import { createContext, useState } from "react";

const SessionContext = createContext({});

export const SessionProvider = ({ children }) => {

    const [session , setSession] = useState(sessionStorage.getItem("session_session") === null ? {} : JSON.parse(sessionStorage.getItem("session_session")));

    const storeSession = (sessionData) => {
        setSession(sessionData);
        sessionStorage.setItem("session_session", JSON.stringify(sessionData));
    };

    return (
        <SessionContext.Provider value={{ session, setSession: storeSession }}>
            {children}
        </SessionContext.Provider>
    )

    // return (
    //     <SessionContext.Provider value={{ session, setSession }}>
    //         {children}
    //     </SessionContext.Provider>
    // )
}

export default SessionContext;