import useAuth from "../hooks/useAuth";
import useSession from "../hooks/useSession";
import useTranslation from "../hooks/useTranslation";

import { getToken } from "../tokens";

//import useEnvironment from "../hooks/useSession";
import axios from "axios";

import { useState, useEffect, useMemo } from "react";
import { json, useNavigate } from "react-router-dom";

import { DataGrid, GridColDef, GridValueGetterParams, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel, TextField, Card } from "@mui/material";
import { Newspaper } from "@mui/icons-material";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";

import CallReceivedIcon from "@mui/icons-material/CallReceived";

import ProductDetails from "../components/productDetails";

import moment from "moment";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import hostsConfig from "../hostconfig.json";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import Tooltip from "@mui/material/Tooltip";
import ModeEditIcon from "@mui/icons-material/ModeEdit";


const Product = () => {
    const { auth, setAuth } = useAuth();
    const { session, setSession } = useSession();
    const { translation, setTranslation } = useTranslation();

    const [filterSupplier, setFilterSupplier] = useState("");

    const [purchases, setPurchases] = useState([]);

    const [schools, setSchools] = useState([]);
    const [schoolDropdown, setSchoolDropdown] = useState("");
    const [selectedSchool, setSelectedSchool] = useState("");
    const [selectedSchoolObj, setSelectedSchoolObj] = useState(null);

    const [locations, setLocations] = useState([]);
    const [locationDropdown, setLocationDropdown] = useState("");

    const [statuses, setStatuses] = useState([]);
    const [statusDropdown, setStatusDropdown] = useState("");

    const [actionTypes, setActionTypes] = useState([]);
    const [actionTypeDropdown, setActionTypeDropdown] = useState("");

    const [email, setEmail] = useState("");

    const [categories, setCategories] = useState([]);
    const [categoryDropdown, setCategoryDropdown] = useState("");

    const [subcategories, setSubcategories] = useState([]);
    const [subcategoryDropdown, setSubcategoryDropdown] = useState("");

    const [manufacturers, setManufacturers] = useState([]);
    const [manufacturerDropdown, setManufacturerDropdown] = useState("");

    const [modelNumber, setModelNumber] = useState("");
    const [productName, setProductName] = useState("");
    const [serialNumber, setSerialNumber] = useState("");
    const [insuranceDropdown, setInsuranceDropdown] = useState("");
    const [purchaseDateFrom, setPurchaseDateFrom] = useState("");
    const [purchaseDateTo, setPurchaseDateTo] = useState("");

    const [suppliers, setSuppliers] = useState([]);
    const [supplierDropdown, setSupplierDropdown] = useState("");

    const [priceVATinclFrom, setPriceVATinclFrom] = useState("");
    const [priceVATinclTo, setPriceVATinclTo] = useState("");

    const [invoiceNrExt, setInvoiceNrExt] = useState("");
    const [invoiceNrInt, setInvoiceNrInt] = useState("");
    const [invoiceNrSch, setInvoiceNrSch] = useState("");

    const [openMessage, setOpenMessage] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogAction, setDialogAction] = useState("");

    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const [productPage, setProductPage] = useState(0);
    const [productMultyPage, setProductMultyPage] = useState(0);

    const [products, setProducts] = useState([]);
    const [productHistory, setProductHistory] = useState([]);
    const [productDetailsObj, setProductDetailsObj] = useState();
    const [productLastStatusObj, setProductLastStatusObj] = useState("");

    const [selectedProduct, setSelectedProduct] = useState(null);

    const [filtersExplanded, setFiltersExplanded] = useState(true);

    //const [showProductDetails, setShowProductDetails] = useState(false);

    const [productSchool, setProductSchool] = useState("");
    const [productLocation, setProductLocation] = useState("");
    const [productInputLocation, setProductInputLocation] = useState("");
    const [productNotes, setProductNotes] = useState("");

    const [productStatus, setProductStatus] = useState("");
    // const [productStatusNotes, setProductStatusNotes] = useState("");
    const [productHistoryNotes, setProductHistoryNotes] = useState("");
    const [productCategory, setProductCategory] = useState("");
    const [productSubcategory, setProductSubcategory] = useState("");
    const [productManufacturer, setProductManufacturer] = useState("");
    const [productModelNumber, setProductModelNumber] = useState("");
    const [productProductName, setProductProductName] = useState("");
    const [productSerialNumber, setProductSerialNumber] = useState("");
    const [productDetailNotes, setProductDetailNotes] = useState("");

    const [selectedRows, setSelectedRows] = useState([]);

    const [tabValue, setTabValue] = useState(0);

    const navigate = useNavigate();


    const [deleteInvoiceVisible, setDeleteInvoiceVisible] = useState(false);

    //products is an array of objects like the following: 
    // {
    //     id: "00b32b10-9054-11ee-a1aa-0242c0a8a002",
    //     productID: "00b32b10-9054-11ee-a1aa-0242c0a8a002",
    //     categoryID: "b76cef70-ab98-11ed-a883-bc8385f61308",
    //     subcategoryID: "da3fff69-ab9a-11ed-a883-bc8385f61308",
    //     manufacturerID: "00000000-0000-0000-0000-000000000000",
    //     modelNumber: "75\" Impact Max",
    //     productName: null,
    //     serialNumber: null,
    //     notes: "Clevertouch",
    //     currentStatusID: "1a0154e2-ab99-11ed-a883-bc8385f61308",
    //     currentLocationID: "00b32de8-9054-11ee-a1aa-0242c0a8a002",
    //     currentOrganisationID: "593add8f-ca45-11ed-b582-bc8385f61308",
    //     insuranceID: "00000000-0000-0000-0000-000000000000",
    //     currentPurchaseID: null,
    //     categoryName: "Monitors",
    //     subcategoryName: "CRT",
    //     manufacturerName: "_Not in the list",
    //     statusName: "In use",
    //     statusColor: "#020202",
    //     orgName: "GO! Atheneum Zottegem",
    //     locName: "H-105",
    //     supplierID: null,
    //     purchaseDate: null,
    //     purNotes: null,
    //     invoiceNrInt: null,
    //     priceVATincl: null,
    //     priceVATexcl: null,
    //     VAT: null,
    //     fileID: null,
    //     fileName: null,
    //     fileType: null,
    //     driveID: null,
    //     invfNotes: null,
    //     driveName: null,
    //     driveColor: null,
    // orgShortName,
    // orgDomain,
    // orgColor,
    //   }


    /**
     * Translate a given text to its Dutch translation if it exists in the translation dictionary.
     * If the session environment language is 'en', return the original text.
     * If the translation does not exist, return the original text enclosed in square brackets.
     * @param {string} text - The text to translate.
     * @returns {string} - The translated text if it exists and the session environment language is not 'en', 
     *                     the original text if the session environment language is 'en', 
     *                     or the original text enclosed in square brackets if no translation exists.
     */
    const dict = (text) => {
        if (session.environment.lang === "en") {
            return text;
        } else {
            if (translation[text]) {
                return translation[text];
            } else {
                return "[" + text + "]";
            }
        }
    };


    const columnsProducts: GridColDef[] = [
        { field: "productID", headerName: "ID", width: 0, hide: true },

        // {
        //     field: "action",
        //     headerName: "Goto product",
        //     sortable: false,
        //     renderCell: (params) => {
        //         const onClick = (e) => {
        //             e.stopPropagation(); // don't select this row after clicking
        //             navigate(`/editproduct/${params.id}`);
        //         };
        //         return <Button onClick={onClick}>...</Button>;
        //     },
        // },

        {
            field: "action",
            headerName: "",
            width: 45,
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    navigate(`/editproduct/${params.id}`);
                };
                return (<>
                    <Tooltip title="Goto edit products" placement="top">
                        <ModeEditIcon onClick={onClick} />
                    </Tooltip>
                </>)
            },
        },


        { field: "orgName", headerName: dict("School"), width: 150 },
        { field: "locName", headerName: dict("Location"), width: 150 },

        { field: "categoryName", headerName: dict("Category"), width: 110 },
        { field: "subcategoryName", headerName: dict("Subcategory"), width: 110 },

        //     modelNumber: "75\" Impact Max",
        //     productName: null,
        //     serialNumber: null,
        //     notes: "Clevertouch",
        { field: "modelNumber", headerName: dict("Model nr"), width: 110 },
        { field: "productName", headerName: dict("Product name"), width: 110 },
        { field: "serialNumber", headerName: dict("Serial nr"), width: 110 },
        { field: "notes", headerName: dict("Detail notes"), width: 110 },


        { field: "manufacturerName", headerName: dict("Manufacturer"), width: 110 },

        //statusName
        { field: "statusLabel", headerName: dict("Status"), width: 110, renderCell: (params) => <Chip label={params.value} sx={{ backgroundColor: params.row.statusColor, width: "100%", borderRadius: 2 }} /> },

        //if purchaseDate is not null then format it to DD/MM/YYYY
        {
            field: "purchaseDate",
            headerName: dict("Purchase date"),
            width: 110,
            renderCell: (params) => <><Typography fontSize={14} >{params.value !== null ? moment(params.value).format("DD-MM-YYYY") : "-"}</Typography></>,
        },

        { field: "purNotes", headerName: dict("Purchase notes"), width: 0, hide: true },
        { field: "invoiceNrInt", headerName: dict("Invoice nr"), width: 0, hide: true },
        { field: "priceVATincl", headerName: dict("Price VAT incl"), width: 100, hide: true },
        { field: "VAT", headerName: dict("VAT"), width: 60, hide: true },
        { field: "priceVATexcl", headerName: dict("Price VAT excl"), width: 150, hide: true },
        { field: "fileID", headerName: dict("File ID"), width: 0, hide: true },
        { field: "fileName", headerName: dict("File name"), width: 0, hide: true },
        { field: "fileType", headerName: dict("File type"), width: 0, hide: true },
        { field: "driveID", headerName: dict("Drive ID"), width: 0, hide: true },
        { field: "driveName", headerName: dict("Drive name"), width: 0, hide: true },
        { field: "invfNotes", headerName: dict("Invoice notes"), width: 0, hide: true },
        { field: "driveColor", headerName: dict("Drive color"), width: 0, hide: true },
        { field: "insuranceName", headerName: dict("Insurance category"), width: 110, hide: true },


        // {
        //     field: "currentPurchaseID",
        //     headerName: "Goto invoice",
        //     sortable: false,
        //     renderCell: (params) => {
        //         if (params?.value !== null) {
        //             const onClick = (e) => {
        //                 e.stopPropagation(); // don't select this row after clicking
        //                 navigate(`/purchase/${params.value}`);
        //             };
        //             return (
        //                 <Button onClick={onClick}>
        //                     <ReceiptIcon />
        //                     <PictureAsPdfIcon />
        //                 </Button>
        //             );
        //         } else {
        //             return "";
        //         }
        //     },
        // },

        // { field: "latestPurchaseID", headerName: "latestPurchaseID", width: 150 },

        // { field: "notes", headerName: "Notes", width: 150 },
        // { field: "statusName", headerName: "Status", width: 150 },
    ];

    // const columnsHistory: GridColDef[] = [
    //     { field: "id", headerName: "ID", width: 20 },
    //     //{ field: "productID", headerName: "product ID", width: 0 },
    //     // { field: "changeDate", headerName: "Date", width: 150 },

    //     {
    //         field: "changeDate",
    //         headerName: "Date",
    //         width: 150,
    //         renderCell: (params) => <Chip label={moment(params.value).format("DD-MM-YYYY")} sx={{ width: "100%", borderRadius: 2 }} />,
    //     },

    //     // {
    //     //     field: "actionName",
    //     //     headerName: "Action",
    //     //     width: 150,
    //     // },

    //     // { field: "actionColor", headerName: "", width: 10 , renderCell: (params) => (
    //     //     //if actionColor is not null, then render a chip with the color of the previous field
    //     //     params.value !== null ? <Chip label={""} sx={{backgroundColor: params.value}} /> : null
    //     // )},

    //     //compined field from two fields
    //     {
    //         field: "actionLabel",
    //         headerName: "Action",
    //         width: 160,
    //         renderCell: (params) =>
    //             //if actionColor is not null, then render a chip sqare corners and with same width and with the color of the previous field
    //             params.row.actionColor !== null ? (
    //                 <Chip label={params.value} sx={{ backgroundColor: params.row.actionColor, width: "100%", borderRadius: 2 }} />
    //             ) : null,
    //     },

    //     { field: "details1", headerName: "Details 1", width: 170 },
    //     { field: "details2", headerName: "Details 2", width: 170 },
    //     { field: "details3", headerName: "Details 3", width: 170 },

    //     //{ field: "accountEmail", headerName: "Account", width: 250 },
    //     //field accountEmail as chip with smaller font size
    //     {
    //         field: "accountEmail",
    //         headerName: "Account",
    //         width: 250,
    //         renderCell: (params) => <Chip label={params.value} sx={{ width: "100%", borderRadius: 2, fontSize: 10 }} />,
    //     },

    //     // { field: "actionTypeID", headerName: "Action ID", width: 110 },
    //     // { field: "actionName", headerName: "Action", width: 110 },
    //     // { field: "notes", headerName: "Notes", width: 150 },
    // ];

    useEffect(() => {
        async function schoolsData() {
            try {
                const response = await axios.get(hostsConfig.BACKEND_HOST + "/organisationstree");
                console.log(response);

                let schools = response.data;
                for (let i = 0; i < schools.length; i++) {
                    if (schools[i].locations.length > 0) {
                        for (let j = 0; j < schools[i].locations.length; j++) {
                            if (schools[i].locations[j].id === null) {
                                //remove this location
                                schools[i].locations.splice(j, 1);
                            }
                        }
                    }
                }

                //rename location.description to location.title
                for (let i = 0; i < schools.length; i++) {
                    if (schools[i].locations.length > 0) {
                        for (let j = 0; j < schools[i].locations.length; j++) {
                            schools[i].locations[j].title = schools[i].locations[j].description;
                        }
                    }
                }

                //sort locations by field 'desc'
                for (let i = 0; i < schools.length; i++) {
                    if (schools[i].locations.length > 0) {
                        schools[i].locations.sort((a, b) => (a.title > b.title ? 1 : -1));
                    }
                }

                setSchools(schools);
            } catch (error) {
                console.error(error);
            }
        }
        schoolsData();

        // if (window.location.pathname.includes("/product/")) {
        //     let productID = window.location.pathname.replace("/product/", "");

        //     //validate if productID is a GUID
        //     if (productID.length !== 36) {
        //         console.log("productID is not a GUID");
        //         setMessage("Product ID is not a correct");
        //         setSeverity("error");
        //         setOpenMessage(true);
        //         return;
        //     }

        //     //setShowProductDetails(true);

        //     try {
        //         setSelectedProduct(productID);
        //         setFiltersExplanded(false);
        //         async function getHistoryByProduct() {
        //             try {
        //                 const response = await axios.get(hostsConfig.BACKEND_HOST + `/history_by_product?productID=${productID}`);
        //                 console.log(response.data.productHistory);
        //                 setProductHistory(response.data.productHistory);
        //                 setProductDetailsObj(response.data.productDetails[0]);
        //                 setProductLastStatusObj(response.data.productLastStatus[0]);
        //                 //scroll current page to bottom
        //                 window.scrollTo(0, 2000);
        //             } catch (error) {
        //                 console.error(error);
        //             }
        //         }
        //         getHistoryByProduct();
        //     } catch (error) {
        //         console.log(error);
        //     }
        // }

        // async function getProductWithoutSchool() {
        //     try {
        //         const response = await axios.get(hostsConfig.BACKEND_HOST + "/products_no_org");
        //         console.log(response);
        //         setProducts(response.data.products);
        //     } catch (error) {
        //         console.error(error);
        //     }
        // }
        // if (selectedSchool === "") {
        //     getProductWithoutSchool();
        // } else {
        //     refreshProducts();
        // }
    }, []);





    //every time productDetailsObj changes, then set the values of the productDetailsObj to the state variables
    // useEffect(() => {
    //     // if productDetailsObj is not null, then set the values of the productDetailsObj to the state variables
    //     if (productDetailsObj !== undefined) {
    //         setProductSchool(productDetailsObj.currentOrganisationID !== null ? productDetailsObj.currentOrganisationID : "");
    //         setProductLocation(productDetailsObj.currentLocationID !== null ? productDetailsObj.currentLocationID : "");
    //         setProductNotes(productDetailsObj.notes !== null ? productDetailsObj.notes : "");

    //         setProductStatus(productDetailsObj.currentStatusID !== null ? productDetailsObj.currentStatusID : "");

    //         //setProductStatusNotes(productDetailsObj.statusNotes !== undefined ? productDetailsObj.statusNotes : "");
    //         //setProductHistoryNotes(productDetailsObj.historyNotes !== undefined ? productDetailsObj.historyNotes : "");
    //         setProductCategory(productDetailsObj.categoryID !== null ? productDetailsObj.categoryID : "");
    //         setProductSubcategory(productDetailsObj.subcategoryID !== null ? productDetailsObj.subcategoryID : "");
    //         setProductManufacturer(productDetailsObj.manufacturerID !== null ? productDetailsObj.manufacturerID : "");
    //         setProductModelNumber(productDetailsObj.modelNumber !== null ? productDetailsObj.modelNumber : "");
    //         setProductProductName(productDetailsObj.productName !== null ? productDetailsObj.productName : "");
    //         setProductSerialNumber(productDetailsObj.serialNumber !== null ? productDetailsObj.serialNumber : "");
    //         setProductDetailNotes(productDetailsObj.notes !== null ? productDetailsObj.notes : "");
    //     } else {
    //         setProductSchool("");
    //         setProductLocation("");
    //         setProductNotes("");

    //         setProductStatus("");

    //         //setProductStatusNotes("");
    //         //setProductHistoryNotes("");
    //         setProductCategory("");
    //         setProductSubcategory("");
    //         setProductManufacturer("");
    //         setProductModelNumber("");
    //         setProductProductName("");
    //         setProductSerialNumber("");
    //         setProductDetailNotes("");
    //     }
    // }, [productDetailsObj]);

    //every time productLastStatusObj changes, then set the values of the productLastStatusObj to the state variables
    // useEffect(() => {
    //     // if productLastStatusObj is not null, then set the values of the productLastStatusObj to the state variables

    //     if (productLastStatusObj !== undefined) {
    //         //if productDetailsObj is not null, then set the values of the productDetailsObj to the state variables
    //         if (productDetailsObj !== undefined) {
    //             // setProductStatusNotes(productLastStatusObj.statusNotes !== undefined ? productLastStatusObj.statusNotes : "");
    //             setProductHistoryNotes(productLastStatusObj.historyNotes !== undefined ? productLastStatusObj.historyNotes : "");
    //         }
    //     } else {
    //         // setProductStatusNotes("");
    //         setProductHistoryNotes("");
    //     }
    // }, [productLastStatusObj]);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDialogAction("");
        setDialogTitle("");
        setDialogMessage("");
    };

    const handleConfirmDialog = () => {
        setOpenDialog(false);
        switch (dialogAction) {
            case "blablabla":
                break;
            default:
                break;
        }
    };

    const handleSelectedSupplierChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        setSupplierDropdown(event.target.value);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleApplyFilters = async () => {
        var msg = "";

        //check purchaseDateFrom and purchaseDateTo are valid dates
        let purchaseDateFromValid = true;
        let purchaseDateToValid = true;
        if (purchaseDateFrom !== "") {
            purchaseDateFromValid = moment(purchaseDateFrom, "YYYY-MM-DD", true).isValid();
        }
        if (purchaseDateFromValid === false) {
            msg = msg + dict("Purchase Date From is not a valid date.");
        }

        if (purchaseDateTo !== "") {
            purchaseDateToValid = moment(purchaseDateTo, "YYYY-MM-DD", true).isValid();
        }
        if (purchaseDateToValid === false) {
            msg = msg + dict("Purchase Date To is not a valid date.");
        }

        //check priceVATinclFrom and priceVATinclTo are valid numbers
        let priceVATinclFromValid = true;
        let priceVATinclToValid = true;

        if (priceVATinclFrom !== "") {
            priceVATinclFromValid = !isNaN(Number(priceVATinclFrom));
        }
        if (priceVATinclFromValid === false) {
            msg = msg + dict("Price VAT incl From is not a valid number.");
        }

        if (priceVATinclTo !== "") {
            priceVATinclToValid = !isNaN(Number(priceVATinclTo));
        }
        if (priceVATinclToValid === false) {
            msg = msg + dict("Price VAT incl To is not a valid number.");
        }

        //check purchaseDateFrom is before purchaseDateTo
        if (purchaseDateFrom !== "" && purchaseDateTo !== "") {
            if (moment(purchaseDateFrom).isAfter(moment(purchaseDateTo))) {
                msg = msg + dict("Purchase Date From is after Purchase Date To.");
            }
        }
        //check priceVATinclFrom is biger than priceVATinclTo
        if (priceVATinclFrom !== "" && priceVATinclTo !== "") {
            if (Number(priceVATinclFrom) > Number(priceVATinclTo)) {
                msg = msg + dict("Price VAT incl From is bigger than Price VAT incl To.");
            }
        }

        if (msg !== "") {
            setMessage(msg);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        //setShowProductDetails(false);

        // SchoolDropdown;
        // LocationDropdown;
        // StatusDropdown;
        // ActionTypeDropdown;
        // Email;
        // CategoryDropdown;
        // SubcategoryDropdown;
        // ManufacturerDropdown;
        // ModelNumber;
        // ProductName;
        // SerialNumber;
        // PurchaseDateFrom;
        // PurchaseDateTo;
        // FilterSupplier;
        // PriceVATinclFrom;
        // PriceVATinclTo;
        // InvoiceNrExt;
        // InvoiceNrInt;
        // InvoiceNrSch;

        try {
            let body = {
                SchoolDropdown: schoolDropdown,
                LocationDropdown: locationDropdown,
                StatusDropdown: statusDropdown,
                ActionTypeDropdown: actionTypeDropdown,
                Email: email,
                CategoryDropdown: categoryDropdown,
                SubcategoryDropdown: subcategoryDropdown,
                ManufacturerDropdown: manufacturerDropdown,
                ModelNumber: modelNumber,
                ProductName: productName,
                SerialNumber: serialNumber,
                PurchaseDateFrom: purchaseDateFrom,
                PurchaseDateTo: purchaseDateTo,
                SupplierDropdown: supplierDropdown,
                PriceVATinclFrom: priceVATinclFrom,
                PriceVATinclTo: priceVATinclTo,
                InvoiceNrExt: invoiceNrExt,
                InvoiceNrInt: invoiceNrInt,
                InvoiceNrSch: invoiceNrSch,
                InsuranceDropdown: insuranceDropdown,
            };

            // Convert the filters object into a query string
            const queryString = Object.keys(body)
                .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`)
                .join("&");

            const response = await axios.get(hostsConfig.BACKEND_HOST + `/filter_products_extended_with_insurance?${queryString}`);
            // console.log(hostsConfig.BACKEND_HOST + "/filter_products");
            console.log(response);

            setSession({
                ...session,
                goback: {
                    page: "editProduct",
                    id: null,
                    queryString: queryString,
                },
            });

            setProducts(response.data.products);
        } catch (error) {
            console.error(error);
        }
    };

    const handleClearFilters = async () => {
        setSchoolDropdown("");
        setSelectedSchool("");
        setSelectedSchoolObj(null);

        setLocationDropdown("");
        setStatusDropdown("");
        setActionTypeDropdown("");
        setCategoryDropdown("");
        setSubcategoryDropdown("");

        setManufacturerDropdown("");
        setModelNumber("");
        setProductName("");
        setSerialNumber("");
        setPurchaseDateFrom("");
        setPurchaseDateTo("");
        setSupplierDropdown("");
        setPriceVATinclFrom("");
        setPriceVATinclTo("");
        setInvoiceNrExt("");
        setInvoiceNrInt("");
        setInvoiceNrSch("");

        setProducts([]);
        setProductHistory([]);
        setProductDetailsObj();
    };

    const handleRowClick: GridEventListener<"rowClick"> = async (params) => {
        console.log(params);
        try {
            setSelectedProduct(params.id);

            // async function getHistoryByProduct() {
            //     try {
            //         const response = await axios.get(hostsConfig.BACKEND_HOST + `/history_by_product?productID=${params.id}`);
            //         console.log(response.data.productHistory);
            //         setProductHistory(response.data.productHistory);
            //         setProductDetailsObj(response.data.productDetails[0]);
            //         setProductLastStatusObj(response.data.productLastStatus[0]);
            //     } catch (error) {
            //         console.error(error);
            //     }
            // }
            // getHistoryByProduct();

        } catch (error) {
            console.log(error);
        }
    };

    // const handleRowClick_multiselect: GridEventListener<"rowClick"> = async (params) => { };

    const handleSelectionModelChange: GridEventListener<"selectionChange"> = (ids) => {
        try {
            // const selectedIDs = new Set(ids);
            // const selectedRows = employees.filter((row) =>
            //     selectedIDs.has(row.id),
            // );
            setSelectedRows(ids);
            // setSession({ ...session, selectedProducts: ids });
        } catch (e) {
            console.log(e);
        }
    };

    const handleSelectedSchoolChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        try {
            setSchoolDropdown(event.target.value);
            // setSchoolGrid(event.target.value);
            setSelectedSchool(event.target.value);
            let sch = schools.filter((school) => school.id === event.target.value)[0];
            setSelectedSchoolObj(sch);
            // setEditSchoolName(sch.name);
            // setEditSchoolNameShort(sch.nameShort);
            // setEditSchoolCodeExternal(sch.codeExternal);
            // setEditSchoolCodeInternal(sch.codeInternal);
            // setEditSchoolDomain(sch.domain);
            // let q = hostsConfig.BACKEND_HOST + "/products_org_loc/" + event.target.value;
            // async function getProductBySchool() {
            //     try {
            //         const response = await axios.get(hostsConfig.BACKEND_HOST + "/products_org_loc/" + event.target.value);
            //         console.log(response);
            //         setProducts(response.data.products);
            //     } catch (error) {
            //         console.error(error);
            //     }
            // }
            // getProductBySchool();
            // setSelectedLocation("");
            // setLocationDropdown("");
            // setLocationGrid("");
            // setSelectedProduct(null);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectedLocationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setLocationDropdown(event.target.value);
    };

    const handleSelectedStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setStatusDropdown(event.target.value);
    };

    const handleSelectedActionTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setActionTypeDropdown(event.target.value);
    };

    const handleSelectedCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCategoryDropdown(event.target.value);
    };

    const handleSelectedSubcategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSubcategoryDropdown(event.target.value);
    };

    const handleSelectedManufacturerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setManufacturerDropdown(event.target.value);
    };

    const handleModelNrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setModelNumber(event.target.value);
    };

    const handleProductNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProductName(event.target.value);
    };

    const handleSerialNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSerialNumber(event.target.value);
    };

    const handleInsuranceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setInsuranceDropdown(event.target.value);
    };

    const handlePurchaseDateFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPurchaseDateFrom(event.target.value);
    };

    const handlePurchaseDateToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPurchaseDateTo(event.target.value);
    };

    const handlePriceVATinclFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPriceVATinclFrom(event.target.value);
    };

    const handlePriceVATinclToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPriceVATinclTo(event.target.value);
    };

    const handleInvoiceNrExtChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInvoiceNrExt(event.target.value);
    };

    const handleInvoiceNrIntChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInvoiceNrInt(event.target.value);
    };

    const handleInvoiceNrSchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInvoiceNrSch(event.target.value);
    };

    const handleChangeProductsLocation = async () => {
        console.log("handleChangeProductsLocation");
        console.log(productNotes);
    };

    const handleChangeProductsStatus = async () => {
        console.log("handleChangeProductsStatus");
    };

    const handleChangeProductsDetails = async () => {
        console.log("handleChangeProductsDetails");
    };

    const handleCreateProducts = async () => {
        console.log("handleCreateProducts");
    };

    // const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    //     setTabValue(newValue);
    //     setSelectedRows([]);
    //     setProductDetailsObj();
    //     setProductHistory([]);
    //     setProductLastStatusObj();
    // };

    // ========== tabs ===============================
    // interface TabPanelProps {
    //     children?: React.ReactNode;
    //     index: number;
    //     value: number;
    // }

    // function TabPanel(props: TabPanelProps) {
    //     const { children, value, index, ...other } = props;

    //     return (
    //         <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
    //             {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    //         </div>
    //     );
    // }

    // function a11yProps(index: number) {
    //     return {
    //         id: `simple-tab-${index}`,
    //         "aria-controls": `simple-tabpanel-${index}`,
    //     };
    // }
    // ===============================================

    const do_nothing = () => { };

    return (
        <Box sx={{ m: 2 }}>
            <Grid container spacing={2}>
                {/* <Grid item md={12} lg={12}>
                    <Typography align="center" variant="h5">
                        Products
                    </Typography>
                </Grid> */}
                <Grid item md={12} lg={12}>
                    <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                        {/* <Typography>Schools</Typography> */}

                        {/*==== Filters ==== */}

                        {/* <Box sx={{ display: "flex", justifyContent: "left", pl: 2, pt: 0 }}>
                            <Typography display={"inline"} variant="h6" sx={{ pt: 1 }}>
                                Filters
                            </Typography>
                            <Button
                                sx={{ m: 1, backgroundColor: "#fff", color: "#111" }}
                                variant="contained"
                                onClick={() => {
                                    setShowFilters(!showFilters);
                                }}
                            >
                                Show/Hide filters
                            </Button>
                        </Box> */}

                        <Accordion
                            expanded={filtersExplanded}
                            onChange={() => {
                                setFiltersExplanded(!filtersExplanded);
                            }}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography>{filtersExplanded ? dict("Hide filters") : dict("Show filters")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {/* School & location */}
                                <Box sx={{ display: "block" }}>
                                    <FormControl sx={{ mt: 1, ml: 1, mb: 0, width: 200 }} size="small">
                                        <InputLabel id="demo-select-small">{dict("School")}</InputLabel>
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={schoolDropdown}
                                            label={dict("School")}
                                            onChange={handleSelectedSchoolChange}
                                        >
                                            {schools.map((sch, index) => (
                                                <MenuItem key={index} value={sch.id}>
                                                    {sch.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setSchoolDropdown("");
                                            setSelectedSchool("");
                                            setSelectedSchoolObj(null);
                                            setLocationDropdown("");
                                        }}
                                    >
                                        x
                                    </Button>

                                    <FormControl sx={{ mt: 1, ml: 1, mb: 0, width: 200 }} size="small">
                                        <InputLabel id="demo-select-small">{dict("Location")}</InputLabel>
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={locationDropdown}
                                            label={dict("Location")}
                                            onChange={handleSelectedLocationChange}
                                        >
                                            {selectedSchool &&
                                                schools
                                                    .filter((s) => s.id === selectedSchool)[0]
                                                    .locations.map((loc, index) => (
                                                        <MenuItem key={index} value={loc.id}>
                                                            {loc.description}
                                                        </MenuItem>
                                                    ))}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setLocationDropdown("");
                                        }}
                                    >
                                        x
                                    </Button>

                                    <FormControl sx={{ mt: 1, ml: 1, mb: 0, width: 200 }} size="small">
                                        <InputLabel id="demo-select-small">{dict("Category")}</InputLabel>
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={categoryDropdown}
                                            label={dict("Category")}
                                            onChange={handleSelectedCategoryChange}
                                        >
                                            {session.environment?.categories.map((cat, index) => (
                                                <MenuItem key={index} value={cat.categoryID}>
                                                    {
                                                        session.environment.lang === "en" ? cat.categoryName : cat.categoryLabel
                                                    }
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setCategoryDropdown("");
                                            setSubcategoryDropdown("");
                                        }}
                                    >
                                        x
                                    </Button>

                                    <FormControl sx={{ mt: 1, ml: 1, mb: 0, width: 200 }} size="small">
                                        <InputLabel id="demo-select-small">{dict("Subcategory")}</InputLabel>
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={subcategoryDropdown}
                                            label={dict("Subcategory")}
                                            onChange={handleSelectedSubcategoryChange}
                                        >
                                            {/* if categoryDropdown is not null, then filter the subcategories by categoryID */}

                                            {categoryDropdown
                                                ? session.environment?.subcategories
                                                    .filter((subcat) => subcat.categoryID === categoryDropdown)
                                                    .map((subcat, index) => (
                                                        <MenuItem key={index} value={subcat.subcategoryID}>
                                                            {
                                                                session.environment.lang === "en" ? subcat.subcategoryName : subcat.subcategoryLabel
                                                            }
                                                        </MenuItem>
                                                    ))
                                                : session.environment?.subcategories.map((subcat, index) => (
                                                    <MenuItem key={index} value={subcat.subcategoryID}>
                                                        {
                                                            session.environment.lang === "en" ? subcat.subcategoryName : subcat.subcategoryLabel
                                                        }
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>

                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setSubcategoryDropdown("");
                                        }}
                                    >
                                        x
                                    </Button>
                                </Box>

                                {/* Status & Action type */}
                                <Box sx={{ display: "block" }}>
                                    <FormControl sx={{ mt: 1, ml: 1, mb: 0, width: 200 }} size="small">
                                        <InputLabel id="demo-select-small">{dict("Status")}</InputLabel>
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={statusDropdown}
                                            label={dict("Status")}
                                            onChange={handleSelectedStatusChange}
                                        >
                                            {session.environment?.statustype.map((sts, index) => (
                                                <MenuItem key={index} value={sts.statusTypeID}>
                                                    {
                                                        session.environment.lang === "en" ? sts.statusName : sts.statusLabel
                                                    }
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setStatusDropdown("");
                                        }}
                                    >
                                        x
                                    </Button>

                                    <FormControl sx={{ mt: 1, ml: 1, mb: 0, width: 200 }} size="small">
                                        <InputLabel id="demo-select-small">{dict("Action type")}</InputLabel>
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={actionTypeDropdown}
                                            label={dict("Action type")}
                                            onChange={handleSelectedActionTypeChange}
                                        >
                                            {session.environment?.actiontype.map((act, index) => (
                                                <MenuItem key={index} value={act.actionTypeID}>
                                                    {
                                                        session.environment.lang === "en" ? act.actionName : act.actionLabel
                                                    }
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setActionTypeDropdown("");
                                        }}
                                    >
                                        x
                                    </Button>
                                    <TextField
                                        sx={{ mt: 1, ml: 1, mb: 0, width: 200 }}
                                        label={dict("Email")}
                                        size="small"
                                        value={email}
                                        onChange={handleEmailChange}
                                    />
                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setEmail("");
                                        }}
                                    >
                                        x
                                    </Button>

                                    <FormControl sx={{ mt: 1, ml: 1, mb: 0, width: 200 }} size="small">
                                        <InputLabel id="demo-select-small">{dict("Manufacturer")}</InputLabel>
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={manufacturerDropdown}
                                            label={dict("Manufacturer")}
                                            onChange={handleSelectedManufacturerChange}
                                        >
                                            {session.environment?.manufacturers.map((man, index) => (
                                                <MenuItem key={index} value={man.manufacturerID}>
                                                    {man.manufacturerName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setManufacturerDropdown("");
                                        }}
                                    >
                                        x
                                    </Button>
                                </Box>

                                {/* Model nr ,  product name & serial nr */}
                                <Box sx={{ display: "block" }}>
                                    <TextField
                                        sx={{ mt: 1, ml: 1, mb: 0, width: 200 }}
                                        label={dict("Model nr")}
                                        size="small"
                                        value={modelNumber}
                                        onChange={handleModelNrChange}
                                    />
                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setModelNumber("");
                                        }}
                                    >
                                        x
                                    </Button>

                                    <TextField
                                        sx={{ mt: 1, ml: 1, mb: 0, width: 200 }}
                                        label={dict("Product name")}
                                        size="small"
                                        value={productName}
                                        onChange={handleProductNameChange}
                                    />
                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setProductName("");
                                        }}
                                    >
                                        x
                                    </Button>

                                    <TextField
                                        sx={{ mt: 1, ml: 1, mb: 0, width: 200 }}
                                        label={dict("Serial nr")}
                                        size="small"
                                        value={serialNumber}
                                        onChange={handleSerialNumberChange}
                                    />
                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setSerialNumber("");
                                        }}
                                    >
                                        x
                                    </Button>

                                    {/* insurance */}
                                    <FormControl sx={{ mt: 1, ml: 1, mb: 0, width: 200 }} size="small">
                                        <InputLabel id="demo-select-small">{dict("Insurance category")}</InputLabel>
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={insuranceDropdown}
                                            label={dict("Insurance category")}
                                            onChange={handleInsuranceChange}
                                        >
                                            {session.environment?.insurance.map((insur, index) => (
                                                <MenuItem key={index} value={insur.insuranceID}>
                                                    {insur.insuranceName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setInsuranceDropdown("");
                                        }}
                                    >
                                        x
                                    </Button>

                                </Box>

                                {/* PurchaseDate from , purchase date to & supplier */}
                                <Box sx={{ display: "block" }}>
                                    <TextField
                                        sx={{ mt: 1, ml: 1, mb: 0, width: 200 }}
                                        InputLabelProps={{ shrink: true }}
                                        label={dict("Purchase date from")}
                                        size="small"
                                        type="date"
                                        value={purchaseDateFrom}
                                        onChange={handlePurchaseDateFromChange}
                                    />
                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setPurchaseDateFrom("");
                                        }}
                                    >
                                        x
                                    </Button>

                                    <TextField
                                        sx={{ mt: 1, ml: 1, mb: 0, width: 200 }}
                                        InputLabelProps={{ shrink: true }}
                                        label={dict("Purchase date to")}
                                        size="small"
                                        type="date"
                                        value={purchaseDateTo}
                                        onChange={handlePurchaseDateToChange}
                                    />
                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setPurchaseDateTo("");
                                        }}
                                    >
                                        x
                                    </Button>

                                    <FormControl sx={{ mt: 1, ml: 1, mb: 0, width: 200 }} size="small">
                                        <InputLabel id="demo-select-small">{dict("Supplier")}</InputLabel>
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={supplierDropdown}
                                            label={dict("Supplier")}
                                            onChange={handleSelectedSupplierChange}
                                        >
                                            {session.environment?.suppliers.map((sup, index) => (
                                                <MenuItem key={index} value={sup.SupplierID}>
                                                    {sup.SupplierName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setSupplierDropdown("");
                                        }}
                                    >
                                        x
                                    </Button>

                                    <TextField
                                        sx={{ mt: 1, ml: 1, mb: 0, width: 200 }}
                                        label={dict("Price VAT incl from")}
                                        size="small"
                                        type="number"
                                        value={priceVATinclFrom}
                                        onChange={handlePriceVATinclFromChange}
                                    />
                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setPriceVATinclFrom("");
                                        }}
                                    >
                                        x
                                    </Button>
                                </Box>

                                {/* invoiceNrExt , invoiceNrInt & invoiceNrSch */}
                                <Box sx={{ display: "block" }}>
                                    <TextField
                                        sx={{ mt: 1, ml: 1, mb: 1, width: 200 }}
                                        label={dict("Invoice nr ext")}
                                        size="small"
                                        value={invoiceNrExt}
                                        onChange={handleInvoiceNrExtChange}
                                    />
                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setInvoiceNrExt("");
                                        }}
                                    >
                                        x
                                    </Button>

                                    <TextField
                                        sx={{ mt: 1, ml: 1, mb: 1, width: 200 }}
                                        label={dict("Invoice nr int")}
                                        size="small"
                                        value={invoiceNrInt}
                                        onChange={handleInvoiceNrIntChange}
                                    />
                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setInvoiceNrInt("");
                                        }}
                                    >
                                        x
                                    </Button>

                                    <TextField
                                        sx={{ mt: 1, ml: 1, mb: 1, width: 200 }}
                                        label={dict("Invoice nr sch")}
                                        size="small"
                                        value={invoiceNrSch}
                                        onChange={handleInvoiceNrSchChange}
                                    />
                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setInvoiceNrSch("");
                                        }}
                                    >
                                        x
                                    </Button>

                                    <TextField
                                        sx={{ mt: 1, ml: 1, mb: 1, width: 200 }}
                                        label={dict("Price VAT incl to")}
                                        size="small"
                                        type="number"
                                        value={priceVATinclTo}
                                        onChange={handlePriceVATinclToChange}
                                    />
                                    <Button
                                        variant="contained"
                                        className="btn_tiny"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: "white",
                                            color: "black",
                                            border: "1px solid lightgray",
                                            boxShadow: "none",
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setPriceVATinclTo("");
                                        }}
                                    >
                                        x
                                    </Button>
                                </Box>

                                <Box sx={{ display: "block" }}>
                                    <Button sx={{ m: 1 }} variant="contained" onClick={handleApplyFilters}>
                                        {dict("Apply filters")}
                                    </Button>
                                    <Button sx={{ m: 1 }} variant="contained" onClick={handleClearFilters}>
                                        {dict("Clear filters")}
                                    </Button>
                                </Box>

                                {/*==== Filters ==== */}
                            </AccordionDetails>
                        </Accordion>

                        {/* <Box pt={3} sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                                <Tab label="Product overview" {...a11yProps(0)} />
                                <Tab label="Change products" {...a11yProps(1)} />
                            </Tabs>
                        </Box> */}

                        {/* <TabPanel value={tabValue} index={0}> */}
                        {filtersExplanded && (
                            <Box sx={{ height: 330, backgroundColor: "#90caf9" }}>
                                <DataGrid
                                    rows={products}
                                    columns={columnsProducts}
                                    pageSize={6}
                                    rowsPerPageOptions={[6]}
                                    page={productPage}
                                    onPageChange={(newPage) => setProductPage(newPage)}
                                    //checkboxSelection
                                    //disableSelectionOnClick
                                    experimentalFeatures={{}}
                                    //editMode="none"
                                    onRowClick={handleRowClick}
                                    onRowDoubleClick={handleRowClick}
                                    //selectionModel={selectedRows}
                                    //onSelectionModelChange={handleSelectionModelChange}
                                    density="compact"
                                    //add export to excel
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                />
                            </Box>
                        )}
                        {!filtersExplanded && (
                            <Box sx={{ height: 650, backgroundColor: "#90caf9" }}>
                                <DataGrid
                                    rows={products}
                                    columns={columnsProducts}
                                    pageSize={14}
                                    rowsPerPageOptions={[14]}
                                    page={productPage}
                                    onPageChange={(newPage) => setProductPage(newPage)}
                                    //checkboxSelection
                                    //disableSelectionOnClick
                                    experimentalFeatures={{}}
                                    //editMode="none"
                                    onRowClick={do_nothing}
                                    onRowDoubleClick={do_nothing}
                                    //selectionModel={selectedRows}
                                    //onSelectionModelChange={handleSelectionModelChange}
                                    density="compact"
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                />
                            </Box>
                        )}



                    </Card>
                </Grid>
            </Grid>

            <Box maxWidth={800}>
                <Snackbar
                    open={openMessage}
                    onClose={() => {
                        setOpenMessage(false);
                    }}
                    TransitionComponent={SlideTransition}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    key="Snackbar"
                //autoHideDuration={3000}
                >
                    <Alert
                        severity={severity}
                        sx={{ marginTop: 2 }}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </Box>

            <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Disagree</Button>
                    <Button onClick={handleConfirmDialog} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export default Product;
