import useAuth from "../hooks/useAuth";
import useSession from "../hooks/useSession";
import axios from "axios";

import { useState, useEffect, useMemo, useContext, createContext } from "react";
import { useNavigate } from "react-router-dom";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel, TextField, Card } from "@mui/material";
import { Newspaper } from "@mui/icons-material";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

import ProductDetails from "../components/productDetails";

import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import { getToken } from "../tokens";

import hostsConfig from '../hostconfig.json'

const product_demo = {
    id: "04bf171c-aea7-11ed-a883-bc8385f61308",
    productID: "04bf171c-aea7-11ed-a883-bc8385f61308",
    categoryID: "b76ccb8e-ab98-11ed-a883-bc8385f61308",
    subcategoryID: "da3f8808-ab9a-11ed-a883-bc8385f61308",
    manufacturerID: "e2426120-ab98-11ed-a883-bc8385f61308",
    modelNumber: "mdl003",
    productName: "dell laptop",
    serialNumber: null,
    notes: "initial relocation entries",
    currentStatusID: null,
    currentLocationID: null,
    currentOrganisationID: null,
    historyID: "89106db7-aea8-11ed-a883-bc8385f61308",
    actionTypeID: "d74b17e7-ab96-11ed-a883-bc8385f61308",
    accountEmail: "tx@email.com",
    changeDate: "2023-02-16T23:00:00.000Z",
    organisationID: "4b87220f-ac51-11ed-a883-bc8385f61308",
    locationID: "457fda5c-ac53-11ed-a883-bc8385f61308",
};

const schoo_demo = {
    id: "457fda5c-ac53-11ed-a883-bc8385f61308",
    name: "School 1",
    nameShort: "S1",
    codeInternal: "INT1",
    codeExternal: "EXT1",
    photo: null,
    domain: "school1.com",
    locations: [
        {
            id: "457fda5c-ac53-11ed-a883-bc8385f61308",
            code: "cr1",
            description: "classroom1",
        },
        {
            id: "457fe815-ac53-11ed-a883-bc8385f61308",
            code: "cr2",
            description: "classroom2",
        },
        {
            id: "457fea06-ac53-11ed-a883-bc8385f61308",
            code: "cr3",
            description: "classroom3",
        },
        {
            id: "457feb61-ac53-11ed-a883-bc8385f61308",
            code: "cr4",
            description: "classroom4",
        },
        {
            id: "457fecc2-ac53-11ed-a883-bc8385f61308",
            code: "cr5",
            description: "classroom5",
        },
        {
            id: "457fef0d-ac53-11ed-a883-bc8385f61308",
            code: "cr6",
            description: "classroom6",
        },
    ],
};

// ========== tabs ===============================
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
// ===============================================


const School = () => {
    const { auth, setAuth } = useAuth();
    const { session, setSession } = useSession();
    const navigate = useNavigate();

    // schoolPage
    // locationPage
    // productPage
    // productMultyPage

    const [schoolPage, setSchoolPage] = useState(0);
    const [locationPage, setLocationPage] = useState(0);
    const [productPage, setProductPage] = useState(0);
    const [productMultyPage, setProductMultyPage] = useState(0);

    const [openMessage, setOpenMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const [schools, setSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState("");
    const [selectedSchoolObj, setSelectedSchoolObj] = useState(null);

    const [selectedLocation, setSelectedLocation] = useState("");

    const [schoolDropdown, setSchoolDropdown] = useState("");
    const [locationDropdown, setLocationDropdown] = useState("");
    const [schoolGrid, setSchoolGrid] = useState("");
    const [locationGrid, setLocationGrid] = useState("");

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);

    const [selectionModel, setSelectionModel] = useState([]);

    const [products, setProducts] = useState([]);

    const [newLocation, setNewLocation] = useState("");
    const [newInputLocation, setNewInputLocation] = useState("");
    const [newSchool, setNewSchool] = useState("");
    const [newNotes, setNewNotes] = useState("");

    const [relocationNotes, setRelocationNotes] = useState("");

    const [statuses, setStatuses] = useState([]);
    const [newStatus, setNewStatus] = useState("");
    // const [newStatusNotes, setNewStatusNotes] = useState("");
    const [newHistoryNotes, setNewHistoryNotes] = useState("");

    const [newCategory, setNewCategory] = useState("");
    const [newSubcategory, setNewSubcategory] = useState("");
    const [newManufacturer, setNewManufacturer] = useState("");
    const [newModelNumber, setNewModelNumber] = useState("");
    const [newProductName, setNewProductName] = useState("");
    const [newSerialNumber, setNewSerialNumber] = useState("");
    const [newDetailNotes, setNewDetailNotes] = useState("");

    const [openSchoolGrid, setOpenSchoolGrid] = useState(false);
    const [openLocationGrid, setOpenLocationGrid] = useState(false);

    const [tabValue, setTabValue] = useState(0);
    const [numberOfProducts, setNumberOfProducts] = useState(1);

    const [newSchoolName, setNewSchoolName] = useState("");
    const [newSchoolNameShort, setNewSchoolNameShort] = useState("");
    const [newSchoolCodeInternal, setNewSchoolCodeInternal] = useState("");
    const [newSchoolCodeExternal, setNewSchoolCodeExternal] = useState("");
    const [newSchoolDomain, setNewSchoolDomain] = useState("");

    const [editSchoolName, setEditSchoolName] = useState("");
    const [editSchoolNameShort, setEditSchoolNameShort] = useState("");
    const [editSchoolCodeInternal, setEditSchoolCodeInternal] = useState("");
    const [editSchoolCodeExternal, setEditSchoolCodeExternal] = useState("");
    const [editSchoolDomain, setEditSchoolDomain] = useState("");

    const [editLocationCode, setEditLocationCode] = useState("");
    const [editLocationDescription, setEditLocationDescription] = useState("");

    const [newLocationCode, setNewLocationCode] = useState("");
    const [newLocationDescription, setNewLocationDescription] = useState("");

    const columnsSchools: GridColDef[] = [
        // { field: "id", headerName: "ID", width:0 },
        {
            field: "name",
            headerName: "Name",
            width: 250,
            editable: true,
        },
        // {
        //     field: "domain",
        //     headerName: "Domain",
        //     width: 150,
        //     editable: true,
        // },
        {
            field: "nameShort",
            headerName: "Short Name",
            width: 80,
            editable: true,
        },
        {
            field: "codeInternal",
            headerName: "Internal Code",
            width: 80,
            editable: true,
        },
        {
            field: "codeExternal",
            headerName: "External Code",
            width: 80,
            editable: true,
        },
    ];

    const columnsLocations: GridColDef[] = [
        // { field: "id", headerName: "ID", width:0 },
        {
            field: "code",
            headerName: "Code",
            width: 100,
            editable: true,
        },
        {
            field: "description",
            headerName: "Description",
            width: 250,
            editable: true,
        },
    ];

    const columnsProducts: GridColDef[] = [
        // {
        //     field: "id",
        //     headerName: "ID",
        //     width: 150,
        //     editable: false,
        // },
        // {
        //     field: "productID",
        //     headerName: "Product ID",
        //     width: 150,
        //     editable: false,
        // },
        {
            field: "productName",
            headerName: "Product Name",
            width: 150,
            editable: false,
        },
        {
            field: "modelNumber",
            headerName: "Model Number",
            width: 120,
            editable: false,
        },
        {
            field: "categoryName",
            headerName: "Category",
            width: 120,
            editable: false,
        },
        {
            field: "subcategoryName",
            headerName: "Subcategory",
            width: 120,
            editable: false,
        },
        {
            field: "manufacturerName",
            headerName: "Manufacturer",
            width: 120,
            editable: false,
        },

        {
            field: "statusName",
            headerName: "Status",
            width: 100,
            editable: false,
        },

        {
            field: "serialNumber",
            headerName: "Serial Nr",
            width: 120,
            editable: false,
        },
        // {
        //     field: "changeDate",
        //     headerName: "Last change",
        //     width: 150,
        //     editable: false,
        // },
        // {
        //     field: "accountEmail",
        //     headerName: "Account Email",
        //     width: 150,
        //     editable: false,
        // },
        {
            field: "notes",
            headerName: "Notes",
            width: 150,
            editable: false,
        },
    ];

    const columnsProductsOverview: GridColDef[] = [
        // {
        //     field: "action",
        //     headerName: "Goto product",
        //     sortable: false,
        //     renderCell: (params) => {
        //         const onClick = (e) => {
        //             e.stopPropagation(); // don't select this row after clicking
        //             navigate(`/product/${params.id}`);
        //         };
        //         return <Button onClick={onClick}>...</Button>;
        //     },
        // },
        {
            field: "productName",
            headerName: "Product Name",
            width: 150,
            editable: false,
        },
        {
            field: "modelNumber",
            headerName: "Model Number",
            width: 120,
            editable: false,
        },
        {
            field: "categoryName",
            headerName: "Category",
            width: 120,
            editable: false,
        },
        {
            field: "subcategoryName",
            headerName: "Subcategory",
            width: 120,
            editable: false,
        },
        {
            field: "manufacturerName",
            headerName: "Manufacturer",
            width: 120,
            editable: false,
        },

        {
            field: "statusName",
            headerName: "Status",
            width: 100,
            editable: false,
        },

        {
            field: "serialNumber",
            headerName: "Serial Nr",
            width: 120,
            editable: false,
        },
        // {
        //     field: "changeDate",
        //     headerName: "Last change",
        //     width: 150,
        //     editable: false,
        // },
        // {
        //     field: "accountEmail",
        //     headerName: "Account Email",
        //     width: 150,
        //     editable: false,
        // },
        {
            field: "notes",
            headerName: "Notes",
            width: 150,
            editable: false,
        },
    ];

    // useEffect(() => {
    //     async function check_if_has_token() {
    //         const token = await getToken();
    //         if (!token || token === "undefined") {
    //                 window.location.href = "http://localhost:8000/login";

    //         }
    //     }
    //     check_if_has_token();
    // }, []);

    useEffect(() => {
        async function schoolsData() {
            try {
                const response = await axios.get(hostsConfig.BACKEND_HOST + "/organisationstree");
                console.log(response);

                let schools = response.data;
                for (let i = 0; i < schools.length; i++) {
                    if (schools[i].locations.length > 0) {
                        for (let j = 0; j < schools[i].locations.length; j++) {
                            if (schools[i].locations[j].id === null) {
                                //remove this location
                                schools[i].locations.splice(j, 1);
                            }
                        }
                    }
                }

                setSchools(schools);
            } catch (error) {
                console.error(error);
            }
        }
        schoolsData();

        async function getProductWithoutSchool() {
            try {
                const response = await axios.get(hostsConfig.BACKEND_HOST + "/products_no_org");
                console.log(response);
                setProducts(response.data.products);
            } catch (error) {
                console.error(error);
            }
        }
        if (selectedSchool === "") {
            getProductWithoutSchool();
        } else {
            refreshProducts();
        }

    }, []);

    const handleRowClick: GridEventListener<"rowClick"> = async (params) => {
        try {
            setSelectedSchool(params.id);
            setSchoolGrid(params.id);
            setSchoolDropdown(params.id);

            let sch = schools.filter((school) => school.id === params.id)[0];
            setSelectedSchoolObj(sch);

            setEditSchoolName(sch.name);
            setEditSchoolNameShort(sch.nameShort);
            setEditSchoolCodeExternal(sch.codeExternal);
            setEditSchoolCodeInternal(sch.codeInternal);
            setEditSchoolDomain(sch.domain);

            let q = hostsConfig.BACKEND_HOST + "/products_org_loc/" + params.id;
            async function getProductBySchool() {
                try {
                    const response = await axios.get(hostsConfig.BACKEND_HOST + "/products_org_loc/" + params.id);
                    console.log(response);
                    setProducts(response.data.products);
                } catch (error) {
                    console.error(error);
                }
            }
            getProductBySchool();
            setSelectedLocation("");
            setLocationGrid("");
            setLocationDropdown("");
            setSelectedProduct(null);
        } catch (error) {
            console.log(error);
        }
    };

    const handleRowClickLocation: GridEventListener<"rowClick"> = async (params) => {
        try {
            setSelectedLocation(params.id);
            setLocationGrid(params.id);
            setLocationDropdown(params.id);

            let loc = selectedSchoolObj.locations.find((x) => x.id === params.id);

            setEditLocationDescription(loc.description);
            setEditLocationCode(loc.code);

            let q = hostsConfig.BACKEND_HOST + "/products_org_loc/" + selectedSchool + "/" + params.id;
            async function getProductByLocation() {
                try {
                    const response = await axios.get(hostsConfig.BACKEND_HOST + "/products_org_loc/" + selectedSchool + "/" + params.id);
                    console.log(response);
                    setProducts(response.data.products);
                } catch (error) {
                    console.error(error);
                }
            }
            getProductByLocation();
        } catch (error) {
            //console.log(error);
        }
        setSelectedProduct(null);
    };

    const refreshProducts = async () => {
        try {
            let q = "";
            if (selectedLocation === null || selectedLocation === undefined || selectedLocation === "") {
                q = hostsConfig.BACKEND_HOST + "/products_org_loc/" + selectedSchool;
            } else {
                q = hostsConfig.BACKEND_HOST + "/products_org_loc/" + selectedSchool + "/" + selectedLocation;
            }

            async function getProductByLocation() {
                try {
                    const response = await axios.get(q);
                    console.log(response);
                    setProducts(response.data.products);
                } catch (error) {
                    console.error(error);
                }
            }
            getProductByLocation();
        } catch (error) {
            //console.log(error);
        }
        setSelectedProduct(null);
    };

    const handleRowClickProduct: GridEventListener<"rowClick"> = async (params) => {
        try {
            //if params.id is not included in the selectedRows array, add it.
            if (selectedRows.includes(params.id)) {
                setSelectedProduct(null);
            } else {
                setSelectedProduct(params.id);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleRowClickProductOverview: GridEventListener<"rowClick"> = async (params) => {
        try {
            setSelectedProduct(params.id);
        } catch (error) {
            console.log(error);
        }
    };

    const handleRowDoubleClickProduct: GridEventListener<"rowDoubleClick"> = async (params) => {
        try {
            setSelectedProduct(params.id);
        } catch (error) {
            console.log(error);
        }
    };

    const handleRowDoubleClickProductOverview: GridEventListener<"rowDoubleClick"> = async (params) => {
        try {
            setSelectedProduct(params.id);
            navigate(`/product/${params.id}`);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectionModelChange: GridEventListener<"selectionChange"> = (ids) => {
        try {
            // const selectedIDs = new Set(ids);
            // const selectedRows = employees.filter((row) =>
            //     selectedIDs.has(row.id),
            // );
            setSelectedRows(ids);
            // console.log(selectedRows);
            setSession({ ...session, selectedProducts: ids });
        } catch (e) {
            console.log(e);
        }
    };

    const handleGotoProduct = () => {
        if (selectedProduct) {
            try {
                navigate(`/product/${selectedProduct}`);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleGotoSchool = () => {
        if (selectedSchool) {
            try {
                navigate(`/school/${selectedSchool}`);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleGotoLocation = () => {
        if (selectedSchool) {
            try {
                navigate(`/school/${selectedSchool}/${selectedLocation}`);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleChangeProductsLocation = async () => {
        for (let i = 0; i < selectedRows.length; i++) {
            let ID = selectedRows[i];

            if (session.environment === undefined) {
                return;
            }

            //get the actionTypeID from the environment.actionTypes array of objects where the actionName is "Relocate"
            let actionTypeID = session.environment.actiontype.find((x) => x.actionName === "Relocate").actionTypeID;
            let accountEmail = auth?.userInfo?.email || "";

            try {
                let body = {
                    productID: ID,
                    organisationID: newSchool,
                    locationID: newLocation,
                    inputLocation: newInputLocation,
                    actionTypeID: actionTypeID,
                    accountEmail: accountEmail,
                    notes: newNotes,
                };

                var inputLoc = newInputLocation;

                // const response = await axios.post(hostsConfig.BACKEND_HOST + "/changeProductOrganisationLocation", body);
                const response = await axios.post(hostsConfig.BACKEND_HOST + "/changeProductOrganisationLocationInputLocation", body);
                refreshProducts();

                setNewLocation("");
                setNewInputLocation("");
                setNewSchool("");
                setNewNotes("");

                setMessage("Product(s) relocated");
                setSeverity("success");
                setOpenMessage(true);

                console.log(response);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleChangeProductsStatus = async () => {
        for (let i = 0; i < selectedRows.length; i++) {
            let ID = selectedRows[i];

            if (session.environment === undefined) {
                return;
            }

            //get the actionTypeID from the environment.actionTypes array of objects where the actionName is "Relocate"
            let actionTypeID = session.environment.actiontype.find((x) => x.actionName === "Status change").actionTypeID;
            let accountEmail = auth?.userInfo?.email || "";

            try {
                let body = {
                    productID: ID,
                    statusID: newStatus,
                    actionTypeID: actionTypeID,
                    accountEmail: accountEmail,
                    historyNotes: newHistoryNotes,
                    // statusNotes: newStatusNotes,
                };

                const response = await axios.post(hostsConfig.BACKEND_HOST + "/changeProductStatus", body);
                refreshProducts();
                console.log(response);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleChangeProductsDetails = async () => {
        for (let i = 0; i < selectedRows.length; i++) {
            let ID = selectedRows[i];

            if (session.environment === undefined) {
                return;
            }

            let accountEmail = auth?.userInfo?.email || "";

            try {
                let body = {
                    productID: ID,
                    categoryID: newCategory,
                    subcategoryID: newSubcategory,
                    manufacturerID: newManufacturer,
                    modelNumber: newModelNumber,
                    productName: newProductName,
                    serialNumber: newSerialNumber,
                    notes: newDetailNotes,
                    accountEmail: accountEmail,
                };

                const response = await axios.put(hostsConfig.BACKEND_HOST + "/changeProductDetails", body);

                refreshProducts();

                console.log(response);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleSelectedSchoolChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        try {
            setSchoolDropdown(event.target.value);
            setSchoolGrid(event.target.value);
            setSelectedSchool(event.target.value);

            let sch = schools.filter((school) => school.id === event.target.value)[0];
            setSelectedSchoolObj(sch);

            setEditSchoolName(sch.name);
            setEditSchoolNameShort(sch.nameShort);
            setEditSchoolCodeExternal(sch.codeExternal);
            setEditSchoolCodeInternal(sch.codeInternal);
            setEditSchoolDomain(sch.domain);

            let q = hostsConfig.BACKEND_HOST + "/products_org_loc/" + event.target.value;
            async function getProductBySchool() {
                try {
                    const response = await axios.get(hostsConfig.BACKEND_HOST + "/products_org_loc/" + event.target.value);
                    console.log(response);
                    setProducts(response.data.products);
                } catch (error) {
                    console.error(error);
                }
            }
            getProductBySchool();
            setSelectedLocation("");
            setLocationDropdown("");
            setLocationGrid("");
            setSelectedProduct(null);
        } catch (error) {
            console.log(error);
        }
    };

    const flipOpenSchoolGrid = () => {
        setOpenSchoolGrid(!openSchoolGrid);
    };

    const flipOpenLocationlGrid = () => {
        setOpenLocationGrid(!openLocationGrid);
    };

    const handleSelectedLocationChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        try {
            setSelectedLocation(event.target.value);
            setLocationDropdown(event.target.value);
            setLocationGrid(event.target.value);

            let loc = selectedSchoolObj.locations.find((x) => x.id === event.target.value);
            setEditLocationDescription(loc.description);
            setEditLocationCode(loc.code);

            let q = hostsConfig.BACKEND_HOST + "/products_org_loc/" + selectedSchool + "/" + event.target.value;
            async function getProductByLocation() {
                try {
                    const response = await axios.get(hostsConfig.BACKEND_HOST + "/products_org_loc/" + selectedSchool + "/" + event.target.value);
                    console.log(response);
                    setProducts(response.data.products);
                } catch (error) {
                    console.error(error);
                }
            }
            getProductByLocation();
        } catch (error) {
            console.log(error);
        }
        setSelectedProduct(null);
    };

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleChangeNumberOfProducts = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNumberOfProducts(event.target.value);
    };

    const handleCreateProducts = async () => {
        try {
            let count_success = 0;
            let count_error = 0;

            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                categoryID: newCategory,
                subcategoryID: newSubcategory,
                manufacturerID: newManufacturer,
                modelNumber: newModelNumber,
                productName: newProductName,
                serialNumber: newSerialNumber,
                detailNotes: newDetailNotes,
                statusID: newStatus,
                // statusNotes: newStatusNotes,
                statusHistoryNotes: newHistoryNotes,
                organisationID: selectedSchool,
                locationID: selectedLocation,
                relocationNotes: newNotes,
                account: accountEmail,
                purchaseID: "",
            };

            for (let i = 0; i < numberOfProducts; i++) {
                try {
                    const response = await axios.post(hostsConfig.BACKEND_HOST + "/insertProduct", body);

                    count_success++;
                    console.log(response);
                } catch (error) {
                    console.error(error);
                    count_error++;
                }
            }

            if (numberOfProducts === count_success) {
                setMessage(numberOfProducts + " created succesfuly.");
                setSeverity("success");
                setOpenMessage(true);
            } else {
                if (count_success > 0) {
                    setMessage("Only " + numberOfProducts + " created succesfuly.");
                    setSeverity("warning");
                    setOpenMessage(true);
                } else {
                    setMessage("No product created.");
                    setSeverity("error");
                    setOpenMessage(true);
                }
            }

            refreshProducts();

            console.log("Creating products" + numberOfProducts);
        } catch (error) {
            console.log(error);
        }
    };

    const handleChangeNewSchoolName = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNewSchoolName(event.target.value);
    };

    const handleChangeNewSchoolNameShort = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNewSchoolNameShort(event.target.value);
    };

    const handleChangeNewSchoolCodeInternal = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNewSchoolCodeInternal(event.target.value);
    };

    const handleChangeNewSchoolCodeExternal = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNewSchoolCodeExternal(event.target.value);
    };

    const handleChangeNewSchoolDomain = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNewSchoolDomain(event.target.value);
    };

    const handleChangeNewLocationDescription = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNewLocationDescription(event.target.value);
    };

    const handleChangeNewLocationCode = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNewLocationCode(event.target.value);
    };

    const handleCreateSchool = async () => {
        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                name: newSchoolName,
                nameShort: newSchoolNameShort,
                codeInternal: newSchoolCodeInternal,
                codeExternal: newSchoolCodeExternal,
                domain: newSchoolDomain,
            };

            try {
                //todo ==================== create the insertOrganisation
                const response = await axios.post(hostsConfig.BACKEND_HOST + "/insertOrganisation", body);

                let schools = response.data;
                for (let i = 0; i < schools.length; i++) {
                    if (schools[i].locations.length > 0) {
                        for (let j = 0; j < schools[i].locations.length; j++) {
                            if (schools[i].locations[j].id === null) {
                                //remove this location
                                schools[i].locations.splice(j, 1);
                            }
                        }
                    }
                }

                setSchools(schools);
                setSelectedSchoolObj(schools.filter((school) => school.id === selectedSchool)[0]);

                setMessage("School created succesfuly.");
                setSeverity("success");
                setOpenMessage(true);

                refreshProducts();

                console.log(response);
            } catch (error) {
                console.error(error);
                setMessage("School not created.");
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleCreateLocation = async () => {
        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                description: newLocationDescription,
                code: newLocationCode,
                organisationID: selectedSchool,
            };

            try {
                //todo ==================== create the insertLocation
                const response = await axios.post(hostsConfig.BACKEND_HOST + "/insertLocation", body);

                let schools = response.data;
                for (let i = 0; i < schools.length; i++) {
                    if (schools[i].locations.length > 0) {
                        for (let j = 0; j < schools[i].locations.length; j++) {
                            if (schools[i].locations[j].id === null) {
                                //remove this location
                                schools[i].locations.splice(j, 1);
                            }
                        }
                    }
                }

                setSchools(schools);
                setSelectedSchoolObj(schools.filter((school) => school.id === selectedSchool)[0]);

                setMessage("Location created succesfuly.");
                setSeverity("success");
                setOpenMessage(true);

                refreshProducts();

                console.log(response);
            } catch (error) {
                console.error(error);
                setMessage("Location not created.");
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSaveSchool = async () => {
        try {
            if (editSchoolName === "") {
                setMessage("School name is required.");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                organisationID: selectedSchool,
                name: editSchoolName,
                nameShort: editSchoolNameShort,
                codeInternal: editSchoolCodeInternal,
                codeExternal: editSchoolCodeExternal,
                domain: editSchoolDomain,
                accountEmail: accountEmail,
            };

            try {
                //todo ==================== create the update_organisation
                const response = await axios.put(hostsConfig.BACKEND_HOST + "/update_organisation", body);

                let schools = response.data;
                for (let i = 0; i < schools.length; i++) {
                    if (schools[i].locations.length > 0) {
                        for (let j = 0; j < schools[i].locations.length; j++) {
                            if (schools[i].locations[j].id === null) {
                                //remove this location
                                schools[i].locations.splice(j, 1);
                            }
                        }
                    }
                }

                setSchools(schools);
                setSelectedSchoolObj(schools.filter((school) => school.id === selectedSchool)[0]);

                setMessage("School saved succesfuly.");
                setSeverity("success");
                setOpenMessage(true);

                refreshProducts();

                console.log(response);
            } catch (error) {
                console.error(error);
                setMessage("School not saved.");
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSaveLocation = async () => {
        try {
            if (editLocationDescription === "") {
                setMessage("Location description is required.");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                locationID: selectedLocation,
                description: editLocationDescription,
                code: editLocationCode,
            };

            try {
                //todo ==================== create the update_location
                const response = await axios.put(hostsConfig.BACKEND_HOST + "/update_location", body);

                let schools = response.data;
                for (let i = 0; i < schools.length; i++) {
                    if (schools[i].locations.length > 0) {
                        for (let j = 0; j < schools[i].locations.length; j++) {
                            if (schools[i].locations[j].id === null) {
                                //remove this location
                                schools[i].locations.splice(j, 1);
                            }
                        }
                    }
                }

                setSchools(schools);
                setSelectedSchoolObj(schools.filter((school) => school.id === selectedSchool)[0]);

                setMessage("Location saved succesfuly.");
                setSeverity("success");
                setOpenMessage(true);

                refreshProducts();

                console.log(response);
            } catch (error) {
                console.error(error);
                setMessage("Location not saved.");
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleChangeEditSchoolName = (event: React.ChangeEvent<{ value: unknown }>) => {
        setEditSchoolName(event.target.value);
    };

    const handleChangeEditSchoolNameShort = (event: React.ChangeEvent<{ value: unknown }>) => {
        setEditSchoolNameShort(event.target.value);
    };

    const handleChangeEditSchoolCodeInternal = (event: React.ChangeEvent<{ value: unknown }>) => {
        setEditSchoolCodeInternal(event.target.value);
    };

    const handleChangeEditSchoolCodeExternal = (event: React.ChangeEvent<{ value: unknown }>) => {
        setEditSchoolCodeExternal(event.target.value);
    };

    const handleChangeEditSchoolDomain = (event: React.ChangeEvent<{ value: unknown }>) => {
        setEditSchoolDomain(event.target.value);
    };

    const handleChangeEditLocationDescription = (event: React.ChangeEvent<{ value: unknown }>) => {
        setEditLocationDescription(event.target.value);
    };

    const handleChangeEditLocationCode = (event: React.ChangeEvent<{ value: unknown }>) => {
        setEditLocationCode(event.target.value);
    };

    //=====================================================================================
    //=====================================================================================
    //=====================================================================================
    //=====================================================================================
    //=====================================================================================
    //=====================================================================================
    //=====================================================================================
    //=====================================================================================

    return (
        <Box sx={{ m: 2 }}>
            <Grid container spacing={2}>
                {/* <Grid item md={12} lg={12}>

                    <Typography align="center" variant="h5">Schools</Typography>
                </Grid> */}
                <Grid item md={12} lg={6}>
                    <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                        {/* <Typography>Schools</Typography> */}

                        <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                            <InputLabel id="demo-select-small">Schools</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={schoolDropdown}
                                label="Manufacturer"
                                onChange={handleSelectedSchoolChange}
                            >
                                {schools.map((sch, index) => (
                                    <MenuItem key={index} value={sch.id}>
                                        {sch.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            // variant="contained"
                            size="small"
                            sx={{ margin: "10px 0px 0px 0px" }}
                            onClick={() => {
                                flipOpenSchoolGrid();
                            }}
                        >
                            Grid
                        </Button>
                        {selectedSchool && (
                            <Box display={"inline"}>
                                <Button size="small" sx={{ margin: "10px 0px 0px 5px" }} variant="contained" onClick={handleGotoSchool}>
                                    Edit school
                                </Button>
                            </Box>
                        )}
                        {openSchoolGrid && (
                            <Box sx={{ height: 346, width: "500px", backgroundColor: "#90caf9" }}>
                                <DataGrid
                                    rows={schools}
                                    columns={columnsSchools}
                                    pageSize={7}
                                    rowsPerPageOptions={[5]}
                                    page={schoolPage}
                                    onPageChange={(newPage) => setSchoolPage(newPage)}
                                    //checkboxSelection
                                    //disableSelectionOnClick
                                    experimentalFeatures={{}}
                                    //editMode="none"
                                    onRowClick={handleRowClick}
                                    onRowDoubleClick={handleRowClick}
                                    density="compact"
                                />
                            </Box>
                        )}
                    </Card>
                </Grid>
                <Grid item md={12} lg={6}>
                    <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                        {/* <Typography>Locations</Typography> */}
                        <FormControl sx={{ m: 1, minWidth: 280 }} size="small">
                            <InputLabel id="demo-select-small">Locations</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={locationDropdown}
                                label="Manufacturer"
                                onChange={handleSelectedLocationChange}
                            >
                                {selectedSchool &&
                                    schools
                                        .filter((s) => s.id === selectedSchool)[0]
                                        .locations.map((loc, index) => (
                                            <MenuItem key={index} value={loc.id}>
                                                {loc.description}
                                            </MenuItem>
                                        ))}
                            </Select>
                        </FormControl>
                        <Button
                            // variant="contained"
                            size="small"
                            sx={{ margin: "10px 0px 0px 5px" }}
                            onClick={() => {
                                flipOpenLocationlGrid();
                            }}
                        >
                            Grid
                        </Button>
                        {selectedLocation && (
                            <Box display={"inline"}>
                                <Button variant="contained" size="small" sx={{ margin: "10px 0px 0px 5px" }} onClick={handleGotoLocation}>
                                    Edit location
                                </Button>
                            </Box>
                        )}
                        {openLocationGrid && (
                            <Box sx={{ height: 346, width: "400px", backgroundColor: "#90caf9" }}>
                                <DataGrid
                                    //rows are the locations of the selected school
                                    rows={
                                        selectedSchool && schools.filter((s) => s.id === selectedSchool)[0].locations
                                            ? schools.filter((s) => s.id === selectedSchool)[0].locations
                                            : []
                                    }
                                    columns={columnsLocations}
                                    pageSize={7}
                                    rowsPerPageOptions={[5]}
                                    page={locationPage}
                                    onPageChange={(newPage) => setLocationPage(newPage)}
                                    //checkboxSelection
                                    //disableSelectionOnClick
                                    experimentalFeatures={{}}
                                    //editMode="none"
                                    onRowClick={handleRowClickLocation}
                                    onRowDoubleClick={handleRowClickLocation}
                                    selectionModel={selectedLocation ? [selectedLocation] : []}
                                    density="compact"
                                />
                            </Box>
                        )}
                    </Card>
                </Grid>
            </Grid>

            {/* ========================= */}

            <Box pt={3} sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                    <Tab disabled={false} label="Edit schools" {...a11yProps(0)} />
                    <Tab disabled={false} label="Overview" {...a11yProps(1)} />
                    <Tab disabled={true} label="Change products" {...a11yProps(2)} />
                    <Tab disabled={true} label="Add new products in school" {...a11yProps(3)} />
                </Tabs>
            </Box>


            <TabPanel value={tabValue} index={0}>
                <Grid container spacing={2}>
                    <Grid item md={6} lg={6}>
                        <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                            <Typography color={"primary"} variant="h6">
                                Edit school
                            </Typography>

                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                id="outlined-basic"
                                label="School name"
                                variant="outlined"
                                value={editSchoolName}
                                size="small"
                                onChange={handleChangeEditSchoolName}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                id="outlined-basic"
                                label="School Name Short"
                                variant="outlined"
                                value={editSchoolNameShort}
                                size="small"
                                onChange={handleChangeEditSchoolNameShort}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                id="outlined-basic"
                                label="Code internal"
                                variant="outlined"
                                value={editSchoolCodeInternal}
                                size="small"
                                onChange={handleChangeEditSchoolCodeInternal}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                id="outlined-basic"
                                label="Code external"
                                variant="outlined"
                                value={editSchoolCodeExternal}
                                size="small"
                                onChange={handleChangeEditSchoolCodeExternal}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                id="outlined-basic"
                                label="Domain"
                                variant="outlined"
                                value={editSchoolDomain}
                                size="small"
                                onChange={handleChangeEditSchoolDomain}
                            />
                            <Box display={"block"}>
                                <Button disabled={selectedSchool === ""} sx={{ mt: 2 }} fullWidth variant="contained" onClick={handleSaveSchool}>
                                    Save changes
                                </Button>
                            </Box>
                        </Card>
                    </Grid>

                    <Grid item md={6} lg={6}>
                        <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                            <Typography color={"primary"} variant="h6">
                                Edit location
                            </Typography>

                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                id="outlined-basic"
                                label="Location name"
                                variant="outlined"
                                value={editLocationDescription}
                                size="small"
                                onChange={handleChangeEditLocationDescription}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                id="outlined-basic"
                                label="Location name short"
                                variant="outlined"
                                value={editLocationCode}
                                size="small"
                                onChange={handleChangeEditLocationCode}
                            />
                            <Box display={"block"}>
                                <Button disabled={selectedLocation === ""} sx={{ mt: 2 }} fullWidth variant="contained" onClick={handleSaveLocation}>
                                    Save changes
                                </Button>
                            </Box>
                        </Card>
                    </Grid>

                    <Grid item md={6} lg={6}>
                        <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                            <Typography color={"primary"} variant="h6">
                                Or create new school
                            </Typography>

                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                id="outlined-basic"
                                label="School name"
                                variant="outlined"
                                value={newSchoolName}
                                size="small"
                                onChange={handleChangeNewSchoolName}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                id="outlined-basic"
                                label="School Name Short"
                                variant="outlined"
                                value={newSchoolNameShort}
                                size="small"
                                onChange={handleChangeNewSchoolNameShort}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                id="outlined-basic"
                                label="Code internal"
                                variant="outlined"
                                value={newSchoolCodeInternal}
                                size="small"
                                onChange={handleChangeNewSchoolCodeInternal}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                id="outlined-basic"
                                label="Code external"
                                variant="outlined"
                                value={newSchoolCodeExternal}
                                size="small"
                                onChange={handleChangeNewSchoolCodeExternal}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                id="outlined-basic"
                                label="Domain"
                                variant="outlined"
                                value={newSchoolDomain}
                                size="small"
                                onChange={handleChangeNewSchoolDomain}
                            />

                            <Box display={"block"}>
                                <Button sx={{ mt: 2 }} fullWidth variant="contained" onClick={handleCreateSchool}>
                                    Create
                                </Button>
                            </Box>
                        </Card>
                    </Grid>

                    <Grid item md={6} lg={6}>
                        <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                            <Typography color={"primary"} variant="h6">
                                Or create new location
                            </Typography>

                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                id="outlined-basic"
                                label="Location name"
                                variant="outlined"
                                value={newLocationDescription}
                                size="small"
                                onChange={handleChangeNewLocationDescription}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                id="outlined-basic"
                                label="Location name short"
                                variant="outlined"
                                value={newLocationCode}
                                size="small"
                                onChange={handleChangeNewLocationCode}
                            />
                            <Box display={"block"}>
                                <Button disabled={selectedSchool === ""} sx={{ mt: 2 }} fullWidth variant="contained" onClick={handleCreateLocation}>
                                    Create
                                </Button>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
                <Grid container spacing={2}>
                    <Grid item md={12} lg={12}>
                        <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                            <Typography variant="h6">
                                {(selectedSchool === null || selectedSchool === '')
                                    ? "Products without school"
                                    : selectedLocation === ""
                                        ? "Products in school"
                                        : "Products in location"}
                            </Typography>

                            <Box
                                sx={{
                                    height: 600,
                                    width: "100%",
                                    backgroundColor: "#90caf9",
                                }}
                            >
                                <DataGrid
                                    //rows are the locations of the selected school
                                    rows={products}
                                    columns={columnsProductsOverview}
                                    pageSize={100}
                                    rowsPerPageOptions={[100]}
                                    page={productPage}
                                    onPageChange={(newPage) => setProductPage(newPage)}
                                    //checkboxSelection
                                    //disableSelectionOnClick

                                    experimentalFeatures={{}}
                                    // editMode="none"
                                    onRowClick={handleRowClickProductOverview}
                                    onRowDoubleClick={handleRowDoubleClickProductOverview}
                                    //selectionModel={selectedRows}
                                    //onSelectionModelChange={handleSelectionModelChange}
                                    density="compact"
                                />
                            </Box>

                            {/* {selectedProduct && products.length > 0 && (

                                    <Button variant="contained" onClick={handleGotoProduct}>
                                        Goto Product {products.filter((p) => p.id === selectedProduct)[0]?.productName}
                                    </Button>
                            )} */}
                        </Card>
                    </Grid>
                </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
                <Grid container spacing={2}>
                    <Grid item md={12} lg={12}>
                        <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                            <Typography variant="h6">
                                {(selectedSchool === null || selectedSchool === '')
                                    ? "Products without school"
                                    : selectedLocation === ""
                                        ? "Products in school"
                                        : "Products in location"}
                            </Typography>

                            <Box
                                sx={{
                                    height: 400,
                                    width: "100%",
                                    backgroundColor: "#90caf9",
                                }}
                            >
                                <DataGrid
                                    //rows are the locations of the selected school
                                    rows={products}
                                    columns={columnsProducts}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    page={productMultyPage}
                                    onPageChange={(newPage) => setProductMultyPage(newPage)}
                                    checkboxSelection
                                    //disableSelectionOnClick

                                    experimentalFeatures={{}}
                                    // editMode="none"
                                    onRowClick={handleRowClickProduct}
                                    onRowDoubleClick={handleRowDoubleClickProduct}
                                    selectionModel={selectedRows}
                                    onSelectionModelChange={handleSelectionModelChange}
                                    density="compact"
                                />
                            </Box>

                            {/* {selectedProduct && products.length > 0 && (

                                    <Button variant="contained" onClick={handleGotoProduct}>
                                        Goto Product {products.filter((p) => p.id === selectedProduct)[0]?.productName}
                                    </Button>
                            )} */}
                        </Card>
                    </Grid>
                </Grid>

                {selectedRows.length > 0 && (
                    <>
                        <ProductDetails
                            selectedRows={selectedRows}
                            schools={schools}
                            newSchool={newSchool}
                            setNewSchool={setNewSchool}
                            newLocation={newLocation}
                            setNewLocation={setNewLocation}
                            newInputLocation={newInputLocation}
                            setNewInputLocation={setNewInputLocation}
                            // todo:not complete
                            newRelocationNotes={newNotes}
                            setNewRelocationNotes={setNewNotes}
                            handleChangeProductsLocation={handleChangeProductsLocation}
                            statuses={session.environment?.statustype}
                            newStatus={newStatus}
                            setNewStatus={setNewStatus}
                            // newStatusNotes={newStatusNotes}
                            // setNewStatusNotes={setNewStatusNotes}
                            newHistoryNotes={newHistoryNotes}
                            setNewHistoryNotes={setNewHistoryNotes}
                            handleChangeProductsStatus={handleChangeProductsStatus}
                            categories={session.environment?.categories}
                            newCategory={newCategory}
                            setNewCategory={setNewCategory}
                            subcategories={session.environment?.subcategories}
                            newSubcategory={newSubcategory}
                            setNewSubcategory={setNewSubcategory}
                            manufacturers={session.environment?.manufacturers}
                            newManufacturer={newManufacturer}
                            setNewManufacturer={setNewManufacturer}
                            newModelNumber={newModelNumber}
                            setNewModelNumber={setNewModelNumber}
                            newProductName={newProductName}
                            setNewProductName={setNewProductName}
                            newSerialNumber={newSerialNumber}
                            setNewSerialNumber={setNewSerialNumber}
                            newDetailNotes={newDetailNotes}
                            setNewDetailNotes={setNewDetailNotes}
                            handleChangeProductsDetails={handleChangeProductsDetails}
                            mode="change"
                            handleCreateProducts={handleCreateProducts}
                            hasPrices="false"
                        />

                        {/* <Grid item md={12} lg={12}>
                            <Box sx={{ maxWidth: "100%" }}>
                                <Typography>
                                    Change status (for {selectedRows.length} product{selectedRows.length > 1 ? "s" : ""}){" "}
                                </Typography>
                            </Box>
                        </Grid> */}
                    </>
                )}
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
                <Grid container spacing={2}>
                    {selectedSchool === "" ? (
                        <>
                            <Typography color={"primary"} variant="h6">
                                Select a school
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Grid item md={12} lg={12}>
                                <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Number of products"
                                        variant="outlined"
                                        value={numberOfProducts}
                                        type="number"
                                        size="small"
                                        onChange={handleChangeNumberOfProducts}
                                    />
                                </Card>
                            </Grid>
                            <Grid item md={12} lg={12}>
                                <ProductDetails
                                    selectedRows={selectedRows}
                                    schools={schools}
                                    newSchool={selectedSchool}
                                    setNewSchool={setNewSchool}
                                    newLocation={selectedLocation}
                                    setNewLocation={setNewLocation}
                                    newInputLocation={newInputLocation}
                                    setNewInputLocation={setNewInputLocation}
                                    // todo:not complete
                                    newRelocationNotes={newNotes}
                                    setNewRelocationNotes={setNewNotes}
                                    handleChangeProductsLocation={handleChangeProductsLocation}
                                    statuses={session.environment?.statustype}
                                    newStatus={newStatus}
                                    setNewStatus={setNewStatus}
                                    // newStatusNotes={newStatusNotes}
                                    // setNewStatusNotes={setNewStatusNotes}
                                    newHistoryNotes={newHistoryNotes}
                                    setNewHistoryNotes={setNewHistoryNotes}
                                    handleChangeProductsStatus={handleChangeProductsStatus}
                                    categories={session.environment?.categories}
                                    newCategory={newCategory}
                                    setNewCategory={setNewCategory}
                                    subcategories={session.environment?.subcategories}
                                    newSubcategory={newSubcategory}
                                    setNewSubcategory={setNewSubcategory}
                                    manufacturers={session.environment?.manufacturers}
                                    newManufacturer={newManufacturer}
                                    setNewManufacturer={setNewManufacturer}
                                    newModelNumber={newModelNumber}
                                    setNewModelNumber={setNewModelNumber}
                                    newProductName={newProductName}
                                    setNewProductName={setNewProductName}
                                    newSerialNumber={newSerialNumber}
                                    setNewSerialNumber={setNewSerialNumber}
                                    newDetailNotes={newDetailNotes}
                                    setNewDetailNotes={setNewDetailNotes}
                                    handleChangeProductsDetails={handleChangeProductsDetails}
                                    mode="create"
                                    handleCreateProducts={handleCreateProducts}
                                    hasPrices="false"
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </TabPanel>



            <Box maxWidth={800}>
                <Snackbar
                    open={openMessage}
                    onClose={() => {
                        setOpenMessage(false);
                    }}
                    TransitionComponent={SlideTransition}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    key="Snackbar"
                //autoHideDuration={3000}
                >
                    <Alert
                        severity={severity}
                        sx={{ marginTop: 2 }}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export default School;
