import useAuth from "../hooks/useAuth";
import useSession from "../hooks/useSession";
import { getToken } from "../tokens";

//import useEnvironment from "../hooks/useSession";
import axios from "axios";

import { useState, useEffect, useMemo } from "react";
import { json, useNavigate } from "react-router-dom";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography, Link, Tooltip } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel, TextField, Card } from "@mui/material";
import { Newspaper } from "@mui/icons-material";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";

import moment from "moment";

import CallReceivedIcon from "@mui/icons-material/CallReceived";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import ProductDetails from "../components/productDetails";

import FileOpenIcon from "@mui/icons-material/FileOpen";

import hostsConfig from "../hostconfig.json";

// ========== tabs ===============================
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
// ===============================================

const Purchase = () => {
    const { auth, setAuth } = useAuth();
    const { session, setSession } = useSession();
    const navigate = useNavigate();

    const [purchasePage, setPurchasePage] = useState(0);
    const [productPage, setProductPage] = useState(0);
    const [productMultyPage, setProductMultyPage] = useState(0);
    const [changePurchasePage, setChangePurchasePage] = useState(0);
    const [cartProductPage, setCartProductPage] = useState(0);
    const [filesPage, setFilesPage] = useState(0);

    const [openMessage, setOpenMessage] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogAction, setDialogAction] = useState("");

    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const [purchases, setPurchases] = useState([]);
    const [allPurchases, setAllPurchases] = useState([]);
    const [selectedPurchase, setSelectedPurchase] = useState("");

    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [productsInCart, setProductsInCart] = useState([]);

    const [selectedPurchaseToChange, setSelectedPurchaseToChange] = useState("");

    const [filterSupplier, setFilterSupplier] = useState("");
    const [filterFrom_date, setFilterFrom_date] = useState("");

    const [invoiceNrExt, setInvoiceNrExt] = useState("");
    const [invoiceNrInt, setInvoiceNrInt] = useState("");
    const [invoiceNrSch, setInvoiceNrSch] = useState("");

    const [filterTo_date, setFilterTo_date] = useState("");
    const [filterInvoiceNrExt, setFilterInvoiceNrExt] = useState("");
    const [filterInvoiceNrInt, setFilterInvoiceNrInt] = useState("");
    const [filterURLfile, setFilterURLfile] = useState("");
    const [filterInvoiceNrSch, setFilterInvoiceNrSch] = useState("");

    const [files, setFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFilesModel, setSelectedFilesModel] = useState([]);

    // {
    //     purchaseID: "054dc233-c192-11ed-852a-bc8385f61308",
    //     supplierID: "c1f15c11-aded-11ed-a883-bc8385f61308",
    //     purchaseDate: "2023-03-12T23:00:00.000Z",
    //     URLfolder: null,
    //     URLfile: null,
    //     notes: null,
    //     invoiceNrExt: null,
    //     invoiceNrInt: null,
    //     invoiceNrSch: null,
    //     SupplierID: "c1f15c11-aded-11ed-a883-bc8385f61308",
    //     SupplierName: "Supplier 1",
    //     Address: "Address 1",
    //     ContactInfo: "ContactInfo 1",
    //     id: "054dc233-c192-11ed-852a-bc8385f61308",
    //   }

    const [selectedSupplierID, setSelectedSupplierID] = useState("");
    const [selectedPurchaseDate, setSelectedPurchaseDate] = useState("");
    const [selectedURLfolder, setSelectedURLfolder] = useState("");
    const [selectedURLfiles, setSelectedURLfiles] = useState([]);
    const [selectedNotes, setSelectedNotes] = useState("");
    const [selectedInvoiceNrExt, setSelectedInvoiceNrExt] = useState("");
    const [selectedInvoiceNrInt, setSelectedInvoiceNrInt] = useState("");
    const [selectedInvoiceNrSch, setSelectedInvoiceNrSch] = useState("");

    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //

    const [schools, setSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState("");
    const [selectedLocation, setSelectedLocation] = useState("");

    const [schoolDropdown, setSchoolDropdown] = useState("");
    const [locationDropdown, setLocationDropdown] = useState("");
    const [schoolGrid, setSchoolGrid] = useState("");
    const [locationGrid, setLocationGrid] = useState("");

    const [newLocation, setNewLocation] = useState("");
    const [newInputLocation, setNewInputLocation] = useState("");
    const [newSchool, setNewSchool] = useState("");
    const [newNotes, setNewNotes] = useState("");

    const [relocationNotes, setRelocationNotes] = useState("");

    const [statuses, setStatuses] = useState([]);
    const [newStatus, setNewStatus] = useState("");
    // const [newStatusNotes, setNewStatusNotes] = useState("");
    const [newHistoryNotes, setNewHistoryNotes] = useState("");

    const [newCategory, setNewCategory] = useState("");
    const [newSubcategory, setNewSubcategory] = useState("");
    const [newManufacturer, setNewManufacturer] = useState("");
    const [newModelNumber, setNewModelNumber] = useState("");
    const [newProductName, setNewProductName] = useState("");
    const [newSerialNumber, setNewSerialNumber] = useState("");
    const [newDetailNotes, setNewDetailNotes] = useState("");

    const [newPriceVATexcl, setNewPriceVATexcl] = useState("");
    const [newPriceVATincl, setNewPriceVATincl] = useState("");
    const [newVAT, setNewVAT] = useState("");

    const [openSchoolGrid, setOpenSchoolGrid] = useState(false);
    const [openLocationGrid, setOpenLocationGrid] = useState(false);

    const [tabValue, setTabValue] = useState(0);

    const [numberOfProducts, setNumberOfProducts] = useState(1);

    const [editSupplierID, setEditSupplierID] = useState("");
    const [editPurchaseDate, setEditPurchaseDate] = useState("");
    const [editURLFolder, setEditURLFolder] = useState("");

    const [editURLFiles, setEditURLFiles] = useState("");

    const [editNotes, setEditNotes] = useState("");
    const [editInvoiceNrExt, setEditInvoiceNrExt] = useState("");
    const [editInvoiceNrInt, setEditInvoiceNrInt] = useState("");
    const [editInvoiceNrSch, setEditInvoiceNrSch] = useState("");

    const [newSupplierID, setNewSupplierID] = useState("");
    const [newPurchaseDate, setNewPurchaseDate] = useState("");
    const [newURLfolder, setNewURLfolder] = useState("");
    const [newURLfile, setNewURLfile] = useState("");
    const [newPurchaseNotes, setNewPurchaseNotes] = useState("");
    const [newInvoiceNrExt, setNewInvoiceNrExt] = useState("");
    const [newInvoiceNrInt, setNewInvoiceNrInt] = useState("");
    const [newInvoiceNrSch, setNewInvoiceNrSch] = useState("");

    // const purchase =     {
    //     purchaseID: "054dc233-c192-11ed-852a-bc8385f61308",
    //     supplierID: "c1f15c11-aded-11ed-a883-bc8385f61308",
    //     purchaseDate: "2023-03-12T23:00:00.000Z",
    //     URLfolder: null,
    //     URLfile: null,
    //     notes: null,
    //     invoiceNrExt: null,
    //     invoiceNrInt: null,
    //     invoiceNrSch: null,
    //     SupplierID: "c1f15c11-aded-11ed-a883-bc8385f61308",
    //     SupplierName: "Supplier 1",
    //     Address: "Address 1",
    //     ContactInfo: "ContactInfo 1",
    // }

    // const columnPurchases: GridColDef[] = [
    //     { field: "purchaseID", headerName: "ID", width: 0 },
    //     { field: "SupplierName", headerName: "Supplier", width: 150 },
    //     { field: "purchaseDate", headerName: "Purchase Date", width: 100, valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY") },
    //     { field: "invoiceNrExt", headerName: "Invoice Nr Ext", width: 150 },
    //     { field: "invoiceNrInt", headerName: "Invoice Nr Int", width: 150 },
    //     { field: "invoiceNrSch", headerName: "Invoice Nr Sch", width: 150 },
    //     { field: "notes", headerName: "Notes", width: 150 },
    //     { field: "URLfile", headerName: "URLfile", width: 150 },
    //     { field: "URLfolder", headerName: "URLfolder", width: 150 },
    //     { field: "Address", headerName: "Address", width: 150 },
    //     { field: "ContactInfo", headerName: "ContactInfo", width: 150 },
    // ];
    const columnPurchases: GridColDef[] = [
        // { field: "purchaseID", headerName: "ID", width: 0 },
        { field: "SupplierName", headerName: "Supplier", width: 200 },
        { field: "purchaseDate", headerName: "Inv.Date", width: 100, valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY") },
        { field: "invoiceNrExt", headerName: "#Ext", width: 100 },
        { field: "invoiceNrInt", headerName: "#Int", width: 100 },
        { field: "invoiceNrSch", headerName: "#Sch", width: 100 },

        {
            field: "fileID",
            headerName: "Inv. file",
            width: 100,
            renderCell: (params) => {
                if (params.row.fileID !== null) {
                    return params.row.fileID !== null ? (
                        <Tooltip title={params.row.fileName} placement="top" key={params.row.fileID}>
                            <Link href={"https://drive.google.com/file/d/" + params.row.fileID + "/view"} target="_blank" rel="noreferrer">
                                <FileOpenIcon />
                            </Link>
                        </Tooltip>
                    ) : (
                        <></>
                    );
                } else {
                    return (
                        <>
                            <Tooltip title="No files" placement="top">
                                <Chip label="No files" sx={{ width: "100%", borderRadius: 2 }} />
                            </Tooltip>
                        </>
                    );
                }
            },
        },

        // {
        //     field: "NumberOfFiles",
        //     headerName: "Files",
        //     width: 150,
        //     renderCell: (params) => {
        //         if (params.row.URLfile !== null) {
        //             let filesObj = JSON.parse(params.row.URLfile);
        //             return filesObj.files.length > 0 ? (
        //                 filesObj.files.map((file) => (
        //                     <Tooltip title={file.name} placement="top" key={file.id}>
        //                         <Link href={"https://drive.google.com/file/d/" + file.id + "/view"} target="_blank" rel="noreferrer">
        //                             <FileOpenIcon />
        //                         </Link>
        //                     </Tooltip>
        //                 ))
        //             ) : (
        //                 <></>
        //             );
        //         } else {
        //             return (
        //                 <>
        //                     <Tooltip title="No files" placement="top">
        //                         <Chip label="No files" sx={{ width: "100%", borderRadius: 2 }} />
        //                     </Tooltip>
        //                 </>
        //             );
        //         }
        //     },
        // },
        { field: "notes", headerName: "Notes", width: 200 },
        // { field: "URLfile", headerName: "URLfile", width: 150 },
        // { field: "Address", headerName: "Address", width: 150 },
        { field: "ContactInfo", headerName: "ContactInfo", width: 150 },
    ];

    const file = {
        kind: "drive#file",
        driveId: "0ANoyiQLfKc6RUk9PVA",
        mimeType: "application/vnd.google-apps.spreadsheet",
        id: "1azT3R-X54uV9_7ECp8ng9LmsH5Ijdw0DxgvMGabLNzw",
        name: "printout_2022 04 25 12 08",
        teamDriveId: "0ANoyiQLfKc6RUk9PVA",
    };

    const columnsFiles: GridColDef[] = [
        { field: "id", headerName: "ID", width: 0 },
        {
            field: "webViewLink",
            headerName: "Open",
            width: 50,
            renderCell: (params) => (
                <Tooltip title="Open file in new tab" placement="top">
                    <Link href={"https://drive.google.com/file/d/" + params.id + "/view"} target="_blank" rel="noreferrer">
                        <PictureAsPdfIcon />
                    </Link>
                </Tooltip>
            ),
        },
        { field: "name", headerName: "Name", width: 250 },
        { field: "mimeType", headerName: "Mime Type", width: 250 },
        { field: "driveId", headerName: "Drive ID", width: 250 },
        { field: "teamDriveId", headerName: "Team Drive ID", width: 250 },
        { field: "kind", headerName: "Kind", width: 150 },
    ];

    // const product = {
    //     productID: "04bf171c-aea7-11ed-a883-bc8385f61308",
    //     categoryID: "b76ccb8e-ab98-11ed-a883-bc8385f61308",
    //     subcategoryID: "da3fd916-ab9a-11ed-a883-bc8385f61308",
    //     manufacturerID: "e2426be5-ab98-11ed-a883-bc8385f61308",
    //     modelNumber: "hp-top-model",
    //     productName: "product name",
    //     serialNumber: "qwe123",
    //     notes: "test purchase",
    //     currentStatusID: null,
    //     currentLocationID: "457fda5c-ac53-11ed-a883-bc8385f61308",
    //     currentOrganisationID: "4b87220f-ac51-11ed-a883-bc8385f61308",
    //     manufacturerName: "HP",
    //     seq: null,
    //     categoryName: "Computers",
    //     categoryColor: "#ff0000",
    //     subcategoryName: "Desktops",
    //     statusTypeID: null,
    //     statusName: null,
    //     statusColor: null,
    //     historyID: "b314d2f1-c191-11ed-852a-bc8385f61308",
    //     actionTypeID: "d74933d2-ab96-11ed-a883-bc8385f61308",
    //     accountEmail: null,
    //     changeDate: "2023-03-13T11:12:44.000Z",
    //     purchaseID: "054dc233-c192-11ed-852a-bc8385f61308",
    //     priceVATexcl: 10,
    //     VAT: 21,
    //     priceVATincl: null,
    //     organisationID: "4b87220f-ac51-11ed-a883-bc8385f61308",
    //     name: "School 1",
    //     nameShort: "S1",
    //     codeInternal: "INT1",
    //     codeExternal: "EXT1",
    //     photo: null,
    //     color: "red",
    //     domain: "school1.com",
    //     locationID: "457fda5c-ac53-11ed-a883-bc8385f61308",
    //     desc: "classroom1",
    //     code: "cr1",
    // };

    const columnsProducts: GridColDef[] = [
        { field: "productID", headerName: "ID", width: 0 },
        { field: "categoryName", headerName: "Category", width: 150 },
        { field: "subcategoryName", headerName: "Subcategory", width: 150 },
        { field: "manufacturerName", headerName: "Manufacturer", width: 150 },
        { field: "modelNumber", headerName: "Model Number", width: 150 },
        { field: "productName", headerName: "Product Name", width: 150 },
        { field: "serialNumber", headerName: "Serial Number", width: 150 },
        { field: "notes", headerName: "Notes", width: 150 },
        { field: "statusName", headerName: "Status", width: 150 },
        { field: "School", headerName: "name", width: 150 },
        { field: "Location", headerName: "desc", width: 150 },
    ];

    const columnsProductsOverview: GridColDef[] = [
        {
            field: "action",
            headerName: "Goto product",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    navigate(`/product/${params.id}`);
                };
                return <Button onClick={onClick}>...</Button>;
            },
        },
        { field: "productID", headerName: "ID", width: 0 },
        { field: "categoryName", headerName: "Category", width: 150 },
        { field: "subcategoryName", headerName: "Subcategory", width: 150 },
        { field: "manufacturerName", headerName: "Manufacturer", width: 150 },
        { field: "modelNumber", headerName: "Model Number", width: 150 },
        { field: "productName", headerName: "Product Name", width: 150 },
        { field: "serialNumber", headerName: "Serial Number", width: 150 },
        { field: "notes", headerName: "Notes", width: 150 },
        { field: "statusName", headerName: "Status", width: 150 },
        { field: "School", headerName: "name", width: 150 },
        { field: "Location", headerName: "desc", width: 150 },
    ];

    const columnsSchools: GridColDef[] = [
        // { field: "id", headerName: "ID", width:0 },
        {
            field: "name",
            headerName: "Name",
            width: 250,
            editable: true,
        },
        // {
        //     field: "domain",
        //     headerName: "Domain",
        //     width: 150,
        //     editable: true,
        // },
        {
            field: "nameShort",
            headerName: "Short Name",
            width: 80,
            editable: true,
        },
        {
            field: "codeInternal",
            headerName: "Internal Code",
            width: 80,
            editable: true,
        },
        {
            field: "codeExternal",
            headerName: "External Code",
            width: 80,
            editable: true,
        },
    ];

    const columnsLocations: GridColDef[] = [
        // { field: "id", headerName: "ID", width:0 },
        {
            field: "code",
            headerName: "Code",
            width: 100,
            editable: true,
        },
        {
            field: "description",
            headerName: "Description",
            width: 250,
            editable: true,
        },
    ];

    useEffect(() => {
        async function purchasesData() {
            try {
                const response = await axios.get(hostsConfig.BACKEND_HOST + "/purchases_by_filters/null/null/null/null/null/null/null");
                console.log(response.data.purchases);

                let purchases = response.data.purchases;

                //for every purchase, get the purshaseID and add it to the array as "id" also
                for (let i = 0; i < purchases.length; i++) {
                    purchases[i].id = purchases[i].purchaseID;
                }

                setPurchases(response.data.purchases);
                setAllPurchases(response.data.purchases);
            } catch (error) {
                console.error(error);
            }
        }
        purchasesData();

        async function schoolsData() {
            try {
                const response = await axios.get(hostsConfig.BACKEND_HOST + "/organisationstree");
                console.log(response);

                let schools = response.data;
                for (let i = 0; i < schools.length; i++) {
                    if (schools[i].locations.length > 0) {
                        for (let j = 0; j < schools[i].locations.length; j++) {
                            if (schools[i].locations[j].id === null) {
                                //remove this location
                                schools[i].locations.splice(j, 1);
                            }
                        }
                    }
                }

                setSchools(schools);
            } catch (error) {
                console.error(error);
            }
        }
        schoolsData();

        setProductsInCart(session?.selectedProducts || []);
    }, []);

    useEffect(() => {
        var pathname = window.location.pathname;

        if (purchases.length !== 0 && pathname.includes("/purchase/")) {
            if (pathname.includes("/productList")) {
                pathname = pathname.replace("/productList", "");
                setTabValue(0);
            }
            if (pathname.includes("/invoiceDetails")) {
                pathname = pathname.replace("/invoiceDetails", "");
                setTabValue(4);
            }

            let purchaseID = pathname.replace("/purchase/", "");

            //validate if productID is a GUID
            if (purchaseID.length !== 36) {
                console.log("productID is not a GUID");
                setMessage("Product ID is not a correct");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            try {
                async function getHistoryByProduct() {
                    try {
                        setSelectedPurchase(purchaseID);
                        //todo :not all info are updated !!!!
                        handleRowClick({ id: purchaseID });

                        if (purchases.length !== 0) {
                            const selectedRowIndex = purchases.findIndex((row) => row.id === purchaseID);
                            const rowsPerPage = 6; // Assuming 10 rows per page
                            const newPage = Math.floor(selectedRowIndex / rowsPerPage);
                            setPurchasePage(newPage);

                            console.log(purchaseID);
                            window.scrollTo(0, 2000);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }
                getHistoryByProduct();
            } catch (error) {
                console.log(error);
            }
        }
    }, [purchases]);

    const handleRowClick: GridEventListener<"rowClick"> = async (params) => {
        try {
            setSelectedPurchase(params.id);

            // const [supplierID, setSupplierID] = useState("");
            // const [purchaseDate, setPurchaseDate] = useState("");
            // const [URLfolder, setURLfolder] = useState("");
            // const [URLfiles, setURLfiles] = useState([]);
            // const [notes, setNotes] = useState("");
            // const [invoiceNrExt, setInvoiceNrExt] = useState("");
            // const [invoiceNrInt, setInvoiceNrInt] = useState("");
            // const [invoiceNrSch, setInvoiceNrSch] = useState("");

            //find the purchase in the array of purchases and set the state to the values of that purchase
            for (let i = 0; i < purchases.length; i++) {
                if (purchases[i].id === params.id) {
                    setSelectedSupplierID(purchases[i].supplierID);
                    setSelectedPurchaseDate(purchases[i].purchaseDate);
                    setSelectedURLfolder(purchases[i].URLfolder);
                    setSelectedURLfiles(purchases[i].URLfile);
                    setSelectedNotes(purchases[i].notes);

                    if (purchases[i].invoiceNrExt === null) {
                        setEditInvoiceNrExt("");
                    } else {
                        setEditInvoiceNrExt(purchases[i].invoiceNrExt);
                    }

                    if (purchases[i].invoiceNrInt === null) {
                        setEditInvoiceNrInt("");
                    } else {
                        setEditInvoiceNrInt(purchases[i].invoiceNrInt);
                    }

                    if (purchases[i].invoiceNrSch === null) {
                        setEditInvoiceNrSch("");
                    } else {
                        setEditInvoiceNrSch(purchases[i].invoiceNrSch);
                    }

                    setEditSupplierID(purchases[i].supplierID);

                    if (purchases[i].URLfolder === null) {
                        setEditURLFolder("");
                    } else {
                        setEditURLFolder(purchases[i].URLfolder);
                    }

                    if (purchases[i].fileID === null) {
                        setEditURLFiles([]);
                        setSelectedFiles([]);
                    } else {
                        //let raw = '{files:[{ id: 123, name: "file1" },{ id: 456, name: "file2" },{ id: 789, name: "file3" }]}';

                        //create a json variable of files and every file has id and name
                        //let testJson = '{"files":[{ "id": 123, "name": "file1" },{ "id": 456, "name": "file2" },{ "id": 789, "name": "file3" }]}';
                        //let json = JSON.parse(purchases[i].URLfile);
                        
                        let file = { "id" : purchases[i].fileID, "name" : purchases[i].fileName };

                        // setEditURLFiles(json.files);
                        // let file_ids = json.files.map((file) => file.id);
                        // setSelectedFiles(file_ids);
                        setEditURLFiles([file]);
                        //let file_ids = json.files.map((file) => file.id);
                        setSelectedFiles(file.id);
                    }

                    // setEditURLFiles(purchases[i].URLfile || "");
                    if (purchases[i].notes === null) {
                        setEditNotes("");
                    } else {
                        setEditNotes(purchases[i].notes);
                    }

                    var date = new Date(purchases[i].purchaseDate);
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero if needed
                    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed

                    setEditPurchaseDate(`${year}-${month}-${day}`);
                }
            }

            //let q = hostsConfig.BACKEND_HOST + "/products_by_purchase/" + params.id;
            async function getProductByPurchase() {
                try {
                    const response = await axios.get(hostsConfig.BACKEND_HOST + "/products_by_purchase/" + params.id);
                    console.log(response.data.products);

                    let productList = response.data.products;
                    for (let i = 0; i < productList.length; i++) {
                        productList[i].id = productList[i].productID;
                    }

                    setProducts(response.data.products);
                } catch (error) {
                    console.error(error);
                }
            }
            getProductByPurchase();

            setSelectedProduct(null);
        } catch (error) {
            console.log(error);
        }
    };

    const refreshProducts = async () => {
        try {
            let q = hostsConfig.BACKEND_HOST + "/products_by_purchase/" + selectedPurchase;

            async function getProductByPurchase() {
                try {
                    const response = await axios.get(q);
                    console.log(response);
                    setProducts(response.data.products);
                } catch (error) {
                    console.error(error);
                }
            }
            getProductByPurchase();
        } catch (error) {
            //console.log(error);
        }
        setSelectedProduct(null);
    };

    const handleRowClickProduct: GridEventListener<"rowClick"> = async (params) => {
        try {
            //if params.id is not included in the selectedRows array, add it.
            if (selectedRows.includes(params.id)) {
                setSelectedProduct(null);
            } else {
                setSelectedProduct(params.id);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleRowClickProductOverview: GridEventListener<"rowClick"> = async (params) => {
        try {
            setSelectedProduct(params.id);
        } catch (error) {
            console.log(error);
        }
    };

    const handleRowDoubleClickProduct: GridEventListener<"rowDoubleClick"> = async (params) => {
        try {
            setSelectedProduct(params.id);
        } catch (error) {
            console.log(error);
        }
    };

    const handleRowDoubleClickProductOverview: GridEventListener<"rowDoubleClick"> = async (params) => {
        try {
            setSelectedProduct(params.id);
            navigate(`/product/${params.id}`);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectionModelChange: GridEventListener<"selectionChange"> = (ids) => {
        try {
            // const selectedIDs = new Set(ids);
            // const selectedRows = employees.filter((row) =>
            //     selectedIDs.has(row.id),
            // );
            setSelectedRows(ids);
        } catch (e) {
            console.log(e);
        }
    };

    const handleGotoProduct = () => {
        if (selectedProduct) {
            try {
                navigate(`/product/${selectedProduct}`);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleChangeProductsLocation = async () => {
        for (let i = 0; i < selectedRows.length; i++) {
            let ID = selectedRows[i];

            if (session.environment === undefined) {
                return;
            }

            //get the actionTypeID from the environment.actionTypes array of objects where the actionName is "Relocate"
            let actionTypeID = session.environment.actiontype.find((x) => x.actionName === "Relocate").actionTypeID;
            let accountEmail = auth?.userInfo?.email || "";

            try {
                let body = {
                    productID: ID,
                    organisationID: newSchool,
                    locationID: newLocation,
                    inputLocation: newInputLocation,
                    actionTypeID: actionTypeID,
                    accountEmail: accountEmail,
                    notes: newNotes,
                };

                const response = await axios.post(hostsConfig.BACKEND_HOST + "/changeProductOrganisationLocationInputLocation", body);
                refreshProducts();

                setNewLocation("");
                setNewInputLocation("");
                setNewSchool("");
                setNewNotes("");

                setMessage("Product(s) relocated");
                setSeverity("success");
                setOpenMessage(true);

                console.log(response);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleChangeProductsStatus = async () => {
        for (let i = 0; i < selectedRows.length; i++) {
            let ID = selectedRows[i];

            if (session.environment === undefined) {
                return;
            }

            //get the actionTypeID from the environment.actionTypes array of objects where the actionName is "Relocate"
            let actionTypeID = session.environment.actiontype.find((x) => x.actionName === "Status change").actionTypeID;
            let accountEmail = auth?.userInfo?.email || "";

            try {
                let body = {
                    productID: ID,
                    statusID: newStatus,
                    actionTypeID: actionTypeID,
                    accountEmail: accountEmail,
                    historyNotes: newHistoryNotes,
                    // statusNotes: newStatusNotes,
                };

                const response = await axios.post(hostsConfig.BACKEND_HOST + "/changeProductStatus", body);
                refreshProducts();
                console.log(response);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleChangeProductsDetails = async () => {
        for (let i = 0; i < selectedRows.length; i++) {
            let ID = selectedRows[i];

            if (session.environment === undefined) {
                return;
            }

            let accountEmail = auth?.userInfo?.email || "";

            try {
                let body = {
                    productID: ID,
                    categoryID: newCategory,
                    subcategoryID: newSubcategory,
                    manufacturerID: newManufacturer,
                    modelNumber: newModelNumber,
                    productName: newProductName,
                    serialNumber: newSerialNumber,
                    notes: newDetailNotes,
                    accountEmail: accountEmail,
                };

                const response = await axios.put(hostsConfig.BACKEND_HOST + "/changeProductDetails", body);

                refreshProducts();

                console.log(response);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleSelectedSchoolChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        try {
            setSchoolDropdown(event.target.value);
            setSchoolGrid(event.target.value);
            setSelectedSchool(event.target.value);

            let q = hostsConfig.BACKEND_HOST + "/products_org_loc/" + event.target.value;
            async function getProductBySchool() {
                try {
                    const response = await axios.get(hostsConfig.BACKEND_HOST + "/products_org_loc/" + event.target.value);
                    console.log(response);
                    setProducts(response.data.products);
                } catch (error) {
                    console.error(error);
                }
            }
            getProductBySchool();
            setSelectedLocation("");
            setLocationDropdown("");
            setLocationGrid("");
            setSelectedProduct(null);
        } catch (error) {
            console.log(error);
        }
    };

    const flipOpenSchoolGrid = () => {
        setOpenSchoolGrid(!openSchoolGrid);
    };

    const flipOpenLocationlGrid = () => {
        setOpenLocationGrid(!openLocationGrid);
    };

    const handleSelectedLocationChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        try {
            setSelectedLocation(event.target.value);
            setLocationDropdown(event.target.value);
            setLocationGrid(event.target.value);
            let q = hostsConfig.BACKEND_HOST + "/products_org_loc/" + selectedSchool + "/" + event.target.value;
            async function getProductByLocation() {
                try {
                    const response = await axios.get(hostsConfig.BACKEND_HOST + "/products_org_loc/" + selectedSchool + "/" + event.target.value);
                    console.log(response);
                    setProducts(response.data.products);
                } catch (error) {
                    console.error(error);
                }
            }
            getProductByLocation();
        } catch (error) {
            console.log(error);
        }
        setSelectedProduct(null);
    };

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleChangeNumberOfProducts = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNumberOfProducts(event.target.value);
    };

    const handleCreateProducts = async () => {
        try {
            let count_success = 0;
            let count_error = 0;

            var _newPriceVATexcl = newPriceVATexcl;
            var _newVAT = newVAT;
            var _newPriceVATincl = newPriceVATincl;

            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                categoryID: newCategory,
                subcategoryID: newSubcategory,
                manufacturerID: newManufacturer,
                modelNumber: newModelNumber,
                productName: newProductName,
                serialNumber: newSerialNumber,
                detailNotes: newDetailNotes,
                statusID: newStatus,
                // statusNotes: newStatusNotes,
                statusHistoryNotes: newHistoryNotes,
                organisationID: selectedSchool,
                locationID: selectedLocation,
                relocationNotes: newNotes,
                account: accountEmail,
                purchaseID: selectedPurchase,
                priceVATexcl: _newPriceVATexcl,
                VAT: _newVAT,
                priceVATincl: _newPriceVATincl,
            };

            for (let i = 0; i < numberOfProducts; i++) {
                try {
                    const response = await axios.post(hostsConfig.BACKEND_HOST + "/insertProduct", body);

                    count_success++;
                    console.log(response);
                } catch (error) {
                    console.error(error);
                    count_error++;
                }
            }

            if (numberOfProducts === count_success) {
                setMessage(numberOfProducts + " created succesfuly.");
                setSeverity("success");
                setOpenMessage(true);
            } else {
                if (count_success > 0) {
                    setMessage("Only " + numberOfProducts + " created succesfuly.");
                    setSeverity("warning");
                    setOpenMessage(true);
                } else {
                    setMessage("No product created.");
                    setSeverity("error");
                    setOpenMessage(true);
                }
            }

            refreshProducts();

            console.log("Creating products" + numberOfProducts);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectedSupplierChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        setFilterSupplier(event.target.value);
    };

    const handleApplyFilters = async () => {
        var supplier = filterSupplier === null || filterSupplier === "" ? "null" : filterSupplier;
        var from_date = filterFrom_date === null || filterFrom_date === "" ? "null" : filterFrom_date;
        var to_date = filterTo_date === null || filterTo_date === "" ? "null" : filterTo_date;
        var URLfile = filterURLfile === null || filterURLfile === "" ? "null" : filterURLfile;
        var invoiceNrExt = filterInvoiceNrExt === null || filterInvoiceNrExt === "" ? "null" : filterInvoiceNrExt;
        var invoiceNrInt = filterInvoiceNrInt === null || filterInvoiceNrInt === "" ? "null" : filterInvoiceNrInt;
        var invoiceNrSch = filterInvoiceNrSch === null || filterInvoiceNrSch === "" ? "null" : filterInvoiceNrSch;

        let q =
            hostsConfig.BACKEND_HOST +
            "/purchases_by_filters/" +
            supplier +
            "/" +
            from_date +
            "/" +
            to_date +
            "/" +
            URLfile +
            "/" +
            invoiceNrExt +
            "/" +
            invoiceNrInt +
            "/" +
            invoiceNrSch;
        async function getPurchasesByFilters() {
            try {
                const response = await axios.get(
                    hostsConfig.BACKEND_HOST +
                        "/purchases_by_filters/" +
                        supplier +
                        "/" +
                        from_date +
                        "/" +
                        to_date +
                        "/" +
                        URLfile +
                        "/" +
                        invoiceNrExt +
                        "/" +
                        invoiceNrInt +
                        "/" +
                        invoiceNrSch
                );
                console.log(response);
                let purchases = response.data.purchases;
                for (let i = 0; i < purchases.length; i++) {
                    purchases[i].id = purchases[i].purchaseID;
                }
                setPurchases(response.data.purchases);
            } catch (error) {
                console.error(error);
            }
        }
        getPurchasesByFilters();
    };

    const handleClearFilters = async () => {
        setFilterSupplier("");
        setFilterFrom_date("");
        setFilterTo_date("");
        setFilterURLfile("");
        setFilterInvoiceNrExt("");
        setFilterInvoiceNrInt("");
        setFilterInvoiceNrSch("");
    };

    const handleFrom_dateChange = (e) => {
        setFilterFrom_date(e.target.value);
    };

    const handleTo_dateChange = (e) => {
        setFilterTo_date(e.target.value);
    };

    const handleGetFiles = async () => {
        try {
            if (selectedPurchase === null || selectedPurchase === "") {
                setMessage("Please select a purchase.");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            if (editURLFolder === null || editURLFolder === "") {
                setMessage("Folder URL is empty.");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            const token = await getToken();
            const FOLDER_ID = "0ANoyiQLfKc6RUk9PVA";
            //const FOLDER_ID = "1J4AgSIHUAxxfXhWskvXbH9QELDYns2uZ"; // SGR - programming / inventory application
            const url = `https://www.googleapis.com/drive/v3/files?q='${FOLDER_ID}'+in+parents+and+trashed=false`;

            var allFiles = [];
            var allSpreadsheet = [];
            var allFolders = [];

            const requestDrive = await fetch(
                `https://www.googleapis.com/drive/v3/files?corpora=drive&driveId=${FOLDER_ID}&includeTeamDriveItems=true&supportsAllDrives=true`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            const dataDrive = await requestDrive.json();
            allFiles = dataDrive.files;
            allSpreadsheet = allFiles.filter((file) => file.mimeType === "application/vnd.google-apps.spreadsheet");
            allFolders = allFiles.filter((file) => file.mimeType === "application/vnd.google-apps.folder");

            let nextToken = dataDrive.nextPageToken;

            while (nextToken !== undefined) {
                const requestDrive = await fetch(
                    `https://www.googleapis.com/drive/v3/files?corpora=drive&driveId=${FOLDER_ID}&includeTeamDriveItems=true&supportsAllDrives=true&pageToken=${nextToken}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                    }
                );
                const dataDrive = await requestDrive.json();
                allFiles = [...allFiles, ...dataDrive.files];
                allSpreadsheet = [
                    ...allSpreadsheet,
                    ...dataDrive.files.filter((file) => file.mimeType === "application/vnd.google-apps.spreadsheet"),
                ];
                allFolders = [...allFolders, ...dataDrive.files.filter((file) => file.mimeType === "application/vnd.google-apps.folder")];

                nextToken = dataDrive.nextPageToken;
            }

            setFiles(allSpreadsheet);

            // let file = {
            //     kind: "drive#file",
            //     driveId: "0ANoyiQLfKc6RUk9PVA",
            //     mimeType: "application/vnd.google-apps.spreadsheet",
            //     id: "1azT3R-X54uV9_7ECp8ng9LmsH5Ijdw0DxgvMGabLNzw",
            //     name: "printout_2022 04 25 12 08",
            //     teamDriveId: "0ANoyiQLfKc6RUk9PVA",
            // };
        } catch (error) {
            console.log("userInfo 35 | error getting calendar data", error);
            return error.message;
        }
    };

    const handleChangeProductsPurchase = async () => {
        console.log(selectedPurchaseToChange);
        console.log(selectedRows);

        if (selectedPurchaseToChange === selectedPurchase) {
            setMessage("You cannot change a purchase to itself");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        var productsChanged = 0;
        var productsNotChanged = 0;

        for (let i = 0; i < selectedRows.length; i++) {
            let ID = selectedRows[i];

            if (session.environment === undefined) {
                return;
            }

            //get the actionTypeID from the environment.actionTypes array of objects where the actionNames are "Purchase" and "Purchase change"
            let newActionTypeID = session.environment.actiontype.find((x) => x.actionName === "Purchase").actionTypeID;
            let oldActionTypeID = session.environment.actiontype.find((x) => x.actionName === "Purchase changed").actionTypeID;
            let newPurchaseID = selectedPurchaseToChange;
            let oldPurchaseID = selectedPurchase;
            let accountEmail = auth?.userInfo?.email || "";

            try {
                let body = {
                    productID: ID,
                    newPurchaseID: newPurchaseID,
                    newActionTypeID: newActionTypeID,
                    oldPurchaseID: oldPurchaseID,
                    oldActionTypeID: oldActionTypeID,
                    accountEmail: accountEmail,
                    notes: newNotes,
                };

                const response = await axios.post(hostsConfig.BACKEND_HOST + "/changeProductPurchace", body);
                refreshProducts();
                console.log(response);

                productsChanged++;
            } catch (error) {
                console.error(error);
                productsNotChanged++;
            }
        }
        setMessage(productsChanged + " products changed, " + productsNotChanged + " products not changed");
        setSeverity("success");
        setOpenMessage(true);
    };

    const handleChangeProductsPrices = async () => {
        console.log("handleChangeProductsPrices");
        console.log(selectedPurchaseToChange);
        console.log(selectedRows);
        console.log(products);

        console.log("newPriceVATexcl", newPriceVATexcl);
        console.log("newVAT", newVAT);
        console.log("newPriceVATincl", newPriceVATincl);

        var _newPriceVATexcl = newPriceVATexcl;
        var _newVAT = newVAT;
        var _newPriceVATincl = newPriceVATincl;

        var productsChanged = 0;
        var productsNotChanged = 0;

        for (let i = 0; i < selectedRows.length; i++) {
            let ID = selectedRows[i];

            let product = products.find((x) => x.id === ID);
            if (product === undefined) {
                productsNotChanged++;
                continue;
            }

            if (session.environment === undefined) {
                return;
            }

            // const productID = body.productID;
            // const newActionTypeID = body.newActionTypeID;
            // const accountEmail = body.accountEmail;

            // const oldPriceVATexcl = body.oldPriceVATexcl;
            // const oldVAT = body.oldVAT;
            // const oldPriceVATincl = body.oldPriceVATincl;

            // const newPriceVATexcl = body.newPriceVATexcl;
            // const newVAT = body.newVAT;
            // const newPriceVATincl = body.newPriceVATincl;

            let newActionTypeID = session.environment.actiontype.find((x) => x.actionName === "Prices change").actionTypeID;
            let accountEmail = auth?.userInfo?.email || "";

            let oldPriceVATexcl = product.priceVATexcl;
            let oldVAT = product.VAT;
            let oldPriceVATincl = product.priceVATincl;

            // let newPriceVATexcl = newPriceVATexcl;
            // let newVAT = newVAT;
            // let newPriceVATincl = newPriceVATincl;
            let purchaseID = selectedPurchase;

            try {
                let body = {
                    productID: ID,
                    purchaseID: purchaseID,
                    newActionTypeID: newActionTypeID,
                    accountEmail: accountEmail,
                    oldPriceVATexcl: oldPriceVATexcl,
                    oldVAT: oldVAT,
                    oldPriceVATincl: oldPriceVATincl,
                    newPriceVATexcl: _newPriceVATexcl,
                    newVAT: _newVAT,
                    newPriceVATincl: _newPriceVATincl,
                };

                const response = await axios.post(hostsConfig.BACKEND_HOST + "/changeProductPrices", body);
                refreshProducts();
                console.log(response);

                productsChanged++;
            } catch (error) {
                console.error(error);
                productsNotChanged++;
            }
        }
        setMessage(productsChanged + " products changed, " + productsNotChanged + " products not changed");
        setSeverity("success");
        setOpenMessage(true);
    };

    const handleRemoveProductsPurchase = async () => {
        setOpenDialog(true);
        setDialogTitle("Remove products from purchase");
        setDialogMessage("Are you sure you want to remove the selected products from the purchase?");
        setDialogAction("confirmedRemoveProductsPurchase");
    };

    const confirmedRemoveProductsPurchase = async () => {
        // console.log(selectedPurchaseToChange);
        // console.log(selectedRows);

        console.log(selectedPurchaseToChange);
        console.log(selectedRows);

        if (selectedPurchaseToChange !== "") {
            setMessage("Something went wrong, please try again");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        var productsChenged = 0;
        var productsNotChenged = 0;

        for (let i = 0; i < selectedRows.length; i++) {
            let ID = selectedRows[i];

            if (session.environment === undefined) {
                return;
            }

            //get the actionTypeID from the environment.actionTypes array of objects where the actionNames are "Purchase" and "Purchase change"
            let newActionTypeID = session.environment.actiontype.find((x) => x.actionName === "Purchase removed").actionTypeID;
            let oldActionTypeID = session.environment.actiontype.find((x) => x.actionName === "Purchase changed").actionTypeID;
            let oldPurchaseID = selectedPurchase;
            let accountEmail = auth?.userInfo?.email || "";

            try {
                let body = {
                    productID: ID,
                    newActionTypeID: newActionTypeID,
                    oldPurchaseID: oldPurchaseID,
                    oldActionTypeID: oldActionTypeID,
                    accountEmail: accountEmail,
                    notes: newNotes,
                };

                const response = await axios.post(hostsConfig.BACKEND_HOST + "/removeProductPurchace", body);
                refreshProducts();
                console.log(response);

                productsChenged++;
            } catch (error) {
                console.error(error);
                productsNotChenged++;
            }
        }
        setMessage(productsChenged + " products removed, " + productsNotChenged + " products not removed");
        setSeverity("success");
        setOpenMessage(true);
    };

    const handleSavePurchaseDetails = async () => {
        console.log("handleSavePurchaseDetails");
        console.log(selectedPurchaseToChange);
        console.log(newNotes);

        if (selectedPurchase === "") {
            setMessage("Please select a supplier.");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        if (session.environment === undefined) {
            return;
        }

        let _supplierID = editSupplierID;
        let _purchaseID = selectedPurchase;
        let _accountEmail = auth?.userInfo?.email || "";
        let _purchaseDate = editPurchaseDate;
        let _notes = editNotes;
        let _invExt = editInvoiceNrExt;
        let _invInt = editInvoiceNrInt;
        let _invSch = editInvoiceNrSch;

        let _folder = editURLFolder;
        let _filesArr = editURLFiles;

        let _files = { files: editURLFiles };

        let _jsonFiles = JSON.stringify(_files);

        //const query = `CALL update_purchase(${purchaseID},${supplierID},${purchaseDate},${URLfolder},${URLfile},${notes},${invoiceNrExt},${invoiceNrInt},${invoiceNrSch},${accountEmail})`;

        try {
            let body = {
                purchaseID: _purchaseID,
                supplierID: _supplierID,
                purchaseDate: _purchaseDate,
                URLfolder: _folder,
                URLfile: _jsonFiles,
                notes: _notes,
                invoiceNrExt: _invExt,
                invoiceNrInt: _invInt,
                invoiceNrSch: _invSch,
                accountEmail: _accountEmail,
            };

            const response = await axios.put(hostsConfig.BACKEND_HOST + "/update_purchase", body);
            refreshProducts();
            console.log(response);
            if (response.data[0].purchaseID === _purchaseID) {
                setMessage("Purchase updated");
                setSeverity("success");
                setOpenMessage(true);
            } else {
                setMessage("Something went wrong, please try again");
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error(error);
            setMessage("Something went wrong, please try again");
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    //add

    const handleAddNewPurshase = async () => {
        console.log("handleAddPurchase");
        console.log(newNotes);

        // if (selectedPurchase === "") {
        //     setMessage("Please select a supplier.");
        //     setSeverity("error");
        //     setOpenMessage(true);
        //     return;
        // }

        if (session.environment === undefined) {
            return;
        }

        let _supplierID = editSupplierID;
        // let _purchaseID = selectedPurchase;
        let _accountEmail = auth?.userInfo?.email || "";
        let _purchaseDate = editPurchaseDate;
        let _notes = editNotes;
        let _invExt = editInvoiceNrExt;
        let _invInt = editInvoiceNrInt;
        let _invSch = editInvoiceNrSch;

        let _folder = editURLFolder;
        let _filesArr = editURLFiles;

        let _files = { files: editURLFiles };

        let _jsonFiles = JSON.stringify(_files);

        //const query = `CALL update_purchase(${purchaseID},${supplierID},${purchaseDate},${URLfolder},${URLfile},${notes},${invoiceNrExt},${invoiceNrInt},${invoiceNrSch},${accountEmail})`;

        try {
            let body = {
                //purchaseID: _purchaseID,
                supplierID: _supplierID,
                purchaseDate: _purchaseDate,
                URLfolder: _folder,
                URLfile: _jsonFiles,
                notes: _notes,
                invoiceNrExt: _invExt,
                invoiceNrInt: _invInt,
                invoiceNrSch: _invSch,
                accountEmail: _accountEmail,
            };

            const response = await axios.put(hostsConfig.BACKEND_HOST + "/add_new_purchase", body);
            refreshProducts();
            console.log(response);

            setMessage("Purchase added");
            setSeverity("success");
            setOpenMessage(true);
        } catch (error) {
            console.error(error);
            setMessage("Something went wrong, please try again");
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDialogAction("");
        setDialogTitle("");
        setDialogMessage("");
    };

    const handleConfirmDialog = () => {
        setOpenDialog(false);
        switch (dialogAction) {
            case "confirmedRemoveProductsPurchase":
                confirmedRemoveProductsPurchase();
                setDialogAction("");
                setDialogTitle("");
                setDialogMessage("");
                break;
            default:
                break;
        }
    };

    const handleRowClickChangePurchase: GridEventListener<"rowClick"> = async (params) => {
        try {
            setSelectedPurchaseToChange(params.id);
        } catch (error) {
            console.log(error);
        }
    };

    // const handleChangeEditSupplierID = (e) => {
    //     setEditSupplierID(e.target.value);
    // };

    const handleSelectedEditSupplierChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        setEditSupplierID(event.target.value);
    };

    const handleChangeEditPurchaseDate = (e) => {
        setEditPurchaseDate(e.target.value);
    };

    const handleChangeEditInvoiceNrExt = (e) => {
        setEditInvoiceNrExt(e.target.value);
    };

    const handleChangeEditInvoiceNrInt = (e) => {
        setEditInvoiceNrInt(e.target.value);
    };

    const handleChangeEditInvoiceNrSch = (e) => {
        setEditInvoiceNrSch(e.target.value);
    };

    const handleChangeEditURLFolder = (e) => {
        setEditURLFolder(e.target.value);
    };

    const handleChangeEditNotes = (e) => {
        setEditNotes(e.target.value);
    };

    const handleRowClickFile: GridEventListener<"rowClick"> = async (params) => {
        try {
            //if params.id is not included in the selectedFiles array, add it.
            if (selectedFiles.includes(params.id)) {
                setSelectedFiles(null);
            } else {
                setSelectedFiles(params.id);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleRowDoubleClickFile: GridEventListener<"rowDoubleClick"> = async (params) => {
        try {
            //if params.id is not included in the selectedFiles array, add it.
            if (selectedFiles.includes(params.id)) {
                setSelectedFiles(null);
            } else {
                setSelectedFiles(params.id);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectionFileModelChange: GridEventListener<"selectionChange"> = (ids) => {
        try {
            setSelectedFiles(ids);

            let filesArr = files.filter((file) => ids.includes(file.id));

            let myFileArray = filesArr.map((file) => {
                return {
                    id: file.id,
                    name: file.name,
                };
            });

            setEditURLFiles(myFileArray);
        } catch (e) {
            console.log(e);
        }
    };

    // handleDelete is a function that deletes get the key of the selected chip and deletes it from the array of selected chips
    const handleDeleteFile = (fileToDelete) => () => {
        let filesArr = editURLFiles.filter((chip) => chip.id !== fileToDelete);
        setEditURLFiles(filesArr);
        let file_ids = filesArr.map((file) => file.id);
        setSelectedFiles(file_ids);
    };

    const handleClickFile = (fileToDelete) => () => {
        console.log(fileToDelete);
    };

    // const handleSelectionPurchasesChange: GridEventListener<"selectionChange"> = (ids) => {
    //     try {
    //         // const selectedIDs = new Set(ids);
    //         // const selectedRows = employees.filter((row) =>
    //         //     selectedIDs.has(row.id),
    //         // );
    //         setSelectedPurchaseToChange(ids);
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    //========================================================================================
    //========================================================================================
    //========================================================================================
    //========================================================================================
    //========================================================================================
    //========================================================================================
    //========================================================================================

    return (
        <Box sx={{ m: 2 }}>
            <Grid container spacing={2}>
                {/* <Grid item md={12} lg={12}>
                    <Typography align="center" variant="h5">
                        Purchases
                    </Typography>
                </Grid> */}
                <Grid item md={12} lg={12}>
                    <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                        {/* <Typography>Schools</Typography> */}

                        {/*==== Filters ==== */}
                        <FormControl sx={{ minWidth: 300 }} size="small">
                            <InputLabel id="demo-select-small">Suppliers</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={filterSupplier}
                                label="Suppliers"
                                onChange={handleSelectedSupplierChange}
                            >
                                {session.environment?.suppliers.map((sup, index) => (
                                    <MenuItem key={index} value={sup.SupplierID}>
                                        {sup.SupplierName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            InputLabelProps={{ shrink: true }}
                            label="From date"
                            size="small"
                            type="date"
                            value={filterFrom_date}
                            onChange={handleFrom_dateChange}
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            label="To date"
                            size="small"
                            type="date"
                            value={filterTo_date}
                            onChange={handleTo_dateChange}
                        />

                        <TextField
                            label="invoiceNrExt"
                            size="small"
                            value={filterInvoiceNrExt}
                            sx={{ width: 150 }}
                            onChange={(e) => setFilterInvoiceNrExt(e.target.value)}
                        />
                        <TextField
                            label="invoiceNrInt"
                            size="small"
                            value={filterInvoiceNrInt}
                            sx={{ width: 150 }}
                            onChange={(e) => setFilterInvoiceNrInt(e.target.value)}
                        />
                        <TextField
                            label="invoiceNrSch"
                            size="small"
                            value={filterInvoiceNrSch}
                            sx={{ width: 150 }}
                            onChange={(e) => setFilterInvoiceNrSch(e.target.value)}
                        />

                        <Button variant="contained" onClick={handleApplyFilters}>
                            Apply filters
                        </Button>
                        <Button variant="contained" onClick={handleClearFilters}>
                            Clear filters
                        </Button>
                        {/*==== Filters ==== */}

                        <Box sx={{ height: 346, backgroundColor: "#90caf9" }}>
                            <DataGrid
                                rows={purchases}
                                columns={columnPurchases}
                                pageSize={6}
                                rowsPerPageOptions={[6]}
                                page={purchasePage}
                                onPageChange={(newPage) => setPurchasePage(newPage)}
                                //checkboxSelection
                                //disableSelectionOnClick
                                experimentalFeatures={{}}
                                //editMode="none"
                                onRowClick={handleRowClick}
                                onRowDoubleClick={handleRowClick}
                                density="compact"
                                selectionModel={selectedPurchase}
                            />
                        </Box>
                    </Card>
                </Grid>
            </Grid>

            {/* ========================= */}

            <Box pt={3} sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                    <Tab label="Overview" {...a11yProps(0)} />
                    <Tab label="Change products" {...a11yProps(1)} />
                    <Tab label="Add new products in purchase" {...a11yProps(2)} />
                    <Tab label="Add products from cart" {...a11yProps(3)} />
                    <Tab label="Edit / Add purchase" {...a11yProps(4)} />
                </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
                <Grid container spacing={2}>
                    <Grid item md={12} lg={12}>
                        <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                            <Typography variant="h6">
                                {selectedSchool === null
                                    ? "Products without school"
                                    : selectedLocation === ""
                                    ? "Products in purchase"
                                    : "Products in location"}
                            </Typography>

                            <Box
                                sx={{
                                    height: 350,
                                    width: "100%",
                                    backgroundColor: "#90caf9",
                                }}
                            >
                                <DataGrid
                                    //rows are the locations of the selected school
                                    rows={products}
                                    columns={columnsProductsOverview}
                                    pageSize={100}
                                    rowsPerPageOptions={[100]}
                                    page={productPage}
                                    onPageChange={(newPage) => setProductPage(newPage)}
                                    //checkboxSelection
                                    //disableSelectionOnClick

                                    experimentalFeatures={{}}
                                    // editMode="none"
                                    onRowClick={handleRowClickProductOverview}
                                    onRowDoubleClick={handleRowDoubleClickProductOverview}
                                    //selectionModel={selectedRows}
                                    //onSelectionModelChange={handleSelectionModelChange}
                                    density="compact"
                                />
                            </Box>

                            {/* {selectedProduct && products.length > 0 && (

                                    <Button variant="contained" onClick={handleGotoProduct}>
                                        Goto Product {products.filter((p) => p.id === selectedProduct)[0]?.productName}
                                    </Button>
                            )} */}
                        </Card>
                    </Grid>
                </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
                <Grid container spacing={2}>
                    <Grid item md={12} lg={12}>
                        <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                            <Typography variant="h6">Products in purchase</Typography>

                            <Box
                                sx={{
                                    height: 350,
                                    width: "100%",
                                    backgroundColor: "#90caf9",
                                }}
                            >
                                <DataGrid
                                    //rows are the locations of the selected school
                                    rows={products}
                                    columns={columnsProducts}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    page={productMultyPage}
                                    onPageChange={(newPage) => setProductMultyPage(newPage)}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    experimentalFeatures={{}}
                                    // editMode="none"
                                    onRowClick={handleRowClickProduct}
                                    onRowDoubleClick={handleRowDoubleClickProduct}
                                    selectionModel={selectedRows}
                                    onSelectionModelChange={handleSelectionModelChange}
                                    density="compact"
                                />
                            </Box>

                            {/* {selectedProduct && products.length > 0 && (

                                    <Button variant="contained" onClick={handleGotoProduct}>
                                        Goto Product {products.filter((p) => p.id === selectedProduct)[0]?.productName}
                                    </Button>
                            )} */}
                        </Card>
                    </Grid>
                </Grid>

                {selectedRows.length > 0 && (
                    <>
                        <ProductDetails
                            selectedRows={selectedRows}
                            schools={schools}
                            newSchool={newSchool}
                            setNewSchool={setNewSchool}
                            newLocation={newLocation}
                            setNewLocation={setNewLocation}
                            newInputLocation={newInputLocation}
                            setNewInputLocation={setNewInputLocation}
                            // todo:not complete
                            newRelocationNotes={newNotes}
                            setNewRelocationNotes={setNewNotes}
                            handleChangeProductsLocation={handleChangeProductsLocation}
                            statuses={session.environment?.statustype}
                            newStatus={newStatus}
                            setNewStatus={setNewStatus}
                            // newStatusNotes={newStatusNotes}
                            // setNewStatusNotes={setNewStatusNotes}
                            newHistoryNotes={newHistoryNotes}
                            setNewHistoryNotes={setNewHistoryNotes}
                            handleChangeProductsStatus={handleChangeProductsStatus}
                            categories={session.environment?.categories}
                            newCategory={newCategory}
                            setNewCategory={setNewCategory}
                            subcategories={session.environment?.subcategories}
                            newSubcategory={newSubcategory}
                            setNewSubcategory={setNewSubcategory}
                            manufacturers={session.environment?.manufacturers}
                            newManufacturer={newManufacturer}
                            setNewManufacturer={setNewManufacturer}
                            newModelNumber={newModelNumber}
                            setNewModelNumber={setNewModelNumber}
                            newProductName={newProductName}
                            setNewProductName={setNewProductName}
                            newSerialNumber={newSerialNumber}
                            setNewSerialNumber={setNewSerialNumber}
                            newDetailNotes={newDetailNotes}
                            setNewDetailNotes={setNewDetailNotes}
                            handleChangeProductsDetails={handleChangeProductsDetails}
                            mode="change"
                            handleCreateProducts={handleCreateProducts}
                            hasPrices="true"
                            newPriceVATexcl={newPriceVATexcl}
                            setNewPriceVATexcl={setNewPriceVATexcl}
                            newVAT={newVAT}
                            setNewVAT={setNewVAT}
                            newPriceVATincl={newPriceVATincl}
                            setNewPriceVATincl={setNewPriceVATincl}
                            handleChangeProductsPrices={handleChangeProductsPrices}
                        />

                        {/* <Grid item md={12} lg={12}>
                            <Box sx={{ maxWidth: "100%" }}>
                                <Typography>
                                    Change status (for {selectedRows.length} product{selectedRows.length > 1 ? "s" : ""}){" "}
                                </Typography>
                            </Box>
                        </Grid> */}

                        <Grid item md={12} lg={12}>
                            <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                                <Typography variant="h6">Purchases</Typography>
                                <Box p={1} sx={{ height: 346 }}>
                                    <DataGrid
                                        rows={allPurchases}
                                        columns={columnPurchases}
                                        pageSize={7}
                                        rowsPerPageOptions={[5, 7]}
                                        page={changePurchasePage}
                                        onPageChange={(newPage) => setChangePurchasePage(newPage)}
                                        //checkboxSelection
                                        disableSelectionOnClick
                                        experimentalFeatures={{}}
                                        //editMode="none"
                                        onRowClick={handleRowClickChangePurchase}
                                        onRowDoubleClick={handleRowClickChangePurchase}
                                        selectionModel={selectedPurchaseToChange}
                                        // onSelectionModelChange={handleSelectionPurchasesChange}
                                        density="compact"
                                    />
                                </Box>
                                {selectedPurchaseToChange !== "" && (
                                    <Box pl={1} pt={1}>
                                        <Button variant="contained" onClick={handleChangeProductsPurchase}>
                                            Change purchase (for {selectedRows.length} product{selectedRows.length > 1 ? "s" : ""})
                                        </Button>
                                    </Box>
                                )}
                                {selectedPurchaseToChange === "" && (
                                    <Box pl={1} pt={1}>
                                        <Button variant="contained" onClick={handleRemoveProductsPurchase} sx={{ backgroundColor: "red" }}>
                                            Remove purchase (for {selectedRows.length} product{selectedRows.length > 1 ? "s" : ""})
                                        </Button>
                                    </Box>
                                )}
                            </Card>
                        </Grid>
                    </>
                )}
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
                <Grid container spacing={2}>
                    {selectedPurchase === null ? (
                        <>
                            <Typography color={"primary"} variant="h6">
                                Select a purchase
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Grid item md={12} lg={12}>
                                <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Number of products"
                                        variant="outlined"
                                        value={numberOfProducts}
                                        type="number"
                                        size="small"
                                        onChange={handleChangeNumberOfProducts}
                                    />
                                </Card>
                            </Grid>
                            <Grid item md={12} lg={12}>
                                <ProductDetails
                                    selectedRows={selectedRows}
                                    schools={schools}
                                    newSchool={selectedSchool}
                                    setNewSchool={setNewSchool}
                                    newLocation={selectedLocation}
                                    setNewLocation={setNewLocation}
                                    newInputLocation={newInputLocation}
                                    setNewInputLocation={setNewInputLocation}
                                    // todo:not complete
                                    newRelocationNotes={newNotes}
                                    setNewRelocationNotes={setNewNotes}
                                    handleChangeProductsLocation={handleChangeProductsLocation}
                                    statuses={session.environment?.statustype}
                                    newStatus={newStatus}
                                    setNewStatus={setNewStatus}
                                    // newStatusNotes={newStatusNotes}
                                    // setNewStatusNotes={setNewStatusNotes}
                                    newHistoryNotes={newHistoryNotes}
                                    setNewHistoryNotes={setNewHistoryNotes}
                                    handleChangeProductsStatus={handleChangeProductsStatus}
                                    categories={session.environment?.categories}
                                    newCategory={newCategory}
                                    setNewCategory={setNewCategory}
                                    subcategories={session.environment?.subcategories}
                                    newSubcategory={newSubcategory}
                                    setNewSubcategory={setNewSubcategory}
                                    manufacturers={session.environment?.manufacturers}
                                    newManufacturer={newManufacturer}
                                    setNewManufacturer={setNewManufacturer}
                                    newModelNumber={newModelNumber}
                                    setNewModelNumber={setNewModelNumber}
                                    newProductName={newProductName}
                                    setNewProductName={setNewProductName}
                                    newSerialNumber={newSerialNumber}
                                    setNewSerialNumber={setNewSerialNumber}
                                    newDetailNotes={newDetailNotes}
                                    setNewDetailNotes={setNewDetailNotes}
                                    handleChangeProductsDetails={handleChangeProductsDetails}
                                    mode="create"
                                    handleCreateProducts={handleCreateProducts}
                                    hasPrices="true"
                                    newPriceVATexcl={newPriceVATexcl}
                                    setNewPriceVATexcl={setNewPriceVATexcl}
                                    newVAT={newVAT}
                                    setNewVAT={setNewVAT}
                                    newPriceVATincl={newPriceVATincl}
                                    setNewPriceVATincl={setNewPriceVATincl}
                                    handleChangeProductsPrices={handleChangeProductsPrices}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
                <Typography color={"primary"} variant="h6">
                    {session?.selectedProducts || "clipboard is empty"}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item md={12} lg={12}>
                        <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                            <Typography variant="h6">Products in cart</Typography>

                            <Box
                                sx={{
                                    height: 350,
                                    width: "100%",
                                    backgroundColor: "#90caf9",
                                }}
                            >
                                <DataGrid
                                    //rows are the locations of the selected school
                                    rows={products}
                                    columns={columnsProducts}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    page={cartProductPage}
                                    onPageChange={(newPage) => setCartProductPage(newPage)}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    experimentalFeatures={{}}
                                    // editMode="none"
                                    onRowClick={handleRowClickProduct}
                                    onRowDoubleClick={handleRowDoubleClickProduct}
                                    selectionModel={selectedRows}
                                    onSelectionModelChange={handleSelectionModelChange}
                                    density="compact"
                                />
                            </Box>

                            {/* {selectedProduct && products.length > 0 && (

                                    <Button variant="contained" onClick={handleGotoProduct}>
                                        Goto Product {products.filter((p) => p.id === selectedProduct)[0]?.productName}
                                    </Button>
                            )} */}
                        </Card>
                    </Grid>
                </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={4}>
                <Grid container spacing={2}>
                    <Grid item md={6} lg={6}>
                        <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                            <FormControl sx={{ minWidth: 300 }} size="small">
                                <InputLabel id="demo-select-small">Change supplier</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={editSupplierID}
                                    label="Change supplier"
                                    onChange={handleSelectedEditSupplierChange}
                                >
                                    {session.environment?.suppliers.map((sup, index) => (
                                        <MenuItem key={index} value={sup.SupplierID}>
                                            {sup.SupplierName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                id="SupplierID"
                                label="Supplier ID"
                                variant="outlined"
                                value={editSupplierID}
                                size="small"
                                onChange={handleChangeEditSupplierID}
                            /> */}

                            <TextField
                                sx={{ mt: 2, maxWidth: 300 }}
                                fullWidth
                                label="Purchase date"
                                id="PurchaseDate"
                                variant="outlined"
                                value={editPurchaseDate}
                                size="small"
                                type="date"
                                onChange={handleChangeEditPurchaseDate}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="URL folder"
                                id="URLFolder"
                                variant="outlined"
                                value={editURLFolder}
                                size="small"
                                onChange={handleChangeEditURLFolder}
                            />

                            <Box
                                sx={{
                                    maxWidth: 600,
                                    mt: 2,
                                }}
                            >
                                <Typography color={"primary"} variant="body">
                                    Files
                                </Typography>
                                <Stack direction="row" flexWrap="wrap" spacing={0}>
                                    {editURLFiles.length > 0 ? (
                                        editURLFiles.map((file) => (
                                            <Chip
                                                key={file.id}
                                                label={file.name}
                                                clickable
                                                deleteIcon={<DeleteIcon />}
                                                onClick={() => {
                                                    window.open("https://drive.google.com/file/d/" + file.id + "/view", "_blank");
                                                }}
                                                onDelete={handleDeleteFile(file.id)}
                                            />
                                        ))
                                    ) : (
                                        <Typography ml={1} color={"secondary"} variant="body">
                                            No files connected with this purchase
                                        </Typography>
                                    )}
                                </Stack>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item md={6} lg={6}>
                        <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="invoice Nr Ext"
                                id="InvoiceNrExt"
                                variant="outlined"
                                value={editInvoiceNrExt}
                                size="small"
                                onChange={handleChangeEditInvoiceNrExt}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="invoice Nr Int"
                                id="InvoiceNrInt"
                                variant="outlined"
                                value={editInvoiceNrInt}
                                size="small"
                                onChange={handleChangeEditInvoiceNrInt}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="invoice Nr Sch"
                                id="InvoiceNrSch"
                                variant="outlined"
                                value={editInvoiceNrSch}
                                size="small"
                                onChange={handleChangeEditInvoiceNrSch}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Notes"
                                id="Notes"
                                variant="outlined"
                                value={editNotes}
                                size="small"
                                onChange={handleChangeEditNotes}
                            />
                        </Card>
                    </Grid>

                    <Grid item md={6} lg={6}>
                        <Box align="left" sx={{ mt: 2 }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" onClick={handleSavePurchaseDetails}>
                                    Save purshase details
                                </Button>
                                <Button variant="contained" onClick={handleAddNewPurshase}>
                                    Add new purshase
                                </Button>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid item md={6} lg={6}>
                        <Box align="right" sx={{ mt: 2 }}>
                            <Button variant="contained" sx={{ backgroundColor: "#42a5f5" }} startIcon={<CallReceivedIcon />} onClick={handleGetFiles}>
                                Show Files in folder
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item md={12} lg={12}>
                        <Box
                            sx={{
                                height: 400,
                                width: "100%",
                                backgroundColor: "#90caf9",
                            }}
                        >
                            {files.length > 0 && (
                                <DataGrid
                                    //rows are the locations of the selected school
                                    rows={files}
                                    columns={columnsFiles}
                                    pageSize={100}
                                    rowsPerPageOptions={[100]}
                                    page={filesPage}
                                    onPageChange={(newPage) => setFilesPage(newPage)}
                                    checkboxSelection
                                    //disableSelectionOnClick

                                    experimentalFeatures={{}}
                                    // editMode="none"
                                    onRowClick={handleRowClickFile}
                                    onRowDoubleClick={handleRowDoubleClickFile}
                                    selectionModel={selectedFiles}
                                    onSelectionModelChange={handleSelectionFileModelChange}
                                    density="compact"
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </TabPanel>

            {/* <Box maxWidth={800}>
                <Collapse in={openMessage}>
                    <Alert
                        severity={severity}
                        sx={{ marginTop: 2 }}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                    >
                        {message}
                    </Alert>
                </Collapse>
            </Box> */}

            <Box maxWidth={800}>
                <Snackbar
                    open={openMessage}
                    onClose={() => {
                        setOpenMessage(false);
                    }}
                    TransitionComponent={SlideTransition}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    key="Snackbar"
                    //autoHideDuration={3000}
                >
                    <Alert
                        severity={severity}
                        sx={{ marginTop: 2 }}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </Box>

            <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Disagree</Button>
                    <Button onClick={handleConfirmDialog} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export default Purchase;
