import useAuth from "../hooks/useAuth";
import useSession from "../hooks/useSession";
import { getToken } from "../tokens";

//import useEnvironment from "../hooks/useSession";
import axios from "axios";

import { useState, useEffect, useMemo } from "react";
import { json, useNavigate } from "react-router-dom";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel, TextField, Card } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";

import hostsConfig from "../hostconfig.json";

const ActionTypeCRUD = ({
    // actionTypes,
    setOpenMessage,
    setMessage,
    setSeverity,

}) => {

    const { auth, setAuth } = useAuth();
    const { session, setSession } = useSession();

    // CREATE TABLE `actiontype` (
    //     `actionTypeID` varchar(36) NOT NULL,
    //     `actionName` varchar(100) NOT NULL,
    //     `actionColor` varchar(100) DEFAULT NULL,
    //     `seq` int DEFAULT NULL,
    //     `actionLabel` varchar(100) DEFAULT NULL,
    //     PRIMARY KEY (`actionTypeID`)
    //   ) ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci;

const [actionTypes, setActionTypes] = useState(session.environment.actiontype);

    // const [inActionTypes, setInActionTypes] = useState(actionTypes);

    const [actionType, setActionType] = useState({
        actionTypeID: "",
        actionColor: "",
        actionLabel: "",
        actionName: "",
        seq: 0,
    });

    const [actionTypeNameDisabled, setActionTypeNameDisabled] = useState(true);
    const [actionTypeFieldsDisabled, setActionTypeFieldsDisabled] = useState(true);

    const [actionTypeButtonEditDisabled, setActionTypeButtonEditDisabled] = useState(false);
    const [actionTypeButtonAddDisabled, setActionTypeButtonAddDisabled] = useState(false);
    const [actionTypeButtonDeleteDisabled, setActionTypeButtonDeleteDisabled] = useState(false);

    const [actionTypeButtonCancelDisabled, setActionTypeButtonCancelDisabled] = useState(true);
    const [actionTypeButtonSubmitDisabled, setActionTypeButtonSubmitDisabled] = useState(true);

    const [actionTypeDialogMode, setActionTypeDialogMode] = useState("");



    useEffect(() => {

        //setActionTypes(session.environment.actiontype);

        setActionTypeButtonAddDisabled(false);
        setActionTypeButtonEditDisabled(false);
        setActionTypeButtonCancelDisabled(true);
        setActionTypeButtonSubmitDisabled(true);
    }, []);

    useEffect(() => {
        if (actionTypeDialogMode === "edit") {
            setActionTypeFieldsDisabled(false);

            setActionTypeNameDisabled(true);
            setActionTypeButtonEditDisabled(false);
            setActionTypeButtonAddDisabled(true);
            setActionTypeButtonDeleteDisabled(true);

            setActionTypeButtonCancelDisabled(false);
            setActionTypeButtonSubmitDisabled(false);
        }

        if (actionTypeDialogMode === "add") {
            emptyActionType();
            setActionTypeFieldsDisabled(false);

            setActionTypeNameDisabled(false);
            setActionTypeButtonEditDisabled(true);
            setActionTypeButtonAddDisabled(false);
            setActionTypeButtonDeleteDisabled(true);

            setActionTypeButtonCancelDisabled(false);
            setActionTypeButtonSubmitDisabled(false);
        }

        if (actionTypeDialogMode === "delete") {
            setActionTypeFieldsDisabled(true);

            setActionTypeNameDisabled(true);
            setActionTypeButtonEditDisabled(true);
            setActionTypeButtonAddDisabled(true);
            setActionTypeButtonDeleteDisabled(false);

            setActionTypeButtonCancelDisabled(false);
            setActionTypeButtonSubmitDisabled(false);
        }

        if (actionTypeDialogMode === "cancel") {
            setActionType(session.environment.actiontype[0]);

            setActionTypeFieldsDisabled(true);

            setActionTypeNameDisabled(true);
            setActionTypeButtonEditDisabled(false);
            setActionTypeButtonAddDisabled(false);
            setActionTypeButtonDeleteDisabled(false);

            setActionTypeButtonCancelDisabled(true);
            setActionTypeButtonSubmitDisabled(true);
        }
    }, [actionTypeDialogMode]);








    const resetButtons = () => {
        setActionTypeFieldsDisabled(true);
        setActionType(session.environment.actiontype[0]);

        setActionTypeNameDisabled(true);
        setActionTypeButtonEditDisabled(false);
        setActionTypeButtonAddDisabled(false);
        setActionTypeButtonDeleteDisabled(false);

        setActionTypeButtonCancelDisabled(true);
        setActionTypeButtonSubmitDisabled(true);
    };

    const emptyActionType = () => {
        setActionType({
            actionTypeID: "",
            actionColor: "",
            actionLabel: "",
            actionName: "",
            seq: "",
        });
    };

    const handleActionTypeSubmit = () => {
        console.log("handleActionTypeSubmit");
        console.log(actionType);

        if (actionTypeDialogMode === "edit") {
            editActionType();
        }

        if (actionTypeDialogMode === "add") {
            addActionType();
        }

        if (actionTypeDialogMode === "delete") {
            deleteActionType();
        }
    };

    const editActionType = async () => {
        console.log("editActionType");
        console.log(actionType);

        try {
            // const [actionType, setActionType] = useState({
            //     actionTypeID: "d74933d2-ab96-11ed-a883-bc8385f61308",
            //     actionColor: "#FFAFA3",
            //     actionLabel: "Purchase",
            //     actionName: "Purchase",
            //     seq: 1,
            // });

            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                actionTypeID: actionType.actionTypeID,

                actionColor: actionType.actionColor,
                actionLabel: actionType.actionLabel,
                actionName: actionType.actionName,
                seq: actionType.seq,

                notes: "update ActionType from settings page",
                accountEmail: accountEmail,
            };

            const response = await axios.put(hostsConfig.BACKEND_HOST + "/update_actionType", body);

            //update session
            let newSession = session;
            for (let i = 0; i < actionTypes.length; i++) {
                if (actionTypes[i].actionTypeID === actionType.actionTypeID) {
                    newSession.environment.actiontype[i] = actionType;
                }
            }
            setSession(newSession);

            setMessage("Updated succesfuly.");
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setActionTypeDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage("Error updating.");
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    const addActionType = async () => {
        console.log("addActionType");
        console.log(actionType);

        if (actionType.actionName === "" || actionType.actionLabel === "" || actionType.actionColor === "" || actionType.seq === "") {
            setMessage("Please fill all fields.");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        let at = actionType;

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                actionTypeID: "",

                actionColor: at.actionColor,
                actionLabel: at.actionLabel,
                actionName: at.actionName,
                seq: at.seq,

                notes: "add ActionType from settings page",
                accountEmail: accountEmail,
            };

            const response = await axios.post(hostsConfig.BACKEND_HOST + "/insert_actionType", body);

            let actionType = response.data[0];

            if (actionType === undefined) {
                setMessage("Error adding action type. Check if it already exists");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            //update session
            let newSession = session;
            newSession.environment.actiontype.push(actionType);
            setSession(newSession);

            setMessage("Added succesfuly.");
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setActionTypeDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage("Error adding.");
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    const deleteActionType = async () => {
        console.log("deleteActionType");
        console.log(actionType);

        let at = actionType;

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                actionTypeID: at.actionTypeID,

                notes: "delete ActionType from settings page",
                accountEmail: accountEmail,
            };

            const response = await axios.post(hostsConfig.BACKEND_HOST + "/delete_actionType", body);

            let newActionTypes = response.data;

            if (newActionTypes === undefined || newActionTypes.length === 0) {
                setMessage("Error deleting action type. Check if there are history entries associated with it.");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            let newSession = session;

            newSession.environment.actiontype = newActionTypes;

            setSession(newSession);
            // setActionTypes(newActionTypes);
            setActionTypes(newActionTypes);
            setActionType(newActionTypes[0]);

            setMessage("Deleted succesfuly.");
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setActionTypeDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage("Error deleting.");
            setSeverity("error");
            setOpenMessage(true);
        }
    };






    return (
        <>

                <Grid container spacing={2}>
                    <Grid item md={12} lg={12}>
                        <Typography align="center" variant="h6">
                            Action Types
                        </Typography>
                    </Grid>

                    <Grid item md={6} lg={6}>
                        <Grid container spacing={1}>
                            <Grid item md={12} lg={12}>
                                <FormControl sx={{ width: 1,pb:2 }} size="small">
                                    <InputLabel id="dropdown-actionType-label">Action type</InputLabel>
                                    <Select
                                        labelId="dropdown-actionType-label"
                                        id="dropdown-actionType"
                                        value={actionType.actionTypeID}
                                        label="Action type"
                                        onChange={(e) => {
                                            setActionType(
                                                session.environment.actiontype.find((actionType) => actionType.actionTypeID === e.target.value)
                                            );
                                            //setActionTypeDropdown(e.target.value);
                                        }}
                                    >
                                        {session.environment.actiontype.map((actionType) => (
                                            <MenuItem key={actionType.actionTypeID} value={actionType.actionTypeID}>
                                                {actionType.actionName} / {actionType.actionLabel} / {actionType.seq}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} lg={4}>
                                <Button
                                    sx={{ width: 1 }}
                                    variant="contained"
                                    onClick={() => {
                                        setActionTypeDialogMode("edit");
                                    }}
                                    disabled={actionTypeButtonEditDisabled}
                                >
                                    Edit
                                </Button>
                            </Grid>
                            <Grid item md={6} lg={4}>
                                <Button
                                    sx={{ width: 1 }}
                                    variant="contained"
                                    onClick={() => {
                                        setActionTypeDialogMode("add");
                                    }}
                                    disabled={actionTypeButtonAddDisabled}
                                >
                                    Add
                                </Button>
                            </Grid>
                            <Grid item md={6} lg={4}>
                                <Button
                                    sx={{ width: 1 }}
                                    variant="contained"
                                    onClick={() => {
                                        setActionTypeDialogMode("delete");
                                    }}
                                    disabled={actionTypeButtonDeleteDisabled}
                                >
                                    Delete
                                </Button>
                            </Grid>
                            <Grid item md={6} lg={6}>
                                <Button
                                    sx={{ width: 1 }}
                                    variant="contained"
                                    onClick={() => {
                                        setActionTypeDialogMode("cancel");
                                    }}
                                    disabled={actionTypeButtonCancelDisabled}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item md={6} lg={6}>
                                <Button
                                    sx={{ width: 1 }}
                                    variant="contained"
                                    onClick={() => {
                                        handleActionTypeSubmit();
                                    }}
                                    disabled={actionTypeButtonSubmitDisabled}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={5} lg={5}>
                        <TextField
                            sx={{ mt: 0, ml: 1, mb: 1, width: 1 }}
                            id="actionName"
                            label="Action Name"
                            variant="standard"
                            size="small"
                            value={actionType.actionName}
                            onChange={(e) => {
                                setActionType({ ...actionType, actionName: e.target.value });
                            }}
                            disabled={actionTypeNameDisabled}
                        />
                        <TextField
                            sx={{ mt: 1, ml: 1, mb: 1, width: 1 }}
                            id="actionLabel"
                            label="Action Label"
                            variant="standard"
                            value={actionType.actionLabel}
                            onChange={(e) => {
                                setActionType({ ...actionType, actionLabel: e.target.value });
                            }}
                            disabled={actionTypeFieldsDisabled}
                        />
                        <TextField
                            sx={{ mt: 1, ml: 1, mb: 1, width: 150 }}
                            id="actionColor"
                            label="Action Color"
                            variant="standard"
                            value={actionType.actionColor}
                            onChange={(e) => {
                                setActionType({ ...actionType, actionColor: e.target.value });
                            }}
                            disabled={actionTypeFieldsDisabled}
                            type="color"
                        />
                        <TextField
                            sx={{ mt: 1, ml: 1, mb: 1, width: 1 }}
                            id="seq"
                            label="Sequence"
                            variant="standard"
                            value={actionType.seq}
                            onChange={(e) => {
                                setActionType({ ...actionType, seq: e.target.value });
                            }}
                            disabled={actionTypeFieldsDisabled}
                            type="number"
                        />
                    </Grid>
                </Grid>

        </>
    );
}

export default ActionTypeCRUD;
