import useAuth from "../hooks/useAuth";
import useEnvironment from "../hooks/useSession";
import useSession from "../hooks/useSession";

import axios from "axios";

import { getToken } from "../tokens";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel, TextField, Card } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";

import moment from "moment";
import hostsConfig from "../hostconfig.json";

import { useState, useEffect, useMemo } from "react";

const AddProduct = () => {
    const { auth, setAuth } = useAuth();
    const { session, setSession } = useSession();

    const [schools, setSchools] = useState([]);
    const [schoolDropdown, setSchoolDropdown] = useState("");
    const [selectedSchool, setSelectedSchool] = useState("");

    const [locations, setLocations] = useState([]);
    const [locationDropdown, setLocationDropdown] = useState("");
    const [selectedLocation, setSelectedLocation] = useState("");

    const [numberOfProducts, setNumberOfProducts] = useState(1);
    const [relocationNotes, setRelocationNotes] = useState("");

    const [optionLocation, setOptionLocation] = useState("");
    const [inputLocation, setInputLocation] = useState("");
    const [locationNotFound, setLocationNotFound] = useState(false);

    const [inputManufacturer, setInputManufacturer] = useState("");
    const [optionManufacturer, setOptionManufacturer] = useState(null);
    const [manufacturerNotFound, setManufacturerNotFound] = useState(false);

    const [optionManufacturerID, setOptionManufacturerID] = useState("");

    const [statuses, setStatuses] = useState([]);
    const [statusDropdown, setStatusDropdown] = useState("");
    const [statusNotes, setStatusNotes] = useState("");

    const [actionTypes, setActionTypes] = useState([]);
    const [actionTypeDropdown, setActionTypeDropdown] = useState("");
    const [selectedActionType, setSelectedActionType] = useState("");

    const [categories, setCategories] = useState([]);
    const [categoryDropdown, setCategoryDropdown] = useState("");
    //const [selectedCategory, setSelectedCategory] = useState("");

    const [subcategories, setSubcategories] = useState([]);
    const [subcategoryDropdown, setSubcategoryDropdown] = useState("");
    //const [selectedSubcategory, setSelectedSubcategory] = useState("");

    const [manufacturers, setManufacturers] = useState([]);
    const [manufacturerDropdown, setManufacturerDropdown] = useState("");
    //const [selectedManufacturer, setSelectedManufacturer] = useState("");

    const [insurance, setInsurance] = useState([]);
    const [insuranceDropdown, setInsuranceDropdown] = useState("");

    const [modelNumber, setModelNumber] = useState("");
    const [productName, setProductName] = useState("");
    const [serialNumber, setSerialNumber] = useState("");
    const [detailNotes, setDetailNotes] = useState("");

    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [openMessage, setOpenMessage] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogAction, setDialogAction] = useState("");

    const [email, setEmail] = useState("");

    const [tabValue, setTabValue] = useState(0);

    const [steps, setSteps] = useState();
    const [activeStep, setActiveStep] = useState(1);


    useEffect(() => {
        async function schoolsData() {
            try {
                const response = await axios.get(hostsConfig.BACKEND_HOST + "/organisationstree");
                console.log(response);

                let schools = response.data;
                for (let i = 0; i < schools.length; i++) {
                    if (schools[i].locations.length > 0) {
                        for (let j = 0; j < schools[i].locations.length; j++) {
                            if (schools[i].locations[j].id === null) {
                                //remove this location
                                schools[i].locations.splice(j, 1);
                            }
                        }
                    }
                }

                //rename location.description to location.title
                for (let i = 0; i < schools.length; i++) {
                    if (schools[i].locations.length > 0) {
                        for (let j = 0; j < schools[i].locations.length; j++) {
                            schools[i].locations[j].title = schools[i].locations[j].description;
                        }
                    }
                }

                //sort locations by field 'desc'
                for (let i = 0; i < schools.length; i++) {
                    if (schools[i].locations.length > 0) {
                        schools[i].locations.sort((a, b) => (a.title > b.title ? 1 : -1));
                    }
                }

                setSchools(schools);
            } catch (error) {
                console.error(error);
            }
        }
        schoolsData();

        setStatuses(session.environment?.statustype);

        if (statusDropdown === "") {
            setStatusDropdown(session.environment?.statustype[0].statusTypeID);
        }

        setCategories(session.environment?.categories);
        setSubcategories(session.environment?.subcategories);
        setManufacturers(session.environment?.manufacturers);
        setInsurance(session.environment?.insurance);
        setInsuranceDropdown(session.environment?.insurance[0].insuranceID);

        let steps = [
            {
                id: 1,
                label: "School",
                completed: false,
                previouseButtonDisabled: true,
                nextButtonDisabled: true,
                completedButtonVisible: false,
                skipped: false,
                skippedButtonDisabled: false,
                skippedButtonLabel: "Skip",
                nextButtonLabel: "Volgende",
                previousButtonLabel: "Vorige",
                hasNext: true,
            },
            {
                id: 2,
                label: "Details",
                completed: false,
                previouseButtonDisabled: false,
                nextButtonDisabled: false,
                completedButtonVisible: false,
                skipped: false,
                skippedButtonDisabled: false,
                skippedButtonLabel: "Skip",
                nextButtonLabel: "Volgende",
                previousButtonLabel: "Vorige",
                hasNext: true,
            },
            {
                id: 3,
                label: "Status",
                completed: false,
                previouseButtonDisabled: false,
                nextButtonDisabled: false,
                completedButtonVisible: false,
                skipped: false,
                skippedButtonDisabled: false,
                skippedButtonLabel: "Skip",
                nextButtonLabel: "Product toevoegen",
                previousButtonLabel: "Vorige",
                hasNext: false,
            },
        ];

        for (let s = 0; s < steps.length; s++) {
            console.log(steps[s]);
        }

        setSteps(steps);

        if (inputLocation !== "" && optionLocation !== null) {
            steps[activeStep - 1].nextButtonDisabled = false;
        } else {
            steps[activeStep - 1].nextButtonDisabled = true;
        }
    }, []);

    // useEffect for ActiveStep
    // useEffect(() => {

    //     if (steps !== undefined || steps !== null) {
    //         if (activeStep === 1) {
    //             if (inputLocation !== "" && optionLocation !== null) {
    //                 steps[activeStep - 1].nextButtonDisabled = true;
    //             } else {
    //                 steps[activeStep - 1].nextButtonDisabled = false;
    //             }
    //         }
    //     }
    // }, [activeStep]);

    // const handleSelectedLocationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    //     // setSelectedLocation(event.target.outerText);
    //     // let defVal = event.target.defaultValue

    //     if (event.target.outerText === undefined || event.target.outerText === "") {
    //         setSelectedLocation(event.target.defaultValue);
    //         if (event.target.defaultValue !== undefined && event.target.defaultValue !== "") {
    //             setLocationNotFound(true);
    //         }
    //     } else {
    //         setSelectedLocation(event.target.outerText);
    //         setLocationNotFound(false);
    //     }

    //     console.log(event.target.outerText);
    // };

    const handleOptionLocationChange = (event: any, newValue: string | null) => {
        //     (event, newInputValue) => {
        //     setInputLocation(newInputValue);
        // }
        setOptionLocation(newValue);
        console.log(newValue);
        if (newValue !== inputLocation) {
            setLocationNotFound(true);
        } else {
            setLocationNotFound(false);
        }

        // if (newValue !== null) {
        //     steps[activeStep - 1].nextButtonDisabled = false;
        // } else {
        //     steps[activeStep - 1].nextButtonDisabled = true;
        // }

        //if newValue belongs to the locations of the selected school then set selectedLocation to id of that location
        let school = schools.filter((s) => s.id === selectedSchool)[0];
        if (school.locations.filter((l) => l.title === newValue).length > 0) {
            setSelectedLocation(school.locations.filter((l) => l.description === newValue)[0].id);
        } else {
            setSelectedLocation("");
        }
    };

    const handleInputLocationChange = (event, newInputValue) => {
        //     (event, newInputValue) => {
        //     setInputLocation(newInputValue);
        // }
        setInputLocation(newInputValue);
        console.log(newInputValue);
        if (newInputValue !== optionLocation) {
            setLocationNotFound(true);
        } else {
            setLocationNotFound(false);
        }

        // if (newInputValue !== null) {
        //     steps[activeStep - 1].nextButtonDisabled = false;
        // } else {
        //     steps[activeStep - 1].nextButtonDisabled = true;
        // }

        let school = schools.filter((s) => s.id === selectedSchool)[0];
        if (school.locations.filter((l) => l.title === newInputValue).length > 0) {
            setSelectedLocation(school.locations.filter((l) => l.description === newInputValue)[0].id);
        } else {
            setSelectedLocation("");
        }
    };

    const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setStatusDropdown(event.target.value);
    };

    const handleHistoryNotesChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setStatusNotes(event.target.value);
    };

    const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCategoryDropdown(event.target.value);
    };

    const handleSubcategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSubcategoryDropdown(event.target.value);
    };

    const handleInsuranceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setInsuranceDropdown(event.target.value);
    };

    const handleManufacturerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setManufacturerDropdown(event.target.value);
    };

    const handleModelNumberChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setModelNumber(event.target.value);
    };

    const handleProductNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setProductName(event.target.value);
    };

    const handleSerialNumberChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSerialNumber(event.target.value);
    };

    const handleDetailNotesChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDetailNotes(event.target.value);
    };

    const handleNext = () => {
        if (activeStep === 3) {

            if (isNaN(numberOfProducts) || numberOfProducts === "" ) {
                setMessage("Number of products is not a number.");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            setOpenDialog(true);
            //setMessage("Are you sure you want to create this product?");
            setDialogTitle("Create product");
            //Are you sure you want to create 'numberProducts' products?"
            if (numberOfProducts === "1") {
                setDialogMessage("Are you sure you want to create this product?");
            } else {
                setDialogMessage("Are you sure you want to create " + numberOfProducts + " products?");
            }
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDialogAction("");
        setDialogTitle("");
        setDialogMessage("");
    };

    const handleConfirmDialog = () => {
        setOpenDialog(false);

        handleCreateProducts();
    };

    const handleCreateProducts = async () => {
        try {
            let count_success = 0;
            let count_error = 0;

            let accountEmail = auth?.userInfo?.email || "";

            //check if numberOfProducts is a number
            if (isNaN(numberOfProducts) || numberOfProducts === "" ) {
                setMessage("Number of products is not a number.");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            if(selectedSchool === "" || (inputLocation === '' && optionLocation === '')){
                setMessage("School and class/person are required.");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            console.log(optionManufacturer);
            console.log(optionManufacturerID);
            console.log(inputManufacturer);

            let body = {
                categoryID: categoryDropdown,
                subcategoryID: subcategoryDropdown,
                manufacturerID: optionManufacturerID,
                inputManufacturer: inputManufacturer,
                modelNumber: modelNumber,
                productName: productName,
                serialNumber: serialNumber,
                detailNotes: detailNotes,
                statusID: statusDropdown,
                statusHistoryNotes: statusNotes,
                organisationID: selectedSchool,
                locationID: selectedLocation,
                inputLocation: inputLocation,
                relocationNotes: relocationNotes,
                account: accountEmail,
                insuranceID: insuranceDropdown,
            };

            for (let i = 0; i < numberOfProducts; i++) {
                try {
                    const response = await axios.post(hostsConfig.BACKEND_HOST + "/insertProduct_no_purchase", body);

                    //if optionManufacturerID is empty then create new manufacturer and update session.environment.manufacturers
                    if (optionManufacturerID === "") {
                        let newManufacturer = {
                            manufacturerID: response.data[0].manufacturerID,
                            manufacturerName: inputManufacturer,
                            seq: 0,
                        };

                        let newManufacturers = [...session.environment.manufacturers, newManufacturer];
                        setManufacturers(newManufacturers);
                        setSession({ ...session, environment: { ...session.environment, manufacturers: newManufacturers } });
                    }

                    setActiveStep(1);

                    setRelocationNotes("");
                    setNumberOfProducts(1);
                    setCategoryDropdown("");
                    setSubcategoryDropdown("");
                    setOptionManufacturer("");
                    setInputManufacturer("");
                    setModelNumber("");
                    setProductName("");
                    setSerialNumber("");
                    setDetailNotes("");
                    setStatusDropdown("");
                    setStatusNotes("");

                    count_success++;
                    console.log(response);
                } catch (error) {
                    console.error(error);
                    count_error++;
                }
                console.log(i);
            }

            if (parseInt(numberOfProducts) === count_success) {
                setMessage(numberOfProducts + " created succesfuly.");
                setSeverity("success");
                setOpenMessage(true);
            } else {
                if (count_success > 0) {
                    setMessage("Only " + numberOfProducts + " created succesfuly.");
                    setSeverity("warning");
                    setOpenMessage(true);
                } else {
                    setMessage("No product created.");
                    setSeverity("error");
                    setOpenMessage(true);
                }
            }

            //refreshProducts();

            console.log("Creating products" + numberOfProducts);
        } catch (error) {
            console.log(error);
        }
    };

    const handleChangeNumberOfProducts = (event: React.ChangeEvent<{ value: unknown }>) => {


//check if event.target.value is a number
//if not then setNumberOfProducts to ''
//if yes then axept min 1 and max 100
        if (isNaN(event.target.value) || event.target.value === "") {
            setNumberOfProducts("");
        }
        else {
            if (event.target.value < 1) {
                setNumberOfProducts('1');
            }
            else if (event.target.value > 100) {
                setNumberOfProducts('100');
            }
            else {
                setNumberOfProducts(event.target.value);
            }
        }
        // }
    };

    const handleRelocationNotesChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setRelocationNotes(event.target.value);
    };


    const handleOptionManufacturerChange = (event: any, newValue: string | null) => {

        // setOptionLocation(newValue);
        // setNewInputLocation(newValue);

        setOptionManufacturer(newValue);
        // setNewInputManufacturer(newValue);

        // console.log(newValue);
        // if (newValue !== inputManufacturer) {
        //     // setLocationNotFound(true);
        //     setManufacturerNotFound(true);
        // } else {
        //     // setLocationNotFound(false);
        //     setManufacturerNotFound(false);
        // }

        var newID = "";
        //if newValue belongs to the manufacturers then set selectedManufacturer to id of that manufacturer
        if (manufacturers.filter((m) => m.manufacturerName === newValue).length > 0) {
            newID = manufacturers.filter((m) => m.manufacturerName === newValue)[0].manufacturerID;
        }

        setOptionManufacturerID(newID);
    };

    const handleInputManufacturerChange = (event, newInputValue) => {

        // setInputLocation(newInputValue);
        // setNewInputLocation(newInputValue);

        setInputManufacturer(newInputValue);
        // setNewInputManufacturer(newInputValue);

        // console.log(newInputValue);
        // if (newInputValue !== optionManufacturer) {
        //     // setLocationNotFound(true);
        //     setManufacturerNotFound(true);
        // } else {
        //     // setLocationNotFound(false);
        //     setManufacturerNotFound(false);
        // }

        var newID = "";
        //if newValue belongs to the manufacturers then set selectedManufacturer to id of that manufacturer
        if (manufacturers.filter((m) => m.manufacturerName === newInputValue).length > 0) {
            newID = manufacturers.filter((m) => m.manufacturerName === newInputValue)[0].manufacturerID;
        }

        setOptionManufacturerID(newID);
    };


    const doNothing = () => { };

    return (
        <Box className="mainBox" sx={{ m: 2, width: "100%" }}>
            <Grid container spacing={0}>
                {/* <Grid item md={12} lg={12}>
                    <Typography align="center" variant="h5">
                        Add products
                    </Typography>
                </Grid> */}

                {steps && (
                    <Grid item md={12} lg={12}>
                        <Box sx={{ ml: 0, width: "95%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            {Array.isArray(steps) &&
                                steps.map((step) => (
                                    <Box key={`div${step.id}`} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        {step.id === activeStep ? (
                                            <>
                                                <Box sx={{ maxWidth: 200 }} p={1} key={step.id}>
                                                    <Button variant="text" onClick={() => { }} sx={{ color: "black" }}>{step.label}</Button>
                                                </Box>
                                            </>
                                        ) : (
                                            <Box sx={{ maxWidth: 200 }} p={1} key={step.id}>
                                                <Button variant="text" onClick={() => { setActiveStep(step.id); }}>{step.label}</Button>
                                            </Box>
                                        )}
                                        {step.hasNext && (
                                            <Box
                                                key={`divider${step.id}`}
                                                sx={{ width: 20, display: "flex", alignItems: "center", justifyContent: "center" }}
                                            >
                                                <Typography key={`divider${step.id}`}>&gt;</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                ))}

                            <Box sx={{ flex: "1 1 auto" }} />
                            {steps && (
                                <>
                                    <Box pb={1}>
                                        <Button
                                            variant="contained"
                                            sx={{ minWidth: 150 }}
                                            disabled={steps[activeStep - 1]?.previouseButtonDisabled}
                                            onClick={() => {
                                                setActiveStep(activeStep - 1);
                                            }}
                                        >
                                            {steps[activeStep - 1]?.previousButtonLabel}
                                        </Button>

                                        <Button
                                            variant="contained"
                                            sx={{ minWidth: 150 }}
                                            disabled={steps[activeStep - 1]?.nextButtonDisabled}
                                            onClick={handleNext}
                                        >
                                            {steps[activeStep - 1]?.nextButtonLabel}
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Grid>
                )}

                <Grid item md={12} lg={12}>
                    <Card variant="plain" sx={{ mr: 4, p: 1 }}>
                        {activeStep === 1 && (
                            <Box sx={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                
                                {/* for every FormControl > backgroundColor:"aliceblue" */}
                                <FormControl required sx={{ mt: 1, ml: 1, mb: 1, width: 400 }} size="small">
                                    <InputLabel id="dropdownschool--label">School</InputLabel>
                                    <Select

                                        labelId="school-dropdown-label"
                                        id="school-dropdown"
                                        value={schoolDropdown}
                                        label="School"
                                        onChange={(e) => {
                                            setSchoolDropdown(e.target.value);
                                            setSelectedSchool(e.target.value);
                                            steps[activeStep - 1].nextButtonDisabled = false;
                                        }}
                                    >
                                        {schools.map((school) => (
                                            <MenuItem key={school.id} value={school.id}>
                                                {school.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ mt: 1, ml: 1, mb: 1, width: 400 }} size="small">
                                    {selectedSchool !== "" ? (
                                        <Autocomplete
                                            value={optionLocation}
                                            onChange={handleOptionLocationChange}
                                            inputValue={inputLocation}
                                            onInputChange={handleInputLocationChange}
                                            id="combo-box-demo2"
                                            freeSolo
                                            options={
                                                selectedSchool !== undefined
                                                    ? schools.filter((s) => s.id === selectedSchool)[0]?.locations.map((option) => option.title)
                                                    : ""
                                            }
                                            renderInput={(params) => (
                                                <TextField required {...params} label="Select or enter class/person" variant="outlined" fullWidth />
                                            )}
                                            disabled={selectedSchool === ""}
                                        />
                                    ) : (
                                        <>
                                            <TextField
                                                required
                                                sx={{ width: 400 }}
                                                id="dummy"
                                                size="small"
                                                label="Select or enter class/person"
                                                value={""}
                                                onChange={doNothing}
                                                disabled={true}
                                            />
                                        </>
                                    )}
                                </FormControl>

                                <TextField
                                    sx={{ mt: 1, ml: 1, mb: 1, width: 400 }}
                                    id="notes"
                                    size="small"
                                    label="Notes about location (optional)"
                                    value={relocationNotes}
                                    onChange={handleRelocationNotesChange}
                                    multiline
                                />

                                <TextField
                                    sx={{ mt: 1, ml: 1, mb: 1, width: 400 }}
                                    id="outlined-basic"
                                    label="Number of products (min 1, max 100)"
                                    variant="outlined"
                                    value={numberOfProducts}
                                    // type="number"
                                    size="small"
                                    onChange={handleChangeNumberOfProducts}
                                />

                                {optionLocation !== inputLocation ? (
                                    <Typography sx={{ mt: 1, ml: 2, mb: 1 }} variant="caption" color="primary">
                                        New class or person ({inputLocation}) will be created.
                                    </Typography>
                                ) : null}
                            </Box>
                        )}

                        {activeStep === 2 && (
                            <Box sx={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                                    <InputLabel id="demo-select-small">Category</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={categoryDropdown}
                                        label="Category"
                                        onChange={handleCategoryChange}
                                    >
                                        {session.environment?.categories.map((cat, index) => (
                                            <MenuItem key={index} value={cat.categoryID}>
                                                {cat.categoryName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                                    <InputLabel id="demo-select-small">Subcategory</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={subcategoryDropdown}
                                        label="Subcategory"
                                        onChange={handleSubcategoryChange}
                                    >
                                        {categoryDropdown !== "" &&
                                            session.environment?.subcategories
                                                .filter((sub) => sub.categoryID === categoryDropdown)
                                                .map((sub, index) => (
                                                    <MenuItem key={index} value={sub.subcategoryID}>
                                                        {sub.subcategoryName}
                                                    </MenuItem>
                                                ))}
                                    </Select>
                                </FormControl>

                                {/* <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                                    <InputLabel id="demo-select-small">Manufacturer</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={manufacturerDropdown}
                                        label="Manufacturer"
                                        onChange={handleManufacturerChange}
                                    >
                                        {session.environment?.manufacturers.map((man, index) => (
                                            <MenuItem key={index} value={man.manufacturerID}>
                                                {man.manufacturerName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> */}

                                <FormControl sx={{ mt: 1, ml: 1, mb: 1, width: 300 }} size="small">

                                    <Autocomplete
                                        value={optionManufacturer}
                                        onChange={handleOptionManufacturerChange}
                                        inputValue={inputManufacturer}
                                        onInputChange={handleInputManufacturerChange}
                                        id="combo-box-demo2"
                                        freeSolo
                                        options={
                                            // newOrg !== undefined
                                            //     ? schools.filter((s) => s.id === newOrg)[0]?.locations.map((option) => option.description)
                                            //     : ""
                                            manufacturers.map((option) => option.manufacturerName)
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select or enter manufacturer" variant="outlined" fullWidth />
                                        )}
                                        // disabled={newOrg === ""}
                                        size="small"
                                    />

                                </FormControl>

                                <TextField
                                    sx={{ pl: 0, mt: 1, ml: 1, width: "300px" }}
                                    id="Model"
                                    size="small"
                                    label="Model"
                                    value={modelNumber}
                                    onChange={handleModelNumberChange}
                                />

                                <TextField
                                    sx={{ pl: 0, mt: 2, ml: 1, width: "300px" }}
                                    id="Serial"
                                    size="small"
                                    label="Serial"
                                    value={serialNumber}
                                    onChange={handleSerialNumberChange}
                                />

                                <TextField
                                    sx={{ pl: 0, mt: 2, ml: 1, width: "300px" }}
                                    id="productName"
                                    size="small"
                                    label="Product name"
                                    value={productName}
                                    onChange={handleProductNameChange}
                                    disabled={true}
                                />

                                <FormControl sx={{ pl: 0, mt: 2, ml: 1, minWidth: 250 }} size="small">
                                    <InputLabel id="demo-select-small">Verzekeringscategorie</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={insuranceDropdown}
                                        label="Verzekeringscategorie"
                                        onChange={handleInsuranceChange}
                                    >
                                        {session.environment?.insurance.map((ins, index) => (
                                            <MenuItem key={index} value={ins.insuranceID}>
                                                {ins.insuranceName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <TextField
                                    sx={{ pl: 0, mt: 2, ml: 1, pb: 1, width: "570px" }}
                                    id="Detail notes"
                                    size="small"
                                    label="Detail notes"
                                    value={detailNotes}
                                    multiline
                                    rows={2}
                                    onChange={handleDetailNotesChange}
                                />
                            </Box>
                        )}

                        {activeStep === 3 && (
                            <Box sx={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <FormControl sx={{ mt: 1, ml: 1, mb: 1, width: 400 }} size="small">
                                    <InputLabel id="demo-select-small">Status</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={statusDropdown}
                                        label="Status"
                                        onChange={handleStatusChange}
                                    >
                                        {statuses.map((sts, index) => (
                                            <MenuItem key={index} value={sts.statusTypeID}>
                                                {sts.statusLabel}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <TextField
                                    sx={{ pl: 0, mt: 2, ml: 1, pb: 1, width: "400px" }}
                                    id="notes"
                                    size="small"
                                    label="Notes about status (optional)"
                                    value={statusNotes}
                                    multiline
                                    rows={2}
                                    onChange={handleHistoryNotesChange}
                                />
                            </Box>
                        )}

                        {/* {steps && (
                            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                <Button
                                    variant="contained"
                                    disabled={steps[activeStep - 1]?.previouseButtonDisabled}
                                    onClick={() => {
                                        setActiveStep(activeStep - 1);
                                    }}
                                >
                                    {steps[activeStep - 1]?.previousButtonLabel}
                                </Button>

                                <Box sx={{ flex: "1 1 auto" }} />

                                
                                <Button variant="contained" disabled={steps[activeStep - 1]?.nextButtonDisabled} onClick={handleNext}>
                                    {steps[activeStep - 1]?.nextButtonLabel}
                                </Button>
                            </Box>
                        )} */}
                    </Card>
                </Grid>
            </Grid>

            <Box maxWidth={800}>
                <Snackbar
                    open={openMessage}
                    onClose={() => {
                        setOpenMessage(false);
                    }}
                    TransitionComponent={SlideTransition}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    key="Snackbar"
                //autoHideDuration={3000}
                >
                    <Alert
                        severity={severity}
                        sx={{ marginTop: 2 }}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </Box>

            <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Disagree</Button>
                    <Button onClick={handleConfirmDialog} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export default AddProduct;
