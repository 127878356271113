import useAuth from "../hooks/useAuth";
import useSession from "../hooks/useSession";
import { getToken } from "../tokens";

//import useEnvironment from "../hooks/useSession";
import axios from "axios";

import { useState, useEffect, useMemo } from "react";
import { json, useNavigate } from "react-router-dom";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel, TextField, Card } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";

import hostsConfig from "../hostconfig.json";

import LocationCRUD from "./LocationCRUD"

const OrganisationCRUD = ({ setOpenMessage, setMessage, setSeverity }) => {
    const { auth, setAuth } = useAuth();
    const { session, setSession } = useSession();

    // CREATE TABLE `organisation` (
    //     `organisationID` varchar(36) NOT NULL,
    //     `name` varchar(255) DEFAULT NULL,
    //     `nameShort` varchar(45) DEFAULT NULL,
    //     `codeInternal` varchar(45) DEFAULT NULL,
    //     `codeExternal` varchar(45) DEFAULT NULL,
    //     `photo` blob,
    //     `color` varchar(45) DEFAULT NULL,
    //     `domain` varchar(255) DEFAULT NULL,
    //     PRIMARY KEY (`organisationID`)
    //   ) ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci;

    const [items, setItems] = useState([]);

    const [item, setItem] = useState({
        organisationID: "",
        name: "",
        nameShort: "",
        codeInternal: "",
        codeExternal: "",
        photo: "",
        color: "",
        domain: "",
    });

    const [nameDisabled, setNameDisabled] = useState(true);
    const [fieldsDisabled, setFieldsDisabled] = useState(true);

    const [buttonEditDisabled, setButtonEditDisabled] = useState(false);
    const [buttonAddDisabled, setButtonAddDisabled] = useState(false);
    const [buttonDeleteDisabled, setButtonDeleteDisabled] = useState(false);

    const [buttonCancelDisabled, setButtonCancelDisabled] = useState(true);
    const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(true);

    const [dialogMode, setDialogMode] = useState("");

    useEffect(() => {
        setButtonAddDisabled(false);
        setButtonEditDisabled(false);
        setButtonCancelDisabled(true);
        setButtonSubmitDisabled(true);

        async function schoolsData() {
            try {
                const response = await axios.get(hostsConfig.BACKEND_HOST + "/organisationstree");
                console.log(response);

                let schools = normalizeSchools(response.data);

                setItems(schools);
            } catch (error) {
                console.error(error);
            }
        }
        schoolsData();
    }, []);

    useEffect(() => {
        if (dialogMode === "edit") {

            if (item.organisationID === "") {
                setMessage("Please select a school.");
                setSeverity("warning");
                setOpenMessage(true);
                resetButtons();
                setDialogMode("cancel");
                return;
            }

            setFieldsDisabled(false);

            setNameDisabled(false);
            setButtonEditDisabled(false);
            setButtonAddDisabled(true);
            setButtonDeleteDisabled(true);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "add") {
            emptyItem();
            setFieldsDisabled(false);

            setNameDisabled(false);
            setButtonEditDisabled(true);
            setButtonAddDisabled(false);
            setButtonDeleteDisabled(true);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "delete") {
            setFieldsDisabled(true);

            setNameDisabled(true);
            setButtonEditDisabled(true);
            setButtonAddDisabled(true);
            setButtonDeleteDisabled(false);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "cancel") {
            emptyItem();

            setFieldsDisabled(true);

            setNameDisabled(true);
            setButtonEditDisabled(false);
            setButtonAddDisabled(false);
            setButtonDeleteDisabled(false);

            setButtonCancelDisabled(true);
            setButtonSubmitDisabled(true);
        }
    }, [dialogMode]);

    const normalizeSchools = (data) => {
        let schools = data;
        for (let i = 0; i < schools.length; i++) {
            if (schools[i].locations.length > 0) {
                for (let j = 0; j < schools[i].locations.length; j++) {
                    if (schools[i].locations[j].id === null) {
                        //remove this location
                        schools[i].locations.splice(j, 1);
                    }
                }
            }
            schools[i].organisationID = schools[i].id;
        }

        //rename location.description to location.title
        for (let i = 0; i < schools.length; i++) {
            if (schools[i].locations.length > 0) {
                for (let j = 0; j < schools[i].locations.length; j++) {
                    schools[i].locations[j].title = schools[i].locations[j].description;
                }
            }
        }

        //sort locations by field 'desc'
        for (let i = 0; i < schools.length; i++) {
            if (schools[i].locations.length > 0) {
                schools[i].locations.sort((a, b) => (a.title > b.title ? 1 : -1));
            }
        }
        return schools;
    }

    const resetButtons = () => {
        setFieldsDisabled(true);
        emptyItem();

        setNameDisabled(true);
        setButtonEditDisabled(false);
        setButtonAddDisabled(false);
        setButtonDeleteDisabled(false);

        setButtonCancelDisabled(true);
        setButtonSubmitDisabled(true);
    };

    const emptyItem = () => {
        setItem({
            organisationID: "",
            name: "",
            nameShort: "",
            codeInternal: "",
            codeExternal: "",
            photo: "",
            color: "",
            domain: "",
        });
    };

    const handleItemSubmit = () => {
        console.log("handleItemSubmit");
        console.log(item);

        if (dialogMode === "edit") {
            editItem();
        }

        if (dialogMode === "add") {
            addItem();
        }

        if (dialogMode === "delete") {
            deleteItem();
        }
    };

    const editItem = async () => {
        console.log("editItem");
        console.log(item);

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                organisationID: item.organisationID,
                name: item.name,
                nameShort: item.nameShort,
                codeInternal: item.codeInternal,
                codeExternal: item.codeExternal,
                color: item.color,
                domain: item.domain,
                accountEmail: accountEmail,
            };

            const response = await axios.put(hostsConfig.BACKEND_HOST + "/update_organisation", body);

            //update session
            // let newItems = items;
            // for (let i = 0; i < newItems.length; i++) {
            //     if (newItems[i].organisationID === item.organisationID) {
            //         newItems[i] = item;
            //     }
            // }

            if (response.data !== undefined || response.data.length !== 0) {

                let newItems = normalizeSchools(response.data);
                setItems(newItems);

                setMessage("Updated succesfuly.");
                setSeverity("success");
                setOpenMessage(true);
            } else {
                setMessage("Error updating.");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage("Error updating.");
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    const addItem = async () => {
        console.log("addItem");
        console.log(item);

        if (item.name === "" || item.nameShort === "") {
            setMessage("Please fill at least the required fields.");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                name: item.name,
                nameShort: item.nameShort,
                codeInternal: item.codeInternal,
                codeExternal: item.codeExternal,
                color: item.color,
                domain: item.domain,
                accountEmail: accountEmail,
            };

            const response = await axios.post(hostsConfig.BACKEND_HOST + "/insertOrganisation", body);


            if (response.data !== undefined || response.data.length !== 0) {

                let newItems = normalizeSchools(response.data);
                setItems(newItems);
                setMessage("Added succesfuly.");
                setSeverity("success");
                setOpenMessage(true);
            } else {
                setMessage("Error adding category. Check if it already exists");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage("Error adding.");
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    const deleteItem = async () => {
        console.log("deleteItem");
        console.log(item);

        let at = item;

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                organisationID: at.organisationID,
                accountEmail: accountEmail,
            };

            const response = await axios.post(hostsConfig.BACKEND_HOST + "/delete_organisation", body);

            if (response.data === undefined || response.data.length === 0) {
                setMessage("Error deleting organisation. Check if there are products associated with it.");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            let newItems = normalizeSchools(response.data);
            setItems(newItems);
            setMessage("Added succesfuly.");
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage("Error deleting.");
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={12} lg={6}>
                    <Grid container spacing={1}>
                        <Grid item md={12} lg={12}>
                            <FormControl sx={{ width: 1, pb: 2 }} size="small">
                                <InputLabel id="dropdown-category-label">select school</InputLabel>
                                <Select
                                    labelId="dropdown-category-label"
                                    id="dropdown-category"
                                    value={item.organisationID}
                                    label="select school"
                                    onChange={(e) => {
                                        setItem(
                                            items.filter((item) => {
                                                return item.organisationID === e.target.value;
                                            })[0]
                                        );
                                    }}
                                >
                                    {items.map((item) => {
                                        return (
                                            <MenuItem key={item.organisationID} value={item.organisationID}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("edit");
                                }}
                                disabled={buttonEditDisabled}
                            >
                                Edit
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("add");
                                }}
                                disabled={buttonAddDisabled}
                            >
                                Add
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("delete");
                                }}
                                disabled={buttonDeleteDisabled}
                            >
                                Delete
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("cancel");
                                }}
                                disabled={buttonCancelDisabled}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    handleItemSubmit();
                                }}
                                disabled={buttonSubmitDisabled  || session.user.role !== "admin"}
                            >
                                Submit {session.user.role !== "admin" && "(admin only)"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12} lg={6}>
                    <Card elevation={5} variant="elevation" sx={{ p: 3, backgroundColor: "#bbdefb", w: 1 }}>
                        <TextField
                            sx={{ mt: 1, mb: 1, width: 1 }}
                            id="name"
                            label="Name"
                            variant="standard"
                            size="small"
                            value={item.name}
                            onChange={(e) => {
                                setItem({ ...item, name: e.target.value });
                            }}
                            disabled={nameDisabled}
                        />

                        <TextField
                            sx={{ mt: 1, mb: 1, width: .3 }}
                            id="nameShort"
                            label="Short name"
                            variant="standard"
                            value={item.nameShort}
                            onChange={(e) => {
                                setItem({ ...item, nameShort: e.target.value });
                            }}
                            disabled={fieldsDisabled}
                        />

                        <TextField
                            sx={{ mt: 1, mb: 1, ml: 1, width: .3 }}
                            id="codeInternal"
                            label="Code Internal"
                            variant="standard"
                            value={item.codeInternal}
                            onChange={(e) => {
                                setItem({ ...item, codeInternal: e.target.value });
                            }}
                            disabled={fieldsDisabled}
                        />

                        <TextField
                            sx={{ mt: 1, mb: 1, ml: 1, width: .3 }}
                            id="codeExternal"
                            label="Code External"
                            variant="standard"
                            value={item.codeExternal}
                            onChange={(e) => {
                                setItem({ ...item, codeExternal: e.target.value });
                            }}
                            disabled={fieldsDisabled}
                        />

                        <TextField
                            sx={{ mt: 1, mb: 1, width: .3 }}
                            id="color"
                            label="Color"
                            variant="standard"
                            value={item.color}
                            onChange={(e) => {
                                setItem({ ...item, color: e.target.value });
                            }}
                            disabled={fieldsDisabled}
                            type="color"
                        />

                        <TextField
                            sx={{ mt: 1, mb: 1, ml: 2, width: .6 }}
                            id="domain"
                            label="Domain"
                            variant="standard"
                            value={item.domain}
                            onChange={(e) => {
                                setItem({ ...item, domain: e.target.value });
                            }}
                            disabled={fieldsDisabled}
                        />
                    </Card>
                </Grid>
            </Grid>

            <Card variant="plain" sx={{ mr: 4, pl: 9, pr: 5, pt: 1 }}>
                <LocationCRUD setOpenMessage={setOpenMessage} setMessage={setMessage} setSeverity={setSeverity} organisation={item} setOrganisations={setItems} />
            </Card>
        </>
    );
};

export default OrganisationCRUD;
