import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import hostsConfig from "../hostconfig.json";

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
    const [translation, setTranslation] = useState({});

    const fetchTranslation = async () => {
        try {
            
            const response = await axios.get(hostsConfig.BACKEND_HOST + '/translation');
            const translationData = response.data.translations.reduce((acc, item) => {
                acc[item.key] = item.dutch;
                return acc;
            }, {});
            setTranslation(translationData);
        } catch (error) {
            console.error('Error fetching translation:', error);
        }
    };

    useEffect(() => {
        fetchTranslation();
    }, []);

    return (
        <TranslationContext.Provider value={{ translation, fetchTranslation }}>
            {children}
        </TranslationContext.Provider>
    );
};

export const useTranslation = () => {
    return React.useContext(TranslationContext);
};

export default TranslationContext;