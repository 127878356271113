import useAuth from "../hooks/useAuth";
import useSession from "../hooks/useSession";
import { getToken } from "../tokens";

//import useEnvironment from "../hooks/useSession";
import axios from "axios";

import { useState, useEffect, useMemo } from "react";
import { json, useNavigate } from "react-router-dom";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel, TextField, Card } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";

import hostsConfig from "../hostconfig.json";

const StatusTypeCRUD = ({

    setOpenMessage,
    setMessage,
    setSeverity,

}) => {

    const { auth, setAuth } = useAuth();
    const { session, setSession } = useSession();

    // CREATE TABLE `statustype` (
    //     `statusTypeID` varchar(36) NOT NULL,
    //     `statusName` varchar(100) NOT NULL,
    //     `statusColor` varchar(100) DEFAULT NULL,
    //     `seq` int DEFAULT NULL,
    //     `statusLabel` varchar(100) DEFAULT NULL,
    //     PRIMARY KEY (`statusTypeID`)
    //   ) ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci;


    const [statusTypes, setStatusTypes] = useState(session.environment.statustype);

    const [statusType, setStatusType] = useState({
        statusTypeID: "",
        statusColor: "",
        statusLabel: "",
        statusName: "",
        seq: 0,
    });

    const [nameDisabled, setNameDisabled] = useState(true);
    const [fieldsDisabled, setFieldsDisabled] = useState(true);

    const [buttonEditDisabled, setButtonEditDisabled] = useState(false);
    const [buttonAddDisabled, setButtonAddDisabled] = useState(false);
    const [buttonDeleteDisabled, setButtonDeleteDisabled] = useState(false);

    const [buttonCancelDisabled, setButtonCancelDisabled] = useState(true);
    const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(true);

    const [dialogMode, setDialogMode] = useState("");



    useEffect(() => {


        setButtonAddDisabled(false);
        setButtonEditDisabled(false);
        setButtonCancelDisabled(true);
        setButtonSubmitDisabled(true);
    }, []);

    useEffect(() => {
        if (dialogMode === "edit") {
            setFieldsDisabled(false);

            setNameDisabled(true);
            setButtonEditDisabled(false);
            setButtonAddDisabled(true);
            setButtonDeleteDisabled(true);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "add") {
            emptyStatusType();
            setFieldsDisabled(false);

            setNameDisabled(false);
            setButtonEditDisabled(true);
            setButtonAddDisabled(false);
            setButtonDeleteDisabled(true);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "delete") {
            setFieldsDisabled(true);

            setNameDisabled(true);
            setButtonEditDisabled(true);
            setButtonAddDisabled(true);
            setButtonDeleteDisabled(false);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "cancel") {
            setStatusType(session.environment.statustype[0]);

            setFieldsDisabled(true);

            setNameDisabled(true);
            setButtonEditDisabled(false);
            setButtonAddDisabled(false);
            setButtonDeleteDisabled(false);

            setButtonCancelDisabled(true);
            setButtonSubmitDisabled(true);
        }
    }, [dialogMode]);








    const resetButtons = () => {
        setFieldsDisabled(true);
        setStatusType(session.environment.statustype[0]);

        setNameDisabled(true);
        setButtonEditDisabled(false);
        setButtonAddDisabled(false);
        setButtonDeleteDisabled(false);

        setButtonCancelDisabled(true);
        setButtonSubmitDisabled(true);
    };

    const emptyStatusType = () => {
        setStatusType({
            statusTypeID: "",
            statusColor: "",
            statusLabel: "",
            statusName: "",
            seq: "",
        });
    };

    const handleStatusTypeSubmit = () => {
        console.log("handleStatusTypeSubmit");
        console.log(statusType);

        if (dialogMode === "edit") {
            editStatusType();
        }

        if (dialogMode === "add") {
            addStatusType();
        }

        if (dialogMode === "delete") {
            deleteStatusType();
        }
    };

    const editStatusType = async () => {
        console.log("editStatusType");
        console.log(statusType);

        try {

            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                statusTypeID: statusType.statusTypeID,

                statusColor: statusType.statusColor,
                statusLabel: statusType.statusLabel,
                statusName: statusType.statusName,
                seq: statusType.seq,
                accountEmail: accountEmail,
            };

            const response = await axios.put(hostsConfig.BACKEND_HOST + "/update_statusType", body);

            //update session
            let newSession = session;
            for (let i = 0; i < statusTypes.length; i++) {
                if (statusTypes[i].statusTypeID === statusType.statusTypeID) {
                    newSession.environment.statustype[i] = statusType;
                }
            }
            setSession(newSession);

            setMessage("Updated succesfuly.");
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage("Error updating.");
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    const addStatusType = async () => {
        console.log("addStatusType");
        console.log(statusType);

        if (statusType.statusName === "" || statusType.statusLabel === "" || statusType.statusColor === "" || statusType.seq === "") {
            setMessage("Please fill all fields.");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        let at = statusType;

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                statusTypeID: "",

                statusColor: at.statusColor,
                statusLabel: at.statusLabel,
                statusName: at.statusName,
                seq: at.seq,
                accountEmail: accountEmail,
            };

            const response = await axios.post(hostsConfig.BACKEND_HOST + "/insert_statusType", body);

            let statusType = response.data[0];

            if (statusType === undefined) {
                setMessage("Error adding status type. Check if it already exists");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            //update session
            let newSession = session;
            newSession.environment.statustype.push(statusType);
            setSession(newSession);

            setMessage("Added succesfuly.");
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage("Error adding.");
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    const deleteStatusType = async () => {
        console.log("deleteStatusType");
        console.log(statusType);

        let at = statusType;

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                statusTypeID: at.statusTypeID,
                accountEmail: accountEmail,
            };

            const response = await axios.post(hostsConfig.BACKEND_HOST + "/delete_statusType", body);

            let newStatusTypes = response.data;

            if (newStatusTypes === undefined || newStatusTypes.length === 0) {
                setMessage("Error deleting status type. Check if there are history entries associated with it.");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            let newSession = session;

            newSession.environment.statustype = newStatusTypes;

            setSession(newSession);
            setStatusTypes(newStatusTypes);
            setStatusType(newStatusTypes[0]);

            setMessage("Deleted succesfuly.");
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage("Error deleting.");
            setSeverity("error");
            setOpenMessage(true);
        }
    };






    return (
        <>

            <Grid container spacing={2}>

                <Grid item md={6} lg={6}>
                    <Grid container spacing={1}>
                        <Grid item md={12} lg={12}>
                            <FormControl sx={{ width: 1, pb: 2 }} size="small">
                                <InputLabel id="dropdown-statusType-label">Select status</InputLabel>
                                <Select
                                    labelId="dropdown-statusType-label"
                                    id="dropdown-statusType"
                                    value={statusType.statusTypeID}
                                    label="Select status"
                                    onChange={(e) => {
                                        setStatusType(
                                            session.environment.statustype.find((statusType) => statusType.statusTypeID === e.target.value)
                                        );
                                    }}
                                >
                                    {session.environment.statustype.map((statusType) => (
                                        <MenuItem key={statusType.statusTypeID} value={statusType.statusTypeID}>
                                            {statusType.statusName} / {statusType.statusLabel} / {statusType.seq}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("edit");
                                }}
                                disabled={buttonEditDisabled}
                            >
                                Edit
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("add");
                                }}
                                disabled={buttonAddDisabled}
                            >
                                Add
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("delete");
                                }}
                                disabled={buttonDeleteDisabled}
                            >
                                Delete
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("cancel");
                                }}
                                disabled={buttonCancelDisabled}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    handleStatusTypeSubmit();
                                }}
                                disabled={buttonSubmitDisabled || session.user.role !== "admin"}
                            >
                                Submit {session.user.role !== "admin" && "(admin only)"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={5} lg={6}>
                    <Card elevation={5} variant="elevation" sx={{ p: 3, backgroundColor: "#bbdefb", w: 1 }}>
                        <TextField
                            sx={{ mt: 0, ml: 1, mb: 1, width: 1 }}
                            id="statusName"
                            label="Status Name"
                            variant="standard"
                            size="small"
                            value={statusType.statusName}
                            onChange={(e) => {
                                setStatusType({ ...statusType, statusName: e.target.value });
                            }}
                            disabled={nameDisabled}
                        />
                        <TextField
                            sx={{ mt: 1, ml: 1, mb: 1, width: 1 }}
                            id="statusLabel"
                            label="Status Label"
                            variant="standard"
                            value={statusType.statusLabel}
                            onChange={(e) => {
                                setStatusType({ ...statusType, statusLabel: e.target.value });
                            }}
                            disabled={fieldsDisabled}
                        />
                        <TextField
                            sx={{ mt: 1, ml: 1, mb: 1, width: .4 }}
                            id="statusColor"
                            label="Status Color"
                            variant="standard"
                            value={statusType.statusColor}
                            onChange={(e) => {
                                setStatusType({ ...statusType, statusColor: e.target.value });
                            }}
                            disabled={fieldsDisabled}
                            type="color"
                        />

                        <TextField
                            sx={{ mt: 1, ml: 1, mb: 1, width: 100 }}
                            id="seq"
                            label="Sequence"
                            variant="standard"
                            value={statusType.seq}
                            onChange={(e) => {
                                setStatusType({ ...statusType, seq: e.target.value });
                            }}
                            disabled={fieldsDisabled}
                            type="number"
                        />
                    </Card>
                </Grid>
            </Grid>

        </>
    );
}

export default StatusTypeCRUD;
