import React, { useState, useEffect } from "react";
import { setTokens } from "../tokens";

import useAuth from "../hooks/useAuth";

import axios from "axios";

import { Button, Typography, Box, Link, Grid } from "@mui/material";
import useSession from "../hooks/useSession";

import hostsConfig from "../hostconfig.json";

const Login = () => {
    const { auth, setAuth } = useAuth();
    const { session, setSession } = useSession();

    useEffect(() => {
        handleTokenFromQueryParams();
    }, []);

    const handleAuth = () => {
        // oauth2Client.getToken(CODE, (err, token) => {
        //     if (err) {
        //       console.error('Error retrieving access token', err);
        //       return;
        //     }
        //     oauth2Client.setCredentials(token);
        //   });
    };

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const createGoogleAuthLink = async () => {
        try {
            const request = await fetch(
                hostsConfig.BACKEND_HOST + "/createAuthLink",
                {
                    method: "POST",
                }
            );
            const response = await request.json();
            window.location.href = response.url;
        } catch (error) {
            console.log("App.js 12 | error", error);
            throw new Error("Issue with Login", error.message);
        }
    };

    const handleTokenFromQueryParams = () => {
        const query = new URLSearchParams(window.location.search);
        const accessToken = query.get("accessToken");
        const refreshToken = query.get("refreshToken");
        const expirationDate = newExpirationDate();
        console.log("App.js 30 | expiration Date", expirationDate);
        if (accessToken && refreshToken) {
            storeTokenData(accessToken, refreshToken, expirationDate);
            setIsLoggedIn(true);

            console.log("redirect | " + hostsConfig.FRONTEND_HOST + "/");
            window.location.href = hostsConfig.FRONTEND_HOST + "/";
        }
    };

    // const newExpirationDate = () => {
    //     var expiration = new Date();
    //     expiration.setHours(expiration.getHours() + 1);
    //     return expiration;
    // };

    const newExpirationDate = () => {
        var expiration = new Date();
        expiration.setMonth(expiration.getMonth() + 1); // Add 1 month
        return expiration;
    };

    const storeTokenData = async (token, refreshToken, expirationDate) => {
        sessionStorage.setItem("accessToken", token);
        sessionStorage.setItem("refreshToken", refreshToken);
        sessionStorage.setItem("expirationDate", expirationDate);

        fetch(
            hostsConfig.BACKEND_HOST +
                "/storeRefreshToken?refreshToken=" +
                refreshToken,
            {
                method: "GET",
                credentials: "include", // Ensures the cookie is stored in the browser
            }
        )
            .then((response) => response.json())
            .then((data) => console.log(data))
            .catch((error) => console.error("Error:", error));

        console.log("App.js 30 | token stored");
    };

    const signOut = () => {
        setIsLoggedIn(false);
        sessionStorage.clear();
    };

    return (
        //centered box with text , image and login button
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
            }}
        >
            <Typography
                color={"primary"}
                variant="h4"
                component="h1"
                gutterBottom
            >
                Welkom
            </Typography>
            <Typography color={"primary"} variant="subtitle1" component="h2">
                ICT voorraadbeheersysteem
            </Typography>
            <Typography
                pb={1}
                color={"primary"}
                variant="subtitle1"
                component="h2"
            >
                voor Scholengroep 20
            </Typography>

            <Box
                component="img"
                sx={{
                    // height: 233,
                    width: 350,
                    // maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 250, md: 350, borderRadius: "5px" },
                }}
                alt="Inventory management system"
                src={
                    hostsConfig.BACKEND_HOST +
                    "/images/vector_workers-warehouse-storage.jpg"
                }
            />

            {!isLoggedIn ? (
                <Button
                    variant="contained"
                    sx={{ mt: 3, width: 350, maxWidth: { xs: 250, md: 350 } }}
                    onClick={createGoogleAuthLink}
                >
                    Login
                </Button>
            ) : (
                <Button
                    variant="contained"
                    sx={{
                        display: "none",
                        mt: 3,
                        width: 350,
                        maxWidth: { xs: 250, md: 350 },
                    }}
                    onClick={signOut}
                >
                    Logout
                </Button>
            )}
            <Box sx={{ mt: 3, width: 350, maxWidth: { xs: 250, md: 350 } }}>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    fontSize={11}
                    justify="flex-end"
                    align="justify"
                >
                    Om je de beste ervaring te bieden, hebben we jouw
                    toestemming nodig om toegang te krijgen tot specifieke
                    Google Drive-functies. Wees gerust, jouw gegevensprivacy en
                    -veiligheid hebben onze hoogste prioriteit.{" "}
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    component="div"
                    fontSize={11}
                    align="justify"
                >
                    <Box fontWeight="600" display="inline" component="span">
                        Al je Google Drive-bestanden zien en downloaden:
                    </Box>{" "}
                    Met deze toestemming kunnen we een uitgebreide lijst van
                    jouw bestanden binnen de applicatie tonen en ze downloaden
                    indien nodig.{" "}
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    component="div"
                    fontSize={11}
                    align="justify"
                >
                    <Box fontWeight="600" display="inline" component="span">
                        Informatie over jouw Google Drive-bestanden zien:
                    </Box>{" "}
                    We hebben deze toegang nodig om essentiële details over jouw
                    bestanden te verzamelen, zodat we nauwkeurige informatie
                    kunnen presenteren en je algehele gebruikerservaring kunnen
                    verbeteren.{" "}
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    component="div"
                    fontSize={11}
                    align="justify"
                    mt={1}
                >
                    Deze pagina maakt gebruik van <Box fontWeight="600" display="inline" component="span">cookies</Box> om je ingelogd te
                    houden op hetzelfde apparaat. Om de cookie te verwijderen en
                    uit te loggen, klik je simpelweg op <Box fontWeight="600" display="inline" component="span">'Uitloggen'</Box> en sluit je
                    daarna je browser. De cookie wordt aan het einde van je sessie verwijderd.{" "}
                </Typography>{" "}
            </Box>
        </Box>
    );
};

export default Login;
