import useAuth from "../hooks/useAuth";
import useEnvironment from "../hooks/useSession";
import axios from "axios";

import { getToken } from "../tokens";

import { Button, Typography, Box, Paper, Link, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import {
    DataGrid,
    GridToolbar,
    GridColDef,
    GridValueGetterParams,
} from "@mui/x-data-grid";

import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import {
    blueberryTwilightPalette,
    mangoFusionPalette,
    cheerfulFiestaPalette,
} from "@mui/x-charts/colorPalettes";

import hostsConfig from "../hostconfig.json";

const chartSetting = {
    yAxis: [
        {
            label: "Aantal producten",
        },
    ],
    width: 1000,
    height: 300,
    legend: { hidden: true },
    // sx: {
    //     [`.${axisClasses.left} .${axisClasses.label}`]: {
    //         transform: "translate(-10px, 0)",
    //     },
    //     [`.${axisClasses.top} .${axisClasses.top}`]: {
    //         transform: "translate(-10px, 0)",
    //     },
    // },
};

const Home = () => {
    const { auth, setAuth } = useAuth();

    const [items, setItems] = useState([]);
    const [categoryViewColumns, setCategoryViewColumns] = useState([]);

    const [catPerStatus, setCatPerStatus] = useState([]);
    const [statusKeyLabel, setStatusKeyLabel] = useState([]);

    const [modelNumber, setModelNumber] = useState("");
    const [lastModelNumber, setLastModelNumber] = useState("");

    //const columns = categoryViewColumns;

    useEffect(() => {
        async function schoolsData() {
            try {
                let body = {
                    modelNumber: "",
                };
                if (modelNumber !== "") {
                    body = {
                        modelNumber: modelNumber,
                    };
                    setLastModelNumber(modelNumber);
                } else {
                    setLastModelNumber("");
                }

                const response = await axios.post(
                    hostsConfig.BACKEND_HOST + "/get_home_dashboard",
                    body
                );

                //const response = await axios.get(hostsConfig.BACKEND_HOST + "/get_home_dashboard");

                console.log(response);

                let orgs = response.data.orgs;
                let dataTable = response.data.catPerOrg;

                let _statusKeyLabel = response.data.statusKeyLabel;
                let _catPerStatus = response.data.catPerStatus;

                let row1 = {
                    field: "categoryLabel",
                    headerName: "Category",
                    width: 140,
                    hide: false,
                };
                let row2 = {
                    field: "Totaal",
                    headerName: "Totaal",
                    width: 60,
                    hide: false,
                };
                //add row1 and row2 to the begining of orgs array..
                orgs.unshift(row2);
                orgs.unshift(row1);

                for (let i = 2; i < orgs.length; i++) {
                    // if (orgs[i].hide === 1 || orgs[i].hide === "1" || orgs[i].hide === "true" || orgs[i].hide === true) {
                    //     orgs[i].hide = true;
                    //     orgs[i].width = 0;
                    // }
                    // if (orgs[i].hide === 0 || orgs[i].hide === "0" || orgs[i].hide === "false" || orgs[i].hide === false) {
                    //     orgs[i].hide = false;
                    //     orgs[i].width = 145;
                    // }
                    orgs[i].hide = false;
                    if (orgs[i].headerName.length === 2) {
                        orgs[i].width = 45;
                    } else if (orgs[i].headerName.length === 3) {
                        orgs[i].width = 50;
                    } else if (orgs[i].headerName.length === 4) {
                        orgs[i].width = 55;
                    } else {
                        orgs[i].width = 60;
                    }
                }

                let statusTotal = {
                    dataKey: "Totaal",
                    label: "Totaal",
                };

                _statusKeyLabel.push(statusTotal);
                setStatusKeyLabel(_statusKeyLabel);
                setCatPerStatus(_catPerStatus);

                setCategoryViewColumns(orgs);
                setItems(dataTable);
            } catch (error) {
                console.error(error);
            }
        }
        schoolsData();
    }, []);

    const getItems = async (m_n) => {
        try {
            let body = {
                modelNumber: m_n,
            };
            setLastModelNumber(m_n);

            const response = await axios.post(
                hostsConfig.BACKEND_HOST + "/get_home_dashboard",
                body
            );

            //const response = await axios.get(hostsConfig.BACKEND_HOST + "/get_home_dashboard");

            console.log(response);

            let orgs = response.data.orgs;
            let dataTable = response.data.catPerOrg;

            let _statusKeyLabel = response.data.statusKeyLabel;
            let _catPerStatus = response.data.catPerStatus;

            let row1 = {
                field: "categoryLabel",
                headerName: "Category",
                width: 140,
                hide: false,
            };
            let row2 = {
                field: "Totaal",
                headerName: "Totaal",
                width: 60,
                hide: false,
            };
            //add row1 and row2 to the begining of orgs array..
            orgs.unshift(row2);
            orgs.unshift(row1);

            for (let i = 2; i < orgs.length; i++) {
                // if (
                //     orgs[i].hide === 1 ||
                //     orgs[i].hide === "1" ||
                //     orgs[i].hide === "true" ||
                //     orgs[i].hide === true
                // ) {
                //     orgs[i].hide = true;
                //     orgs[i].width = 0;
                // }
                // if (
                //     orgs[i].hide === 0 ||
                //     orgs[i].hide === "0" ||
                //     orgs[i].hide === "false" ||
                //     orgs[i].hide === false
                // ) {
                //     orgs[i].hide = false;
                //     orgs[i].width = 110;
                // }
                orgs[i].hide = false;
                if (orgs[i].headerName.length === 2) {
                    orgs[i].width = 45;
                } else if (orgs[i].headerName.length === 3) {
                    orgs[i].width = 50;
                } else if (orgs[i].headerName.length === 4) {
                    orgs[i].width = 55;
                } else {
                    orgs[i].width = 60;
                }
            }

            let statusTotal = {
                dataKey: "Totaal",
                label: "Totaal",
            };

            // _statusKeyLabel.unshift(statusTotal);
            _statusKeyLabel.push(statusTotal);
            setStatusKeyLabel(_statusKeyLabel);
            setCatPerStatus(_catPerStatus);

            setCategoryViewColumns(orgs);
            setItems(dataTable);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography
                    color={"primary"}
                    variant="h4"
                    component="h1"
                    gutterBottom
                >
                    Welkom
                </Typography>
                <Typography
                    color={"primary"}
                    variant="subtitle1"
                    component="h2"
                    gutterBottom
                >
                    ICT voorraadbeheersysteem voor Scholengroep 20
                </Typography>

                {/* <Typography
                    sx={{ width: 0.8, pt: 2 }}
                    color={"primary"}
                    variant="subtitle1"
                    component="h2"
                    gutterBottom
                >
                    Lorem ipsum dolor sit amet. Ea beatae deleniti eos
                    perferendis nostrum ad laudantium quia eum aliquam
                    repellendus. Eos necessitatibus enim quo sint impedit et
                    quibusdam voluptas quo assumenda omnis. Et dolorum aliquam
                    ut pariatur quia est itaque eaque. Eos sunt minus quo
                    deserunt aperiam est dignissimos dolores non culpa iure.
                </Typography> */}

                <Box
                    sx={{
                        display: "flex",
                        width: 1,
                        backgroundColor: "aliceblue",
                        mb: 0,
                        pt: 2,
                    }}
                >
                    <TextField
                        id="outlined-basic"
                        label="search using model number (or part of it)"
                        variant="outlined"
                        sx={{ width: 1, mr: 2, ml: 2 }}
                        value={modelNumber}
                        size="small"
                        onChange={(e) => {
                            setModelNumber(e.target.value);
                        }}
                    />
                    <Button
                        variant="contained"
                        sx={{ width: 1, mr: 2, maxWidth: 100 }}
                        size="small"
                        onClick={() => {
                            getItems(modelNumber);
                        }}
                    >
                        Zoek
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ width: 1, mr: 2, maxWidth: 100 }}
                        size="small"
                        onClick={() => {
                            setModelNumber("");
                            getItems("");
                        }}
                    >
                        Reset
                    </Button>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        width: 1,
                        backgroundColor: "aliceblue",
                        mb: 2,
                        pt: 2,
                    }}
                >
                    {/* add TextField here */}

                    {catPerStatus.length > 0 ? (
                        <BarChart
                            dataset={catPerStatus}
                            xAxis={[
                                { scaleType: "band", dataKey: "categoryLabel" },
                            ]}
                            
                            series={statusKeyLabel}
                            borderRadius={10}
                            
                            // series={[
                            //     { dataKey: "london", label: "London" },
                            //     { dataKey: "paris", label: "Paris" },
                            //     { dataKey: "newYork", label: "New York" },
                            //     { dataKey: "seoul", label: "Seoul" },
                            // ]}
                            {...chartSetting}
                            // slotProps={{ legend: { hidden: true } }}
                            slotProps={{
                                legend: {
                                    labelStyle: {
                                        fontSize: 10,
                                        fill: "black",
                                    },
                                },
                            }}
                            colors={cheerfulFiestaPalette}
                        />
                    ) : (
                        <>
                            <Typography
                                sx={{ p: 2 }}
                                color={"orange"}
                                variant="subtitle1"
                                component="h2"
                                gutterBottom
                            >
                                No data found for model number:{" "}
                                {lastModelNumber}
                            </Typography>
                        </>
                    )}
                </Box>

                {/* <Box
                    component="img"
                    sx={{
                        // height: 233,
                        width: 700,
                        // maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 0, sm: 100, md: 100, lg: 200, xl: 200, borderRadius: "10px" },
                    }}
                    alt="Inventory management system"
                    src={hostsConfig.BACKEND_HOST + "/images/vector_workers-warehouse-storage.jpg"}
                />
                <Box>
                    <Typography color={"primary"} variant="caption" gutterBottom>
                        AI generated image
                    </Typography>
                </Box> */}

                <Box
                    sx={{ width: 1, height: 700, backgroundColor: "aliceblue" }}
                >
                    <DataGrid
                        rows={items}
                        columns={categoryViewColumns}
                        density="compact"
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        // hideFooter={true}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0
                                ? "Mui-even"
                                : "Mui-odd"
                        }
                    />
                </Box>
            </Box>
        </>
    );
};

export default Home;
