import useAuth from "../hooks/useAuth";
import useSession from "../hooks/useSession";
import useTranslation from "../hooks/useTranslation";

import { getToken } from "../tokens";

//import useEnvironment from "../hooks/useSession";
import axios from "axios";

import { useState, useEffect, useMemo } from "react";
import { json, useNavigate } from "react-router-dom";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel, TextField, Card } from "@mui/material";

import hostsConfig from "../hostconfig.json";

const DictionaryCRUD = ({ setOpenMessage, setMessage, setSeverity }) => {
    const { auth, setAuth } = useAuth();
    const { session, setSession } = useSession();
    const { translation, fetchTranslation } = useTranslation();



    const [dictionary, setDictionary] = useState(Object.entries(translation).map(([key, value]) => ({
        key: key,
        dutch: value
    })));

    const [dictionaryItem, setDictionaryItem] = useState({
        key: "",
        dutch: ""
    });

    const [nameDisabled, setNameDisabled] = useState(true);
    const [fieldsDisabled, setFieldsDisabled] = useState(true);

    const [buttonEditDisabled, setButtonEditDisabled] = useState(false);
    const [buttonAddDisabled, setButtonAddDisabled] = useState(false);
    const [buttonDeleteDisabled, setButtonDeleteDisabled] = useState(false);

    const [buttonCancelDisabled, setButtonCancelDisabled] = useState(true);
    const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(true);

    const [dialogMode, setDialogMode] = useState("");

    const [tra, setTra] = useState(true);

    /**
     * Translate a given text to its Dutch translation if it exists in the translation dictionary.
     * If the session environment language is 'en', return the original text.
     * If the translation does not exist, return the original text enclosed in square brackets.
     * @param {string} text - The text to translate.
     * @returns {string} - The translated text if it exists and the session environment language is not 'en', 
     *                     the original text if the session environment language is 'en', 
     *                     or the original text enclosed in square brackets if no translation exists.
     */
    const dict = (text) => {
        if (session.environment.lang === "en") {
            return text;
        } else {
            if (translation[text]) {
                return translation[text];
            } else {
                return "[" + text + "]";
            }
        }
    };



    useEffect(() => {
        setButtonAddDisabled(false);
        setButtonEditDisabled(false);
        setButtonCancelDisabled(true);
        setButtonSubmitDisabled(true);
    }, []);

    useEffect(() => {
        if (dialogMode === "edit") {
            setFieldsDisabled(false);

            setNameDisabled(false);
            setButtonEditDisabled(false);
            setButtonAddDisabled(true);
            setButtonDeleteDisabled(true);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "add") {
            emptyDictionaryItem();
            setFieldsDisabled(false);

            setNameDisabled(false);
            setButtonEditDisabled(true);
            setButtonAddDisabled(false);
            setButtonDeleteDisabled(true);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "delete") {
            setFieldsDisabled(true);

            setNameDisabled(true);
            setButtonEditDisabled(true);
            setButtonAddDisabled(true);
            setButtonDeleteDisabled(false);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "cancel") {
            setDictionaryItem(dictionary[0]);

            setFieldsDisabled(true);

            setNameDisabled(true);
            setButtonEditDisabled(false);
            setButtonAddDisabled(false);
            setButtonDeleteDisabled(false);

            setButtonCancelDisabled(true);
            setButtonSubmitDisabled(true);
        }
    }, [dialogMode]);

    const resetButtons = () => {
        setFieldsDisabled(true);
        setDictionaryItem(dictionary[0]);

        setNameDisabled(true);
        setButtonEditDisabled(false);
        setButtonAddDisabled(false);
        setButtonDeleteDisabled(false);

        setButtonCancelDisabled(true);
        setButtonSubmitDisabled(true);
    };

    const emptyDictionaryItem = () => {
        setDictionaryItem({
            key: "",
            dutch: ""
        });
    };

    const handleDictionaryItemSubmit = () => {
        console.log("handleManufacturerSubmit");
        console.log(dictionaryItem);

        if (dialogMode === "edit") {
            editDictionaryItem();
        }

        // if (dialogMode === "add") {
        //     addDictionaryItem();
        // }

        // if (dialogMode === "delete") {
        //     deleteDictionaryItem();
        // }
    };

    const editDictionaryItem = async () => {
        console.log("editDictionaryItem");
        console.log(dictionaryItem);

        if(dictionaryItem.dutch === "" || dictionaryItem.dutch.replace(/\s/g, '') === "") {
            setMessage(dict("Dutch translation is required."));
            setOpenMessage(true);
            return;
        }

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                key: dictionaryItem.key,
                dutch: dictionaryItem.dutch,
                accountEmail: accountEmail,
            };

            const response = await axios.put(hostsConfig.BACKEND_HOST + "/update_dictionary", body);

            //update session
            let newDictionary = dictionary;
            for (let i = 0; i < dictionary.length; i++) {
                if (newDictionary[i].key === dictionaryItem.key) {
                    newDictionary[i] = dictionaryItem;
                }
            }
            setDictionary(newDictionary);

            let newTranslation = translation;
            newTranslation[dictionaryItem.key] = dictionaryItem.dutch;
            fetchTranslation();

            setMessage(dict("Updated succesfuly."));
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage(dict("Error updating."));
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    const addDictionaryItem = async () => {
        console.log("addManufacturer");
        console.log(dictionaryItem);

        if (dictionaryItem.dutch === "" ) {
            setMessage(dict("Please fill all fields."));
            setOpenMessage(true);
            return;
        }

        var d_i = dictionaryItem;

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                key: "",
                manufacturerName: d_i.dutch,
                accountEmail: accountEmail,
            };

            const response = await axios.post(hostsConfig.BACKEND_HOST + "/insert_dictionary", body);

            let new_d_i = response.data[0];

            if (new_d_i === undefined) {
                setMessage(dict("Error adding dictionary item. Check if it already exists"));
                setSeverity("error");
                setOpenMessage(true);
                return;
            }
            //update session
            let newTranslation = translation;
            newTranslation.push(new_d_i);
            //setTranslation(newTranslation);

            setMessage(dict("Added succesfuly."));
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage(dict("Error adding."));
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    const deleteDictionaryItem = async () => {
        console.log("deleteDictionaryItem");
        console.log(dictionaryItem);

        let d_i = dictionaryItem;

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                key: d_i.key,
                accountEmail: accountEmail,
            };

            const response = await axios.post(hostsConfig.BACKEND_HOST + "/delete_dictionary", body);

            let newDictionary = response.data;

            if (newDictionary === undefined || newDictionary.length === 0) {
                setMessage(dict("Error deleting manufacturer. Check if there are products associated with it."));
                setSeverity("error");
                setOpenMessage(true);
                return;
            }


            //setTranslation(newDictionary);
            setDictionaryItem(dictionary[0]);

            setMessage(dict("Deleted succesfuly."));
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage(dict("Error deleting."));
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    const reloadDictionary = async () => {
        try {
            fetchTranslation();
            setMessage(dict("Reloaded succesfuly."));
            setSeverity("success");
            setOpenMessage(true);
        } catch (error) {
            console.error(error);

            setMessage(dict("Error reloading."));
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    return (
        <>
            <Grid container spacing={2}>


                <Grid item md={6} lg={6}>
                    <Grid container spacing={1}>
                        <Grid item md={12} lg={12}>
                            <FormControl sx={{ width: 1, pb: 2 }} size="small">
                                <InputLabel id="dropdown-manufacturer-label">{dict("Select item")}</InputLabel>
                                <Select
                                    labelId="dropdown-manufacturer-label"
                                    id="dropdown-manufacturer"
                                    value={dictionaryItem.key}
                                    label={dict("Select item")}
                                    onChange={(e) => {
                                        setDictionaryItem(dictionary.find((d) => d.key === e.target.value));
                                    }}
                                >
                                    {dictionary?.map((d) => (
                                        <MenuItem key={d.key} value={d.key}>
                                            {d.key} 
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("edit");
                                }}
                                disabled={buttonEditDisabled}
                            >
                                {dict("Edit")}
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("add");
                                }}
                                disabled={true} //{buttonAddDisabled}
                            >
                                {dict("Add")}
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    reloadDictionary();
                                }}
                                disabled={false} //{buttonDeleteDisabled}
                            >
                                {dict("Reload")}
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("cancel");
                                }}
                                disabled={buttonCancelDisabled}
                            >
                                {dict("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    handleDictionaryItemSubmit();
                                }}
                                disabled={buttonSubmitDisabled}
                            >
                                {dict("Submit")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={5} lg={6}>
                    <Card elevation={5} variant="elevation" sx={{ p: 3, backgroundColor: "#bbdefb", w: 1 }}>
                        <TextField
                            sx={{ mt: 0, ml: 1, mb: 1, width: 1 }}
                            id="manufacturerName"
                            label={dict("Dutch translation")}
                            variant="standard"
                            size="small"
                            value={dictionaryItem.dutch}
                            onChange={(e) => {
                                setDictionaryItem({ ...dictionaryItem, dutch: e.target.value });
                            }}
                            disabled={nameDisabled}
                        />


                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default DictionaryCRUD;
