//create component for product details
import React, { useEffect, useState } from "react";
import useSession from "../hooks/useSession";
import useTranslation from "../hooks/useTranslation";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography, Card } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel, TextField, Tooltip } from "@mui/material";
import { Newspaper } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import { red } from "@mui/material/colors";
// import Tooltip from '@mui/material/Tooltip';

const ProductDetails = ({
    selectedRows,
    schools,
    newSchool,
    setNewSchool,
    newLocation,
    setNewLocation,
    newInputLocation,
    setNewInputLocation,
    newRelocationNotes,
    setNewRelocationNotes,
    handleChangeProductsLocation,
    statuses,
    newStatus,
    setNewStatus,
    // newStatusNotes,
    // setNewStatusNotes,
    newHistoryNotes,
    setNewHistoryNotes,
    handleChangeProductsStatus,
    categories,
    newCategory,
    setNewCategory,
    subcategories,
    newSubcategory,
    setNewSubcategory,
    manufacturers,
    newManufacturer,
    setNewManufacturer,
    newInputManufacturer,
    setNewInputManufacturer,
    newModelNumber,
    setNewModelNumber,
    newProductName,
    setNewProductName,
    newSerialNumber,
    setNewSerialNumber,
    newDetailNotes,
    setNewDetailNotes,
    handleChangeProductsDetails,
    mode,
    handleCreateProducts,
    hasPrices,
    newPriceVATexcl,
    setNewPriceVATexcl,
    newVAT,
    setNewVAT,
    newPriceVATincl,
    setNewPriceVATincl,
    handleChangeProductsPrices,
    productLastStatusObj,
    handleDeleteProduct,
    insurances,
    newInsurance,
    setNewInsurance,
    userInfo
}) => {
    const [newOrg, setNewOrg] = useState(newSchool);
    const { session, setSession } = useSession();
    // const [newLoc, setNewLoc] = useState(newLocation);

    const { translation, setTranslation } = useTranslation();

    const [inputLocation, setInputLocation] = useState("");
    const [optionLocation, setOptionLocation] = useState(null);
    const [locationNotFound, setLocationNotFound] = useState(false);

    const [inputManufacturer, setInputManufacturer] = useState("");
    const [optionManufacturer, setOptionManufacturer] = useState(null);
    const [manufacturerNotFound, setManufacturerNotFound] = useState(false);

    useEffect(() => {
        setNewOrg(newSchool);

        var sch = schools.filter((s) => s.id === newSchool)[0];
        if (sch !== undefined) {
            if (sch.locations.filter((l) => l.id === newLocation).length > 0) {
                setOptionLocation(sch.locations.filter((l) => l.id === newLocation)[0].description);
            } else {
                setOptionLocation(null);
            }
        }
    }, [newSchool, newLocation]);

    useEffect(() => {
        if (manufacturers.filter((m) => m.manufacturerID === newManufacturer).length > 0) {
            setOptionManufacturer(manufacturers.filter((m) => m.manufacturerID === newManufacturer)[0].manufacturerName);
        } else {
            setOptionManufacturer(null);
        }
    }, [newManufacturer]);

    /**
     * Translate a given text to its Dutch translation if it exists in the translation dictionary.
     * If the session environment language is 'en', return the original text.
     * If the translation does not exist, return the original text enclosed in square brackets.
     * @param {string} text - The text to translate.
     * @returns {string} - The translated text if it exists and the session environment language is not 'en', 
     *                     the original text if the session environment language is 'en', 
     *                     or the original text enclosed in square brackets if no translation exists.
     */
    const dict = (text) => {
        if (session.environment.lang === "en") {
            return text;
        } else {
            if (translation[text]) {
                return translation[text];
            } else {
                return "[" + text + "]";
            }
        }
    };

    const handleOptionLocationChange = (event: any, newValue: string | null) => {
        setOptionLocation(newValue);
        setNewInputLocation(newValue);

        console.log(newValue);
        if (newValue !== inputLocation) {
            setLocationNotFound(true);
        } else {
            setLocationNotFound(false);
        }

        //if newValue belongs to the locations of the selected school then set selectedLocation to id of that location
        let school = schools.filter((s) => s.id === newSchool)[0];
        if (school.locations.filter((l) => l.description === newValue).length > 0) {
            var loc = school.locations.filter((l) => l.description === newValue)[0];
            setNewLocation(school.locations.filter((l) => l.description === newValue)[0].id);
            // setNewLoc(loc.id);
        } else {
            setNewLocation("");
            // setNewLocation("");
        }
    };

    const handleInputLocationChange = (event, newInputValue) => {
        setInputLocation(newInputValue);
        setNewInputLocation(newInputValue);

        console.log(newInputValue);
        if (newInputValue !== optionLocation) {
            setLocationNotFound(true);
        } else {
            setLocationNotFound(false);
        }

        var school = schools.filter((s) => s.id === newSchool)[0];
        if (school.locations.filter((l) => l.description === newInputValue).length > 0) {
            var loc = school.locations.filter((l) => l.description === newInputValue)[0];
            setNewLocation(school.locations.filter((l) => l.description === newInputValue)[0].id);
            // setNewLoc(loc.id);
        } else {
            setNewLocation("");
            // setNewLoc("");
        }
    };

    const handleOptionManufacturerChange = (event: any, newValue: string | null) => {
        // setOptionLocation(newValue);
        // setNewInputLocation(newValue);

        setOptionManufacturer(newValue);
        setNewInputManufacturer(newValue);

        console.log(newValue);
        if (newValue !== inputManufacturer) {
            // setLocationNotFound(true);
            setManufacturerNotFound(true);
        } else {
            // setLocationNotFound(false);
            setManufacturerNotFound(false);
        }

        //if newValue belongs to the manufacturers then set selectedManufacturer to id of that manufacturer
        if (manufacturers.filter((m) => m.manufacturerName === newValue).length > 0) {
            setNewManufacturer(manufacturers.filter((m) => m.manufacturerName === newValue)[0].manufacturerID);
        } else {
            setNewManufacturer("");
        }
    };

    const handleInputManufacturerChange = (event, newInputValue) => {
        // setInputLocation(newInputValue);
        // setNewInputLocation(newInputValue);

        setInputManufacturer(newInputValue);
        setNewInputManufacturer(newInputValue);

        console.log(newInputValue);
        if (newInputValue !== optionManufacturer) {
            // setLocationNotFound(true);
            setManufacturerNotFound(true);
        } else {
            // setLocationNotFound(false);
            setManufacturerNotFound(false);
        }

        //if newValue belongs to the manufacturers then set selectedManufacturer to id of that manufacturer
        if (manufacturers.filter((m) => m.manufacturerName === newInputValue).length > 0) {
            setNewManufacturer(manufacturers.filter((m) => m.manufacturerName === newInputValue)[0].manufacturerID);
        } else {
            setNewManufacturer("");
        }
    };

    const doNothing = () => { };

    const handleNewSchoolChange = (event) => {
        setNewOrg(event.target.value);
        setNewSchool(event.target.value);
    };

    const handleNewLocationChange = (event) => {
        // setNewLoc(event.target.value);
        setNewLocation(event.target.value);
    };

    const handleRelocationNotesChange = (event) => {
        setNewRelocationNotes(event.target.value);
    };

    const handleNewStatusChange = (event) => {
        setNewStatus(event.target.value);
    };

    // const handleStatusNotesChange = (event) => {
    //     setNewStatusNotes(event.target.value);
    // };

    const handleHistoryNotesChange = (event) => {
        setNewHistoryNotes(event.target.value);
    };

    const handleNewCategoryChange = (event) => {
        setNewCategory(event.target.value);
    };

    const handleNewSubcategoryChange = (event) => {
        setNewSubcategory(event.target.value);
    };

    // const handleNewManufacturerChange = (event) => {
    //     setNewManufacturer(event.target.value);
    // };

    const handleModelNumberChange = (event) => {
        setNewModelNumber(event.target.value);
    };

    const handleProductNameChange = (event) => {
        setNewProductName(event.target.value);
    };

    const handleSerialNumberChange = (event) => {
        setNewSerialNumber(event.target.value);
    };

    const handleDetailNotesChange = (event) => {
        setNewDetailNotes(event.target.value);
    };

    const handlePriceVATexclChange = (event) => {
        setNewPriceVATexcl(event.target.value);
    };

    const handleVATChange = (event) => {
        setNewVAT(event.target.value);
    };

    const handlePriceVATinclChange = (event) => {
        setNewPriceVATincl(event.target.value);
    };

    const calculatePriceVATincluded = () => {
        if (newPriceVATexcl > 0 && newVAT > 0) {
            setNewPriceVATincl((newPriceVATexcl * (1 + newVAT / 100)).toFixed(2));
        } else {
            setNewPriceVATincl(0);
        }
    };

    const calculatePriceVATexcluded = () => {
        if (newPriceVATincl > 0 && newVAT > 0) {
            setNewPriceVATexcl((newPriceVATincl / (1 + newVAT / 100)).toFixed(2));
        } else {
            setNewPriceVATexcl(0);
        }
    };

    const handleChangePricesClicked = () => {
        if (newPriceVATexcl > 0 && newVAT > 0 && newPriceVATincl > 0) {
            handleChangeProductsPrices();
        }
    };

    const handleNewInsuranceChange = (event) => {
        setNewInsurance(event.target.value);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={12} lg={6}>
                    <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                        {/* <Box sx={{ maxWidth: "100%" }}>
                   </Box>      */}

                        <Typography sx={{ fontSize: 14, pb: 1 }} color="text.primary" gutterBottom>
                            {dict("Location details")}
                        </Typography>
                        <Box>
                            <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                                <InputLabel id="demo-select-small">School</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={newOrg}
                                    label={dict("School")}
                                    onChange={handleNewSchoolChange}
                                >
                                    {schools.map((sch, index) => (
                                        <MenuItem key={index} value={sch.id}>
                                            {sch.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                                <InputLabel id="demo-select-small">Location</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={newLoc}
                                    label="Location"
                                    onChange={handleNewLocationChange}
                                >
                                    {newOrg !== "" &&
                                        schools
                                            .filter((sch) => sch.id === newOrg)[0]
                                            .locations.map((loc, index) => (
                                                <MenuItem key={index} value={loc.id}>
                                                    {loc.description}
                                                </MenuItem>
                                            ))}
                                </Select>
                            </FormControl> */}

                            <FormControl sx={{ mt: 1, ml: 1, mb: 1, width: 250 }} size="small">
                                {newOrg !== "" ? (
                                    <Autocomplete
                                        value={optionLocation}
                                        onChange={handleOptionLocationChange}
                                        inputValue={inputLocation}
                                        onInputChange={handleInputLocationChange}
                                        id="combo-box-demo2"
                                        freeSolo
                                        options={
                                            newOrg !== undefined
                                                ? schools.filter((s) => s.id === newOrg)[0]?.locations.map((option) => option.description)
                                                : ""
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} label={dict("Select or enter class/person")} variant="outlined" fullWidth />
                                        )}
                                        disabled={newOrg === ""}
                                        size="small"
                                    />
                                ) : (
                                    <>
                                        <TextField
                                            sx={{}}
                                            id="dummy"
                                            size="small"
                                            label={dict("Select or enter class/person")}
                                            value={""}
                                            onChange={doNothing}
                                            disabled={true}
                                        />
                                    </>
                                )}
                            </FormControl>

                            <TextField
                                sx={{ pt: 1, pb: 1, ml: 1, width: "400px" }}
                                id="notes"
                                size="small"
                                label={dict("Relocation notes")}
                                value={newRelocationNotes}
                                onChange={handleRelocationNotesChange}
                            />

                            {mode === "change" && (
                                <Box pl={1}>
                                    <Button variant="contained" onClick={handleChangeProductsLocation}>
                                        {dict("Change location")}
                                        {/* (for {selectedRows.length} product{selectedRows.length > 1 ? "s" : ""}) */}
                                    </Button>
                                </Box>
                            )}
                            {optionLocation !== inputLocation ? (
                                <Typography sx={{ mt: 1, ml: 2, mb: 1 }} variant="caption" color="primary">
                                    {dict("New class or person ")} ({inputLocation}) {dict("will be created.")}
                                </Typography>
                            ) : null}
                        </Box>
                    </Card>
                </Grid>

                <Grid item md={12} lg={6}>
                    <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                        <Typography sx={{ fontSize: 14, pb: 1 }} color="text.primary" gutterBottom>
                            {dict("Status")}
                        </Typography>
                        <Box>
                            <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                                <InputLabel id="demo-select-small">Status</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={newStatus}
                                    label={dict("Status")}
                                    onChange={handleNewStatusChange}
                                >
                                    {statuses.map((sts, index) => (
                                        <MenuItem key={index} value={sts.statusTypeID}>
                                            {
                                                session.environment.lang === "en" ? sts.statusName : sts.statusLabel
                                            }
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                sx={{ pt: 1, pb: 1, ml: 1, width: "400px" }}
                                id="notes"
                                size="small"
                                label={dict("History notes")}
                                value={newHistoryNotes}
                                onChange={handleHistoryNotesChange}
                            />

                            {/* <TextField
                                sx={{ pt: 1, pb: 1, ml: 1, width: "400px", backgroundColor: "#f50000" }}
                                id="notes"
                                size="small"
                                label="Details (status notes)"
                                value={newStatusNotes}
                                onChange={handleStatusNotesChange}


                            /> */}
                            {mode === "change" && (
                                <>
                                    <Box pl={1}>
                                        <Button variant="contained" onClick={handleChangeProductsStatus}>
                                            {dict("Change status")}
                                            {/* (for {selectedRows.length} product{selectedRows.length > 1 ? "s" : ""}) */}
                                        </Button>

                                        {productLastStatusObj !== "" &&
                                            statuses.filter((sts) => sts.statusTypeID === productLastStatusObj.statusTypeID)[0].statusName === "Disposed" && (
                                                <Button disabled={userInfo && userInfo.role !== "admin"} sx={{ backgroundColor: "red" }} variant="contained" onClick={handleDeleteProduct}>
                                                    {dict("Delete product")} {userInfo && userInfo.role !== "admin" ? "(only admin)" : ""}
                                                </Button>
                                            )}
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Card>
                </Grid>

                <Grid item md={12} lg={12}>
                    <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                        <Typography sx={{ fontSize: 14, pb: 1 }} color="text.primary" gutterBottom>
                            {dict("Details")}
                        </Typography>
                        <Box>
                            <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                                <InputLabel id="demo-select-small">{dict("Category")}</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={newCategory}
                                    label={dict("Category")}
                                    onChange={handleNewCategoryChange}
                                >
                                    {categories.map((cat, index) => (
                                        <MenuItem key={index} value={cat.categoryID}>
                                            {
                                                session.environment.lang === "en" ? cat.categoryName : cat.categoryLabel
                                            }
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                                <InputLabel id="demo-select-small">{dict("Subcategory")}</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={newSubcategory}
                                    label={dict("Subcategory")}
                                    onChange={handleNewSubcategoryChange}
                                >
                                    {newCategory !== "" &&
                                        subcategories
                                            .filter((sub) => sub.categoryID === newCategory)
                                            .map((sub, index) => (
                                                <MenuItem key={index} value={sub.subcategoryID}>
                                                    {
                                                        session.environment.lang === "en" ? sub.subcategoryName : sub.subcategoryLabel
                                                    }
                                                </MenuItem>
                                            ))}
                                </Select>
                            </FormControl>

                            {/* <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                                <InputLabel id="demo-select-small">Manufacturer</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={newManufacturer}
                                    label="Manufacturer"
                                    onChange={handleNewManufacturerChange}
                                >
                                    {manufacturers.map((man, index) => (
                                        <MenuItem key={index} value={man.manufacturerID}>
                                            {man.manufacturerName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}

                            <FormControl sx={{ mt: 1, ml: 1, mb: 1, width: 250 }} size="small">
                                <Autocomplete
                                    value={optionManufacturer}
                                    onChange={handleOptionManufacturerChange}
                                    inputValue={inputManufacturer}
                                    onInputChange={handleInputManufacturerChange}
                                    id="combo-box-demo2"
                                    freeSolo
                                    options={
                                        // newOrg !== undefined
                                        //     ? schools.filter((s) => s.id === newOrg)[0]?.locations.map((option) => option.description)
                                        //     : ""
                                        manufacturers.map((option) => option.manufacturerName)
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} label={dict("Select or enter manufacturer")} variant="outlined" fullWidth />
                                    )}
                                    // disabled={newOrg === ""}
                                    size="small"
                                />
                            </FormControl>

                            <TextField
                                sx={{ pl: 0, mt: 1, ml: 1, width: "300px" }}
                                id="Model"
                                size="small"
                                label={dict("Model")}
                                value={newModelNumber}
                                onChange={handleModelNumberChange}
                            />

                            <TextField
                                sx={{ pl: 0, mt: 1, ml: 1, width: "300px" }}
                                id="Serial"
                                size="small"
                                label={dict("Serial")}
                                value={newSerialNumber}
                                onChange={handleSerialNumberChange}
                            />

                            <TextField
                                sx={{ pl: 0, mt: 1, ml: 1, width: "300px" }}
                                id="productName"
                                size="small"
                                label={dict("Product name")}
                                value={newProductName}
                                disabled={true}
                                onChange={handleProductNameChange}
                            />

                            <TextField
                                sx={{ pl: 0, mt: 1, ml: 1, pb: 1, width: "400px" }}
                                id="Detail notes"
                                size="small"
                                label={dict("Detail notes")}
                                // multiline
                                // rows={2}
                                value={newDetailNotes}
                                onChange={handleDetailNotesChange}
                            />

                            <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                                <InputLabel id="demo-select-small">{dict("Insurance category")}</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={newInsurance}
                                    label={dict("Insurance category")}
                                    onChange={handleNewInsuranceChange}
                                >
                                    {insurances.map((ins, index) => (
                                        <MenuItem key={index} value={ins.insuranceID}>
                                            {ins.insuranceName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {mode === "change" && (
                                <Box pl={1}>
                                    <Button variant="contained" onClick={handleChangeProductsDetails}>
                                        {dict("Change details")}
                                        {/* (for {selectedRows.length} product{selectedRows.length > 1 ? "s" : ""}) */}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Card>
                </Grid>

                {hasPrices && hasPrices === "true" && (
                    <Grid item md={12} lg={12}>
                        <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                            <Typography variant="h6">{dict("Prices")}</Typography>
                            <Box>
                                <TextField
                                    sx={{ pt: 1, pb: 1, ml: 1, width: "200px" }}
                                    id="PriceExclVAT"
                                    size="small"
                                    label={dict("Price excl. VAT")}
                                    type="number"
                                    value={newPriceVATexcl}
                                    onChange={handlePriceVATexclChange}
                                />

                                <Box pl={1} display={"inline"}>
                                    <Tooltip title="Calculate the price excluding VAT with rounding to two decimal places" arrow>
                                        <Button size="small" sx={{ mt: 2 }} variant="contained" onClick={calculatePriceVATexcluded}>
                                            {"<"}
                                        </Button>
                                    </Tooltip>
                                </Box>

                                <TextField
                                    sx={{ pt: 1, pb: 1, ml: 1, width: "200px" }}
                                    id="VAT"
                                    size="small"
                                    label={dict("BTW")}
                                    type="number"
                                    value={newVAT}
                                    onChange={handleVATChange}
                                />

                                <Box pl={1} display={"inline"}>
                                    <Tooltip title="Calculate the price including VAT with rounding to two decimal places." arrow>
                                        <Button size="small" sx={{ mt: 2 }} variant="contained" onClick={calculatePriceVATincluded}>
                                            {">"}
                                        </Button>
                                    </Tooltip>
                                </Box>

                                <TextField
                                    sx={{ pt: 1, pb: 1, ml: 1, width: "200px" }}
                                    id="PriceInclVAT"
                                    size="small"
                                    type="number"
                                    label={dict("Price incl. VAT")}
                                    value={newPriceVATincl}
                                    onChange={handlePriceVATinclChange}
                                />

                                {mode === "change" && (
                                    <Box pl={1}>
                                        <Button variant="contained" onClick={handleChangePricesClicked}>
                                            {dict("Change prices")}
                                            {/* (for {selectedRows.length} product{selectedRows.length > 1 ? "s" : ""}) */}
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Card>
                    </Grid>
                )}
                <Grid item md={12} lg={12}>
                    {mode === "create" && (
                        <Button variant="contained" onClick={handleCreateProducts}>
                            {dict("Create products")}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default ProductDetails;
