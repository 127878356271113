import useAuth from "../hooks/useAuth";
import useSession from "../hooks/useSession";
import { getToken } from "../tokens";

//import useEnvironment from "../hooks/useSession";
import axios from "axios";

import { useState, useEffect, useMemo } from "react";
import { json, useNavigate } from "react-router-dom";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel, TextField, Card } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import hostsConfig from "../hostconfig.json";

import ActionTypeCRUD from "../components/ActionTypeCRUD";
import ManufacturersCRUD from "../components/ManufacturersCRUD";
import StatusTypeCRUD from "../components/StatusTypeCRUD";
import CategoriesCRUD from "../components/CategoriesCRUD";
import OrganisationCRUD from "../components/OrganisationCRUD";

const Settings = () => {
    const { auth, setAuth } = useAuth();
    const { session, setSession } = useSession();

    const [openMessage, setOpenMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    useEffect(() => {}, []);

    return (
        <Box className="mainBox" sx={{ m: 2, width: "100%" }}>
            <Box sx={{ pr: 4 }}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>Manufacturers</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ManufacturersCRUD setOpenMessage={setOpenMessage} setMessage={setMessage} setSeverity={setSeverity} />
                    </AccordionDetails>
                </Accordion>
            </Box>

            <Box sx={{ pr: 4, mt: 1 }}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>Status</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <StatusTypeCRUD setOpenMessage={setOpenMessage} setMessage={setMessage} setSeverity={setSeverity} />
                    </AccordionDetails>
                </Accordion>
            </Box>

            <Box sx={{ pr: 4, mt: 1 }}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>Categories</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CategoriesCRUD setOpenMessage={setOpenMessage} setMessage={setMessage} setSeverity={setSeverity} />
                    </AccordionDetails>
                </Accordion>
            </Box>

            <Box sx={{ pr: 4, mt: 1 }}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>Schools / SGR20</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <OrganisationCRUD setOpenMessage={setOpenMessage} setMessage={setMessage} setSeverity={setSeverity} />
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box maxWidth={800}>
                <Snackbar
                    open={openMessage}
                    onClose={() => {
                        setOpenMessage(false);
                    }}
                    TransitionComponent={SlideTransition}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    key="Snackbar"
                    //autoHideDuration={3000}
                >
                    <Alert
                        severity={severity}
                        sx={{ marginTop: 2 }}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export default Settings;
