import hostsConfig from "./hostconfig.json";
import axios, { all } from "axios";

export const getToken = async () => {
    //check if refresh token exists
    try {
        const response = await axios.get(
            hostsConfig.BACKEND_HOST + "/getRefreshAccessToken",
            { withCredentials: true } // Required for cookies
        );
        //const data = await response.json();
        console.log("New Access Token:", response.data.accessToken);
        return response.data.accessToken;
    } catch (error) {
        return null;
    }

    // if (tokenExpired()) {
    //     const refreshtoken = sessionStorage.getItem("refreshToken");
    //     const token = await getValidTokenFromServer(refreshtoken);
    //     sessionStorage.setItem("accessToken", token.accessToken);
    //     sessionStorage.setItem("expirationDate", newExpirationDate());
    //     return token.accessToken;
    // } else {
    //     console.log("tokens.js 11 | token not expired");
    //     return sessionStorage.getItem("accessToken");
    // }
};

const newExpirationDate = () => {
    var expiration = new Date();
    expiration.setHours(expiration.getHours() + 1);
    return expiration;
};

const tokenExpired = () => {
    const now = Date.now();

    const expirationDate = sessionStorage.getItem("expirationDate");
    const expDate = new Date(expirationDate);

    if (now > expDate.getTime()) {
        return true; // token expired
    }

    return false; // valid token
};


