import useAuth from "../hooks/useAuth";
import useSession from "../hooks/useSession";
import { getToken } from "../tokens";

//import useEnvironment from "../hooks/useSession";
import axios from "axios";

import { useState, useEffect, useMemo } from "react";
import { json, useNavigate } from "react-router-dom";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel, TextField, Card } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";

import hostsConfig from "../hostconfig.json";



const SubCategoriesCRUD = ({

    setOpenMessage,
    setMessage,
    setSeverity,
    categoryID

}) => {

    const { auth, setAuth } = useAuth();
    const { session, setSession } = useSession();

    // CREATE TABLE `subcategories` (
    //     `subcategoryID` varchar(36) NOT NULL,
    //     `categoryID` varchar(36) DEFAULT NULL,
    //     `subcategoryName` varchar(255) DEFAULT NULL,
    //     `seq` int DEFAULT NULL,
    //     `subcategoryLabel` varchar(100) DEFAULT NULL,
    //     PRIMARY KEY (`subcategoryID`),
    //     KEY `categoryID` (`categoryID`),
    //     CONSTRAINT `subcategories_ibfk_1` FOREIGN KEY (`categoryID`) REFERENCES `categories` (`categoryID`) ON DELETE CASCADE ON UPDATE CASCADE
    //   ) ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci;




    const [items, setItems] = useState(session.environment.subcategories.filter((item) => item.categoryID === categoryID));
    // const [items, setItems] = useState([]);

    const [item, setItem] = useState({
        subcategoryID: "",
        subcategoryLabel: "",
        subcategoryName: "",
        seq: 0,
    });

    const [nameDisabled, setNameDisabled] = useState(true);
    const [fieldsDisabled, setFieldsDisabled] = useState(true);

    const [buttonEditDisabled, setButtonEditDisabled] = useState(false);
    const [buttonAddDisabled, setButtonAddDisabled] = useState(false);
    const [buttonDeleteDisabled, setButtonDeleteDisabled] = useState(false);

    const [buttonCancelDisabled, setButtonCancelDisabled] = useState(true);
    const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(true);

    const [dialogMode, setDialogMode] = useState("");



    useEffect(() => {


        setButtonAddDisabled(false);
        setButtonEditDisabled(false);
        setButtonCancelDisabled(true);
        setButtonSubmitDisabled(true);
    }, []);

    useEffect(() => {
        if (dialogMode === "edit") {
            setFieldsDisabled(false);

            setNameDisabled(true);
            setButtonEditDisabled(false);
            setButtonAddDisabled(true);
            setButtonDeleteDisabled(true);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "add") {
            emptyItem();
            setFieldsDisabled(false);

            setNameDisabled(false);
            setButtonEditDisabled(true);
            setButtonAddDisabled(false);
            setButtonDeleteDisabled(true);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "delete") {
            setFieldsDisabled(true);

            setNameDisabled(true);
            setButtonEditDisabled(true);
            setButtonAddDisabled(true);
            setButtonDeleteDisabled(false);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "cancel") {
            setItem(session.environment.subcategories.filter((item) => item.categoryID === categoryID)[0]);

            if (session.environment.subcategories.filter((item) => item.categoryID === categoryID).length === 0) {
                setItems([]);
                emptyItem();
            }

            setFieldsDisabled(true);

            setNameDisabled(true);
            setButtonEditDisabled(false);
            setButtonAddDisabled(false);
            setButtonDeleteDisabled(false);

            setButtonCancelDisabled(true);
            setButtonSubmitDisabled(true);
        }
    }, [dialogMode]);

    //useEffect when categoryID changes the items should be updated to empty

    useEffect(() => {
        if (categoryID === "" || categoryID === undefined) return;
        if(session.environment.subcategories.filter((item) => item.categoryID === categoryID).length === 0){
            setItems([]);
            emptyItem();
            return;
        };

        setItems(session.environment.subcategories.filter((item) => item.categoryID === categoryID));
        setItem(session.environment.subcategories.filter((item) => item.categoryID === categoryID)[0]);
    }, [categoryID]);






    const resetButtons = () => {
        setFieldsDisabled(true);
        setItem(session.environment.subcategories.filter((item) => item.categoryID === categoryID)[0]);

        setNameDisabled(true);
        setButtonEditDisabled(false);
        setButtonAddDisabled(false);
        setButtonDeleteDisabled(false);

        setButtonCancelDisabled(true);
        setButtonSubmitDisabled(true);
    };

    const emptyItem = () => {
        setItem({
            subcategoryID: "",
            subcategoryLabel: "",
            subcategoryName: "",
            seq: "",
        });
    };

    const handleItemSubmit = () => {
        console.log("handleItemSubmit");
        console.log(item);

        if (dialogMode === "edit") {
            editItem();
        }

        if (dialogMode === "add") {
            addItem();
        }

        if (dialogMode === "delete") {
            deleteItem();
        }
    };

    const editItem = async () => {
        console.log("editItem");
        console.log(item);


        if (item.subcategoryLabel === "" || item.seq === "") {
            setMessage("Please fill all fields.");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        try {

            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                subcategoryID: item.subcategoryID,
                subcategoryLabel: item.subcategoryLabel,
                subcategoryName: item.subcategoryName,
                seq: item.seq,
                accountEmail: accountEmail,
            };

            const response = await axios.put(hostsConfig.BACKEND_HOST + "/update_subcategory", body);

            //update session
            let newSession = session;
            for (let i = 0; i < items.length; i++) {
                if (items[i].subcategoryID === item.subcategoryID) {
                    for (let j = 0; j < newSession.environment.subcategories.length; j++) {
                        if (newSession.environment.subcategories[j].subcategoryID === item.subcategoryID) {
                            newSession.environment.subcategories[j] = item;
                        }
                    }
                }
            }
            setSession(newSession);

            setMessage("Updated succesfuly.");
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage("Error updating.");
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    const addItem = async () => {
        console.log("addItem");
        console.log(item);

        if (item.subcategoryName === "" || item.subcategoryLabel === "" || item.subcategoryColor === "" || item.seq === "") {
            setMessage("Please fill all fields.");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        let at = item;

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                categoryID: categoryID,
                subcategoryID: "",
                subcategoryLabel: at.subcategoryLabel,
                subcategoryName: at.subcategoryName,
                seq: at.seq,
                accountEmail: accountEmail,
            };

            const response = await axios.post(hostsConfig.BACKEND_HOST + "/insert_subcategory", body);

            let newItem = response.data[0];

            if (newItem === undefined) {
                setMessage("Error adding category. Check if it already exists");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            //update session
            let newSession = session;
            newSession.environment.subcategories.push(newItem);
            setSession(newSession);

            setMessage("Added succesfuly.");
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage("Error adding.");
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    const deleteItem = async () => {
        console.log("deleteItem");
        console.log(item);

        let at = item;

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                subcategoryID: at.subcategoryID,
                accountEmail: accountEmail,
            };

            const response = await axios.post(hostsConfig.BACKEND_HOST + "/delete_subcategory", body);

            let newItems = response.data;

            if (newItems === undefined || newItems.length === 0) {
                setMessage("Error deleting category. Check if there are products associated with it.");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            let newSession = session;

            newSession.environment.subcategories = newItems;

            setSession(newSession);
            setItems(newItems);
            setItem(newItems[0]);

            setMessage("Deleted succesfuly.");
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage("Error deleting.");
            setSeverity("error");
            setOpenMessage(true);
        }
    };



    if (categoryID === "" || categoryID === undefined) return (<></>);


    return (


        <>

            <Grid container spacing={2}>
                {/* <Grid item md={12} lg={12}>
                    <Typography align="center" variant="body1">
                        Subcategories
                    </Typography>
                </Grid> */}

                <Grid item md={6} lg={6}>
                    <Grid container spacing={1}>
                        <Grid item md={12} lg={12}>
                            <FormControl sx={{ width: 1, pb: 2 }} size="small">
                                <InputLabel id="dropdown-subcategory-label">subcategory</InputLabel>
                                <Select
                                    labelId="dropdown-subcategory-label"
                                    id="dropdown-subcategory"
                                    value={item.subcategoryID}
                                    label="Subcategory"
                                    onChange={(e) => {
                                        setItem(
                                            session.environment.subcategories.filter((item) => item.categoryID === categoryID).find((_items) => _items.subcategoryID === e.target.value)
                                        );
                                    }}
                                >
                                    {session.environment.subcategories.filter((item) => item.categoryID === categoryID).map((_item) => (
                                        <MenuItem key={_item.subcategoryID} value={_item.subcategoryID}>
                                            {_item.subcategoryName} / {_item.subcategoryLabel} / {_item.seq}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("edit");
                                }}
                                disabled={buttonEditDisabled}
                            >
                                Edit
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("add");
                                }}
                                disabled={buttonAddDisabled}
                            >
                                Add
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("delete");
                                }}
                                disabled={buttonDeleteDisabled}
                            >
                                Delete
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("cancel");
                                }}
                                disabled={buttonCancelDisabled}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    handleItemSubmit();
                                }}
                                disabled={buttonSubmitDisabled || session.user.role !== "admin"}
                            >
                                Submit {session.user.role !== "admin" && "(admin only)"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={5} lg={6}>
                    <Card elevation={5} variant="elevation" sx={{ p: 3, backgroundColor: "#bbdefb", w: 1 }}>
                        <TextField
                            sx={{ mt: 0, ml: 1, mb: 1, width: .4 }}
                            id="subcategoryName"
                            label="Category Name"
                            variant="standard"
                            size="small"
                            value={item.subcategoryName}
                            onChange={(e) => {
                                setItem({ ...item, subcategoryName: e.target.value });
                            }}
                            disabled={nameDisabled}
                        />
                        <TextField
                            sx={{ mt: 0, ml: 1, mb: 1, width: .4 }}
                            id="subcategoryLabel"
                            label="Category Label"
                            variant="standard"
                            size="small"
                            value={item.subcategoryLabel}
                            onChange={(e) => {
                                setItem({ ...item, subcategoryLabel: e.target.value });
                            }}
                            disabled={fieldsDisabled}
                        />
                        {/* <TextField
                            sx={{ mt: 1, ml: 1, mb: 1, width: 150 }}
                            id="subcategoryColor"
                            label="Category Color"
                            variant="standard"
                            value={item.subcategoryColor}
                            onChange={(e) => {
                                setItem({ ...item, subcategoryColor: e.target.value });
                            }}
                            disabled={fieldsDisabled}
                            type="color"
                        /> */}

                        <TextField
                            sx={{ mt: 1, ml: 1, mb: 1, width: 100 }}
                            id="seq"
                            label="Sequence"
                            variant="standard"
                            value={item.seq}
                            onChange={(e) => {
                                setItem({ ...item, seq: e.target.value });
                            }}
                            disabled={fieldsDisabled}
                            type="number"
                        />
                    </Card>
                </Grid>
            </Grid>


        </>
    );
}

export default SubCategoriesCRUD;
