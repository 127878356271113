import React, { useState, useContext } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Product from "./pages/Product";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import School from "./pages/School";
import Login from "./pages/Login";
import Purchase from "./pages/Purchase";
import Settings from "./pages/Settings";
import AddProduct from './pages/AddProduct';
import EditProduct from './pages/EditProduct';
// import EditProductNew from './pages/EditProductNew';


function App() {





  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="school" element={<School />} />

          <Route path="purchase" element={<Purchase />} />
          <Route path="purchase/:id" element={<Purchase />} />
          <Route path="purchase/:id/productList" element={<Purchase />} />
          <Route path="purchase/:id/invoiceDetails" element={<Purchase />} />

          <Route path="product" element={<Product />} />
          <Route path="product/:id" element={<Product />} />
          <Route path="status" element={<Product />} />
          <Route path="settings" element={<Settings />} />
          <Route path="addproduct" element={<AddProduct />} />
          <Route path="editproduct" element={<EditProduct />} />
          <Route path="editproduct/:id" element={<EditProduct />} />
          {/* <Route path="editproductNew" element={<EditProductNew />} /> */}
          {/* <Route path="editproductNew/:id" element={<EditProductNew />} /> */}
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="/login">
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}


export default App;



