import useAuth from "../hooks/useAuth";
import useSession from "../hooks/useSession";
import useTranslation from "../hooks/useTranslation";

import { getToken } from "../tokens";

//import useEnvironment from "../hooks/useSession";
import axios from "axios";

import { useState, useEffect, useMemo } from "react";
import { json, useNavigate } from "react-router-dom";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel, TextField, Card } from "@mui/material";

import hostsConfig from "../hostconfig.json";

const ManufacturersCRUD = ({ setOpenMessage, setMessage, setSeverity }) => {
    const { auth, setAuth } = useAuth();
    const { session, setSession } = useSession();
    const { translation, setTranslation } = useTranslation();

    // CREATE TABLE `manufacturers` (
    //     `manufacturerID` varchar(36) NOT NULL,
    //     `manufacturerName` varchar(100) NOT NULL,
    //     `seq` int DEFAULT NULL,
    //     PRIMARY KEY (`manufacturerID`)
    //   ) ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci;

    const [manufacturers, setManufacturers] = useState(session.environment.manufacturers);

    const [manufacturer, setManufacturer] = useState({
        manufacturerID: "",
        manufacturerName: "",
        seq: 0,
    });

    const [nameDisabled, setNameDisabled] = useState(true);
    const [fieldsDisabled, setFieldsDisabled] = useState(true);

    const [buttonEditDisabled, setButtonEditDisabled] = useState(false);
    const [buttonAddDisabled, setButtonAddDisabled] = useState(false);
    const [buttonDeleteDisabled, setButtonDeleteDisabled] = useState(false);

    const [buttonCancelDisabled, setButtonCancelDisabled] = useState(true);
    const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(true);

    const [dialogMode, setDialogMode] = useState("");

    const [tra, setTra] = useState(true);

    /**
     * Translate a given text to its Dutch translation if it exists in the translation dictionary.
     * If the session environment language is 'en', return the original text.
     * If the translation does not exist, return the original text enclosed in square brackets.
     * @param {string} text - The text to translate.
     * @returns {string} - The translated text if it exists and the session environment language is not 'en', 
     *                     the original text if the session environment language is 'en', 
     *                     or the original text enclosed in square brackets if no translation exists.
     */
    const dict = (text) => {
        if (session.environment.lang === "en") {
            return text;
        } else {
            if (translation[text]) {
                return translation[text];
            } else {
                return "[" + text + "]";
            }
        }
    };



    useEffect(() => {
        setButtonAddDisabled(false);
        setButtonEditDisabled(false);
        setButtonCancelDisabled(true);
        setButtonSubmitDisabled(true);
    }, []);

    useEffect(() => {
        if (dialogMode === "edit") {
            setFieldsDisabled(false);

            setNameDisabled(false);
            setButtonEditDisabled(false);
            setButtonAddDisabled(true);
            setButtonDeleteDisabled(true);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "add") {
            emptyManufacturer();
            setFieldsDisabled(false);

            setNameDisabled(false);
            setButtonEditDisabled(true);
            setButtonAddDisabled(false);
            setButtonDeleteDisabled(true);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "delete") {
            setFieldsDisabled(true);

            setNameDisabled(true);
            setButtonEditDisabled(true);
            setButtonAddDisabled(true);
            setButtonDeleteDisabled(false);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "cancel") {
            setManufacturer(session.environment.manufacturers[0]);

            setFieldsDisabled(true);

            setNameDisabled(true);
            setButtonEditDisabled(false);
            setButtonAddDisabled(false);
            setButtonDeleteDisabled(false);

            setButtonCancelDisabled(true);
            setButtonSubmitDisabled(true);
        }
    }, [dialogMode]);

    const resetButtons = () => {
        setFieldsDisabled(true);
        setManufacturer(session.environment.manufacturers[0]);

        setNameDisabled(true);
        setButtonEditDisabled(false);
        setButtonAddDisabled(false);
        setButtonDeleteDisabled(false);

        setButtonCancelDisabled(true);
        setButtonSubmitDisabled(true);
    };

    const emptyManufacturer = () => {
        setManufacturer({
            manufacturerID: "",
            manufacturerName: "",
            seq: "",
        });
    };

    const handleManufacturerSubmit = () => {
        console.log("handleManufacturerSubmit");
        console.log(manufacturer);

        if (dialogMode === "edit") {
            editManufacturer();
        }

        if (dialogMode === "add") {
            addManufacturer();
        }

        if (dialogMode === "delete") {
            deleteManufacturer();
        }
    };

    const editManufacturer = async () => {
        console.log("editManufacturer");
        console.log(manufacturer);

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                manufacturerID: manufacturer.manufacturerID,
                manufacturerName: manufacturer.manufacturerName,
                seq: manufacturer.seq,
                accountEmail: accountEmail,
            };

            const response = await axios.put(hostsConfig.BACKEND_HOST + "/update_manufacturer", body);

            //update session
            let newSession = session;
            for (let i = 0; i < manufacturers.length; i++) {
                if (manufacturers[i].manufacturerID === manufacturer.manufacturerID) {
                    newSession.environment.manufacturers[i] = manufacturer;
                }
            }
            setSession(newSession);

            setMessage(dict("Updated succesfuly."));
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage(dict("Error updating."));
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    const addManufacturer = async () => {
        console.log("addManufacturer");
        console.log(manufacturer);

        if (manufacturer.manufacturerName === "" || manufacturer.seq === "") {
            setMessage(dict("Please fill all fields."));
            setOpenMessage(true);
            return;
        }

        var man = manufacturer;

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                manufacturerID: "",
                manufacturerName: man.manufacturerName,
                seq: man.seq,
                accountEmail: accountEmail,
            };

            const response = await axios.post(hostsConfig.BACKEND_HOST + "/insert_manufacturer", body);

            let newMan = response.data[0];

            if (newMan === undefined) {
                setMessage(dict("Error adding manufacturer. Check if it already exists"));
                setSeverity("error");
                setOpenMessage(true);
                return;
            }
            //update session
            let newSession = session;
            newSession.environment.manufacturers.push(newMan);
            setSession(newSession);

            setMessage(dict("Added succesfuly."));
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage(dict("Error adding."));
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    const deleteManufacturer = async () => {
        console.log("deleteManufacturer");
        console.log(manufacturer);

        let man = manufacturer;

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                manufacturerID: man.manufacturerID,
                accountEmail: accountEmail,
            };

            const response = await axios.post(hostsConfig.BACKEND_HOST + "/delete_manufacturer", body);

            let newManufacturers = response.data;

            if (newManufacturers === undefined || newManufacturers.length === 0) {
                setMessage(dict("Error deleting manufacturer. Check if there are products associated with it."));
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            let newSession = session;

            newSession.environment.manufacturers = newManufacturers;

            setSession(newSession);
            setManufacturers(newManufacturers);
            setManufacturer(newManufacturers[0]);

            setMessage(dict("Deleted succesfuly."));
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage(dict("Error deleting."));
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    return (
        <>
            <Grid container spacing={2}>


                <Grid item md={6} lg={6}>
                    <Grid container spacing={1}>
                        <Grid item md={12} lg={12}>
                            <FormControl sx={{ width: 1, pb: 2 }} size="small">
                                <InputLabel id="dropdown-manufacturer-label">{dict("Select manufacturer")}</InputLabel>
                                <Select
                                    labelId="dropdown-manufacturer-label"
                                    id="dropdown-manufacturer"
                                    value={manufacturer.manufacturerID}
                                    label={dict("Select manufacturer")}
                                    onChange={(e) => {
                                        setManufacturer(session.environment.manufacturers.find((man) => man.manufacturerID === e.target.value));
                                    }}
                                >
                                    {session.environment.manufacturers.map((man) => (
                                        <MenuItem key={man.manufacturerID} value={man.manufacturerID}>
                                            {man.manufacturerName} / {man.seq}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("edit");
                                }}
                                disabled={buttonEditDisabled}
                            >
                                {dict("Edit")}
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("add");
                                }}
                                disabled={buttonAddDisabled}
                            >
                                {dict("Add")}
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("delete");
                                }}
                                disabled={buttonDeleteDisabled}
                            >
                                {dict("Delete")}
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("cancel");
                                }}
                                disabled={buttonCancelDisabled}
                            >
                                {dict("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    handleManufacturerSubmit();
                                }}
                                disabled={buttonSubmitDisabled}
                            >
                                {dict("Submit")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={5} lg={6}>
                    <Card elevation={5} variant="elevation" sx={{ p: 3, backgroundColor: "#bbdefb", w: 1 }}>
                        <TextField
                            sx={{ mt: 0, ml: 1, mb: 1, width: 1 }}
                            id="manufacturerName"
                            label={dict("Manufacturer name")}
                            variant="standard"
                            size="small"
                            value={manufacturer.manufacturerName}
                            onChange={(e) => {
                                setManufacturer({ ...manufacturer, manufacturerName: e.target.value });
                            }}
                            disabled={nameDisabled}
                        />

                        <TextField
                            sx={{ mt: 1, ml: 1, mb: 1, width: 100 }}
                            id="seq"
                            label={dict("Sequence")}
                            variant="standard"
                            value={manufacturer.seq}
                            onChange={(e) => {
                                setManufacturer({ ...manufacturer, seq: e.target.value });
                            }}
                            disabled={fieldsDisabled}
                            type="number"
                        />
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default ManufacturersCRUD;
