import useAuth from "../hooks/useAuth";
import useSession from "../hooks/useSession";
import useTranslation from "../hooks/useTranslation";
import { getToken } from "../tokens";

//import useEnvironment from "../hooks/useSession";
import axios from "axios";

import { useState, useEffect, useMemo } from "react";
import { json, useNavigate } from "react-router-dom";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel, TextField, Card } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";

import hostsConfig from "../hostconfig.json";


const LocationCRUD = ({ setOpenMessage, setMessage, setSeverity, organisation, setOrganisations }) => {
    const { auth, setAuth } = useAuth();
    const { session, setSession } = useSession();
    const { translation, setTranslation } = useTranslation();
    const [tra, setTra] = useState(true);

    // CREATE TABLE `organisation` (
    //     `organisationID` varchar(36) NOT NULL,
    //     `name` varchar(255) DEFAULT NULL,
    //     `nameShort` varchar(45) DEFAULT NULL,
    //     `codeInternal` varchar(45) DEFAULT NULL,
    //     `codeExternal` varchar(45) DEFAULT NULL,
    //     `photo` blob,
    //     `color` varchar(45) DEFAULT NULL,
    //     `domain` varchar(255) DEFAULT NULL,
    //     PRIMARY KEY (`organisationID`)
    //   ) ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci;

    // CREATE TABLE `location` (
    //     `locationID` varchar(36) NOT NULL,
    //     `organisationID` varchar(36) DEFAULT NULL,
    //     `desc` varchar(255) DEFAULT NULL,
    //     `code` varchar(45) DEFAULT NULL,
    //     PRIMARY KEY (`locationID`),
    //     KEY `fk_org_idx` (`organisationID`),
    //     CONSTRAINT `fk_organisation` FOREIGN KEY (`organisationID`) REFERENCES `organisation` (`organisationID`) ON DELETE SET NULL ON UPDATE CASCADE
    //   ) ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci;


    const [items, setItems] = useState([]);

    const [item, setItem] = useState({
        id: "",
        organisationID: "",
        desc: "",
        code: "",
    });

    const [nameDisabled, setNameDisabled] = useState(true);
    const [fieldsDisabled, setFieldsDisabled] = useState(true);

    const [buttonEditDisabled, setButtonEditDisabled] = useState(false);
    const [buttonAddDisabled, setButtonAddDisabled] = useState(false);
    const [buttonDeleteDisabled, setButtonDeleteDisabled] = useState(false);

    const [buttonCancelDisabled, setButtonCancelDisabled] = useState(true);
    const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(true);

    const [dialogMode, setDialogMode] = useState("");

    /**
     * Translate a given text to its Dutch translation if it exists in the translation dictionary.
     * If the session environment language is 'en', return the original text.
     * If the translation does not exist, return the original text enclosed in square brackets.
     * @param {string} text - The text to translate.
     * @returns {string} - The translated text if it exists and the session environment language is not 'en', 
     *                     the original text if the session environment language is 'en', 
     *                     or the original text enclosed in square brackets if no translation exists.
     */
    const dict = (text) => {
        if (session.environment.lang === "en") {
            return text;
        } else {
            if (translation[text]) {
                return translation[text];
            } else {
                return "[" + text + "]";
            }
        }
    };

    useEffect(() => {
        setButtonAddDisabled(false);
        setButtonEditDisabled(false);
        setButtonCancelDisabled(true);
        setButtonSubmitDisabled(true);



    }, []);

    useEffect(() => {
        if (organisation === undefined || organisation.organisationID === "") {
            setItem({
                id: "",
                description: "",
                code: "",
            });
            setItems([]);
        } else {

            if (organisation.locations !== undefined && organisation.locations.length !== 0) {
                setItem(organisation.locations[0]);
            } else {
                setItem({
                    id: "",
                    description: "",
                    code: "",
                });
            }


            setItems(organisation.locations);
        }
    }, [organisation]);

    useEffect(() => {
        if (dialogMode === "edit") {

            if (item.id === "") {
                setMessage(dict("Please select a location / person."));
                setSeverity("warning");
                setOpenMessage(true);
                resetButtons();
                setDialogMode("cancel");
                return;
            }

            setFieldsDisabled(false);

            setNameDisabled(false);
            setButtonEditDisabled(false);
            setButtonAddDisabled(true);
            setButtonDeleteDisabled(true);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "add") {
            emptyItem();
            setFieldsDisabled(false);

            setNameDisabled(false);
            setButtonEditDisabled(true);
            setButtonAddDisabled(false);
            setButtonDeleteDisabled(true);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "delete") {
            setFieldsDisabled(true);

            setNameDisabled(true);
            setButtonEditDisabled(true);
            setButtonAddDisabled(true);
            setButtonDeleteDisabled(false);

            setButtonCancelDisabled(false);
            setButtonSubmitDisabled(false);
        }

        if (dialogMode === "cancel") {
            emptyItem();

            setFieldsDisabled(true);

            setNameDisabled(true);
            setButtonEditDisabled(false);
            setButtonAddDisabled(false);
            setButtonDeleteDisabled(false);

            setButtonCancelDisabled(true);
            setButtonSubmitDisabled(true);
        }
    }, [dialogMode]);


    const resetButtons = () => {
        setFieldsDisabled(true);
        //emptyItem();

        setNameDisabled(true);
        setButtonEditDisabled(false);
        setButtonAddDisabled(false);
        setButtonDeleteDisabled(false);

        setButtonCancelDisabled(true);
        setButtonSubmitDisabled(true);
    };

    const emptyItem = () => {
        setItem({
            id: "",
            description: "",
            code: "",
        });
    };

    const handleItemSubmit = () => {
        console.log("handleItemSubmit");
        console.log(item);

        if (dialogMode === "edit") {
            editItem();
        }

        if (dialogMode === "add") {
            addItem();
        }

        if (dialogMode === "delete") {
            deleteItem();
        }
    };

    const normalizeSchools = (data) => {
        let schools = data;
        for (let i = 0; i < schools.length; i++) {
            if (schools[i].locations.length > 0) {
                for (let j = 0; j < schools[i].locations.length; j++) {
                    if (schools[i].locations[j].id === null) {
                        //remove this location
                        schools[i].locations.splice(j, 1);
                    }
                }
            }
            schools[i].organisationID = schools[i].id;
        }

        //rename location.description to location.title
        for (let i = 0; i < schools.length; i++) {
            if (schools[i].locations.length > 0) {
                for (let j = 0; j < schools[i].locations.length; j++) {
                    schools[i].locations[j].title = schools[i].locations[j].description;
                }
            }
        }

        //sort locations by field 'desc'
        for (let i = 0; i < schools.length; i++) {
            if (schools[i].locations.length > 0) {
                schools[i].locations.sort((a, b) => (a.title > b.title ? 1 : -1));
            }
        }
        return schools;
    }

    //editItem =========================================================================================================
    const editItem = async () => {
        console.log("editItem");
        console.log(item);

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                locationID: item.id,
                organisationID: organisation.organisationID,
                description: item.description,
                code: item.code,
                accountEmail: accountEmail,
            };

            const response = await axios.put(hostsConfig.BACKEND_HOST + "/update_location", body);

            //update session
            // let newItems = items;
            // for (let i = 0; i < newItems.length; i++) {
            //     if (newItems[i].organisationID === item.organisationID) {
            //         newItems[i] = item;
            //     }
            // }

            if (response.data !== undefined || response.data.length !== 0) {

                let newSchools = normalizeSchools(response.data);
                setOrganisations(newSchools);

                //find the school in newItems using organisationID
                let newSchool = newSchools.filter((_organisation) => {
                    return _organisation.organisationID === organisation.organisationID;
                })[0];

                setItems(newSchool.locations);

                //find the location in newItems using locationID
                let newLocation = newSchool.locations.filter((_location) => {
                    return _location.id === item.id;
                })[0];
                setItem(newLocation);

                //todo: update organisations
                setMessage(dict("Updated succesfuly."));
                setSeverity("success");
                setOpenMessage(true);
            } else {
                setMessage(dict("Error updating."));
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage(dict("Error updating."));
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    //addItem =========================================================================================================
    const addItem = async () => {
        console.log("addItem");
        console.log(item);

        if (item.name === "" || item.nameShort === "") {
            setMessage(dict("Please fill at least the required fields."));
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                id: item.id,
                organisationID: organisation.organisationID,
                description: item.description,
                code: item.code,
                accountEmail: accountEmail,
            };

            const response = await axios.post(hostsConfig.BACKEND_HOST + "/insertLocation", body);


            if (response.data !== undefined || response.data.length !== 0) {

                let newSchools = normalizeSchools(response.data);
                setOrganisations(newSchools);

                //find the school in newItems using organisationID
                let newSchool = newSchools.filter((_organisation) => {
                    return _organisation.organisationID === organisation.organisationID;
                })[0];

                setItems(newSchool.locations);

                //find the location in newItems using locationID
                let newLocation = newSchool.locations.filter((_location) => {
                    return _location.description === item.description;
                })[0];
                setItem(newLocation);


                setMessage(dict("Added succesfuly."));
                setSeverity("success");
                setOpenMessage(true);
            } else {
                setMessage(dict("Error adding category. Check if it already exists"));
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage(dict("Error adding."));
            setSeverity("error");
            setOpenMessage(true);
        }
    };



    //deleteItem =========================================================================================================
    const deleteItem = async () => {
        console.log("deleteItem");
        console.log(item);

        let at = item;

        try {
            let accountEmail = auth?.userInfo?.email || "";

            let body = {
                id: item.id,
                accountEmail: accountEmail,
            };

            const response = await axios.post(hostsConfig.BACKEND_HOST + "/delete_location", body);

            if (response.data === undefined || response.data.length === 0) {
                setMessage(dict("Error deleting organisation. Check if there are products associated with it."));
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            let newSchools = normalizeSchools(response.data);
            setOrganisations(newSchools);

            //find the school in newItems using organisationID
            let newSchool = newSchools.filter((_organisation) => {
                return _organisation.organisationID === organisation.organisationID;
            })[0];

            setItems(newSchool.locations);

            if (newSchool.locations.length > 0) {
                setItem(newSchool.locations[0]);
            } else {
                emptyItem();
            }

            setMessage(dict("Deleted succesfuly."));
            setSeverity("success");
            setOpenMessage(true);

            resetButtons();
            setDialogMode("");

            console.log(response);
        } catch (error) {
            console.error(error);

            setMessage(dict("Error deleting."));
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    if (organisation === undefined || organisation.organisationID === "") return (<></>);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={12} lg={6}>
                    <Grid container spacing={1}>
                        <Grid item md={12} lg={12}>
                            <FormControl sx={{ width: 1, pb: 2 }} size="small">
                                <InputLabel id="dropdown-category-label">{dict("select location / person")}</InputLabel>
                                <Select
                                    labelId="dropdown-category-label"
                                    id="dropdown-category"
                                    value={item.id}
                                    label={dict("select location / person")}
                                    onChange={(e) => {
                                        setItem(
                                            items.filter((item) => {
                                                return item.id === e.target.value;
                                            })[0]
                                        );
                                    }}
                                >
                                    {items.map((item) => {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.description}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("edit");
                                }}
                                disabled={buttonEditDisabled}
                            >
                                {dict("Edit")}
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("add");
                                }}
                                disabled={buttonAddDisabled}
                            >
                                {dict("Add")}
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("delete");
                                }}
                                disabled={buttonDeleteDisabled}
                            >
                                {dict("Delete")}
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    setDialogMode("cancel");
                                }}
                                disabled={buttonCancelDisabled}
                            >
                                {dict("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Button
                                sx={{ width: 1 }}
                                variant="contained"
                                onClick={() => {
                                    handleItemSubmit();
                                }}
                                disabled={buttonSubmitDisabled}
                            >
                                {dict("Submit")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12} lg={6}>
                    <Card elevation={5} variant="elevation" sx={{ p: 3, backgroundColor: "#bbdefb", w: 1 }}>
                        <TextField
                            sx={{ mt: 1, mb: 1, width: 1 }}
                            id="desc"
                            label={dict("Description")}
                            variant="standard"
                            size="small"
                            value={item.description}
                            onChange={(e) => {
                                setItem({ ...item, description: e.target.value });
                            }}
                            disabled={nameDisabled}
                        />

                        <TextField
                            sx={{ mt: 1, mb: 1, width: .3 }}
                            id="code"
                            label={dict("Code")}
                            variant="standard"
                            value={item.code}
                            onChange={(e) => {
                                setItem({ ...item, code: e.target.value });
                            }}
                            disabled={fieldsDisabled}
                        />


                    </Card>
                </Grid>
            </Grid>

            {/* <Card variant="plain" sx={{ mr: 4, pl: 7, pr: 5, mt: 1 }}>
                <SubCategoriesCRUD setOpenMessage={setOpenMessage} setMessage={setMessage} setSeverity={setSeverity} categoryID={item.categoryID} />
            </Card> */}
        </>
    );
};

export default LocationCRUD;
