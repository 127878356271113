import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(
    sessionStorage.getItem("session_auth") === null
      ? {}
      : JSON.parse(sessionStorage.getItem("session_auth"))
  );

  const updateAuth = (data) => {
    sessionStorage.setItem("session_auth", JSON.stringify(data));
    setAuth(data);
  };

  return (
    <AuthContext.Provider value={{ auth, setAuth: updateAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

// import { createContext, useState } from "react";

// const AuthContext = createContext({});

// export const AuthProvider = ({ children }) => {

//     const [auth, setAuth] = useState(sessionStorage.getItem("session_auth") === null ? {} : JSON.parse(sessionStorage.getItem("session_auth")));

//     return (
//         <AuthContext.Provider value={{ auth, setAuth }}>
//             {children}
//         </AuthContext.Provider>
//     )
// }

// export default AuthContext;