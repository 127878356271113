// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  overflow-y: scroll;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sidebarButton > .active {
  background-color: lightsteelblue;
  color: white;
} 

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.btn_tiny
{
  max-width: 18px !important; 
  min-width: 18px !important;
  width: 18px !important;
}

.btn_tiny:hover 
{
  background-color: #ff9797 !important; 
}

.Mui-odd {
  background-color: aliceblue !important;
}

.Mui-even {
  background-color: white !important;
}

/* .btn_tiny { border: none !important;} */
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,gCAAgC;EAChC,YAAY;AACd;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;;EAEE,0BAA0B;EAC1B,0BAA0B;EAC1B,sBAAsB;AACxB;;AAEA;;EAEE,oCAAoC;AACtC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,kCAAkC;AACpC;;AAEA,0CAA0C","sourcesContent":[".App {\n  text-align: center;\n  overflow-y: scroll;\n}\n\n.App {\n  font-family: sans-serif;\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.sidebarButton > .active {\n  background-color: lightsteelblue;\n  color: white;\n} \n\ninput[type=number]::-webkit-inner-spin-button, \ninput[type=number]::-webkit-outer-spin-button { \n  -webkit-appearance: none; \n  margin: 0; \n}\n\n.btn_tiny\n{\n  max-width: 18px !important; \n  min-width: 18px !important;\n  width: 18px !important;\n}\n\n.btn_tiny:hover \n{\n  background-color: #ff9797 !important; \n}\n\n.Mui-odd {\n  background-color: aliceblue !important;\n}\n\n.Mui-even {\n  background-color: white !important;\n}\n\n/* .btn_tiny { border: none !important;} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
