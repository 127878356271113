import React, { useState, useEffect, useContext } from "react";
import { Outlet, Link, NavLink } from "react-router-dom";
import Container from "@mui/material/Container";
import { Box, Tooltip, CircularProgress } from "@mui/material";
import "../App.css";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useTranslation from "../hooks/useTranslation";
import axios from "axios";

import {
    AppBar,
    Toolbar,
    Avatar,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    CssBaseline,
    Drawer,
    Typography,
    Button,
} from "@mui/material";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

import useSession from "../hooks/useSession";
import useAuth from "../hooks/useAuth";

import { getToken } from "../tokens";

import hostsConfig from "../hostconfig.json";

const Layout = () => {
    const [hasToken, setHasToken] = useState(false);

    const { session, setSession } = useSession();
    const { auth, setAuth } = useAuth();

    const { translation, setTranslation } = useTranslation();

    // const {userInfo , setUserInfo}= useContext(UserInfoContext);

    useEffect(() => {
        var env = {};
        var _users = [];
        var user = {};

        async function check_if_has_token() {
            const token = await getToken();

            if (!token || token === null  || token === "undefined") {
                window.location.href = hostsConfig.FRONTEND_HOST + "/login";
                setHasToken(false);
            } else {
                setHasToken(true);

                try {
                    const request = await fetch(
                        `https://www.googleapis.com/oauth2/v3/userinfo`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    const user = await request.json();
                    console.log("userInfo 24 | ", user);
                    //setSession({ ...session, userInfo: data });
                    setAuth({ ...auth, userInfo: user });
                    //return data;
                } catch (error) {
                    console.log(
                        "userInfo 35 | error getting calendar data",
                        error
                    );
                    //return error.message;
                }
            }
        }
        //check_if_has_token();

        async function environmentData() {
            try {
                const response = await axios.get(
                    hostsConfig.BACKEND_HOST + "/environment"
                );
                console.log(response);
                env = response.data;

                //setSession({ ...session, environment: response.data });
            } catch (error) {
                console.error(error);
            }
        }
        //environmentData();

        async function usersData() {
            try {
                const response = await axios.get(
                    hostsConfig.BACKEND_HOST + "/users"
                );
                console.log(response);
                //add users to session.environment
                _users = response.data.users;
                //setSession({ ...session, users: response.data.users });
            } catch (error) {
                console.error(error);
            }
        }
        //usersData();

        // Promise.all([check_if_has_token(), environmentData(), usersData()]).then((values) => {
        //     console.log("check_if_has_token , environmentData , usersData one");
        //     setSession({ ...session, environment: env, users: _users });
        // });

        const fetchData = async () => {
            try {
                const token = await check_if_has_token(); // Step 1
                console.log("check_if_has_token done");

                await environmentData(); // Step 2
                console.log("environmentData done");

                await usersData(); // Step 3
                console.log("usersData done");

                setSession({ environment: env, users: _users });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        try {
            // session.environment.users = [
            //     {
            //       email: "achim.rosier@scholengroep20.be",
            //       username: "achim",
            //       role: "admin",
            //       pages: "home,schools,purchase,products,settings,add product,edit product,logout",
            //     },
            //     {
            //       email: "test.persoon1@scholengroep20.be",
            //       username: "tp1",
            //       role: "user",
            //       pages: "homeadd product,edit product,logout",
            //     },
            //     {
            //       email: "theofilos.xanthopoulos@scholengroep20.be",
            //       username: "theo",
            //       role: "admin",
            //       pages: "home,schools,purchase,products,settings,add product,edit product,contact,logout",
            //     },
            //   ]

            //if in session.environment.users there is a user with the same email as the auth.userInfo.email then set the session.user to that user
            if (auth.userInfo?.email !== undefined) {
                var authEmail = auth.userInfo.email;
                if (authEmail !== undefined) {
                    if (session.users === undefined) return;

                    const user = session.users.find(
                        (user) => user.email === authEmail
                    );
                    // setSession({ ...session, user: user });

                    async function logUserLogin() {
                        try {
                            const response = await axios.post(
                                hostsConfig.BACKEND_HOST + "/logUser",
                                {
                                    action: "login",
                                    email: authEmail,
                                }
                            );
                            console.log(
                                "logUserLogin | response",
                                response.data[0].email
                            );
                        } catch (error) {
                            console.log("logUserLogin | error", error);
                        }
                    }
                    logUserLogin();

                    if (user) {
                        const pages = user.pages.split(",");
                        console.log("pages 97 | ", pages);
                        setSession({ ...session, pages: pages, user: user });
                    } else {
                        setSession({ ...session, pages: [], user: user });
                        window.location.href =
                            hostsConfig.FRONTEND_HOST + "/login";
                        setHasToken(false);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, [auth, session.users]);

    /**
     * Translate a given text to its Dutch translation if it exists in the translation dictionary.
     * If the session environment language is 'en', return the original text.
     * If the translation does not exist, return the original text enclosed in square brackets.
     * @param {string} text - The text to translate.
     * @returns {string} - The translated text if it exists and the session environment language is not 'en',
     *                     the original text if the session environment language is 'en',
     *                     or the original text enclosed in square brackets if no translation exists.
     */
    const dict = (text) => {
        if (session.environment.lang === "en") {
            return text;
        } else {
            if (translation[text]) {
                return translation[text];
            } else {
                return "[" + text + "]";
            }
        }
    };

    const handleLogout = async() => {
        setHasToken(false);
        sessionStorage.clear();

        async function logUserLogout() {
            try {
                let email = auth.userInfo.email;
                const response = await axios.post(
                    hostsConfig.BACKEND_HOST + "/logUser",
                    {
                        action: "logout",
                        email: email,
                    },
                    { withCredentials: true }
                );
                console.log("logUserLogout | response", response.data[0].email);
            } catch (error) {
                console.log("logUserLogout | error", error);
            }
        }
    
        // Wait for logUserLogout to finish before redirecting
        await logUserLogout();

        window.location.href = hostsConfig.FRONTEND_HOST + "/login";
    };

    if (session.pages === undefined) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh" // Set the height of the container to fill the entire viewport
            >
                <CircularProgress />
            </Box>
        );
    } else {
        return (
            <>
                {hasToken && (
                    <Container maxWidth="xl">
                        <Box sx={{ bgcolor: "#bbdefb", p: 1 }}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    lg={2}
                                    xl={2}
                                    pl={1}
                                >
                                    {auth?.userInfo?.email && (
                                        <>
                                            <Box
                                                sx={{
                                                    m: 2,
                                                    p: 1,
                                                    backgroundColor: "#1976d2",
                                                    borderRadius: 2,
                                                }}
                                            >
                                                <Typography
                                                    pb={1}
                                                    align="center"
                                                    variant="h6"
                                                >
                                                    {dict(
                                                        "Inventory Management"
                                                    )}
                                                </Typography>
                                                {/* <Box
                                                    component="img"
                                                    sx={{
                                                        width: "100%",
                                                        // mt: 1,
                                                        // ml: 2,
                                                        // mb: 1,
                                                        mt: 1,
                                                        ml: "auto",
                                                        p: 0,
                                                        borderRadius: "5%",
                                                    }}
                                                    alt="Inventory management"
                                                    // src={hostsConfig.BACKEND_HOST + "/images/vector_workers-warehouse-storage.jpg"}
                                                    src={hostsConfig.BACKEND_HOST + "/images/vector_workers-warehouse-storage.jpg"}
                                                /> */}

                                                <Box
                                                    component="img"
                                                    sx={{
                                                        width: "100%",
                                                        mt: 1,
                                                        ml: "auto",
                                                        p: 0,
                                                        borderRadius: "5%",
                                                    }}
                                                    alt="Inventory management"
                                                    src={
                                                        hostsConfig.BACKEND_HOST +
                                                        "/images/vector_workers-warehouse-storage.jpg"
                                                    }
                                                    onClick={() => {
                                                        const newLang =
                                                            session.environment
                                                                .lang === "en"
                                                                ? "nl"
                                                                : "en";
                                                        setSession({
                                                            ...session,
                                                            environment: {
                                                                ...session.environment,
                                                                lang: newLang,
                                                            },
                                                        });
                                                    }}
                                                />

                                                <Typography
                                                    align="center"
                                                    variant="caption"
                                                    display={"block"}
                                                >
                                                    {dict("Welcome")}
                                                </Typography>
                                                <Typography
                                                    align="center"
                                                    variant="caption"
                                                    display={"block"}
                                                >
                                                    {
                                                        auth?.userInfo?.email.split(
                                                            "@"
                                                        )[0]
                                                    }
                                                </Typography>

                                                <Typography
                                                    align="center"
                                                    variant="caption"
                                                    display={"block"}
                                                >
                                                    {dict("from")}{" "}
                                                    {
                                                        auth?.userInfo?.email.split(
                                                            "@"
                                                        )[1]
                                                    }
                                                </Typography>
                                            </Box>
                                        </>
                                    )}
                                    <MenuList>
                                        {session?.pages?.includes("home") && (
                                            <MenuItem className="sidebarButton">
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    component={NavLink}
                                                    to="/"
                                                    sx={{ mt: 0 }}
                                                >
                                                    {dict("Home")}
                                                </Button>
                                            </MenuItem>
                                        )}

                                        {session?.pages?.includes(
                                            "purchase"
                                        ) && (
                                            <MenuItem className="sidebarButton">
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    component={NavLink}
                                                    to="/purchase"
                                                    sx={{ mt: 0 }}
                                                >
                                                    {dict("Purchase")}
                                                </Button>
                                            </MenuItem>
                                        )}

                                        {session?.pages?.includes(
                                            "add product"
                                        ) && (
                                            <MenuItem className="sidebarButton">
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    component={NavLink}
                                                    to="/addproduct"
                                                    sx={{ mt: 0 }}
                                                >
                                                    {dict("Add product")}
                                                </Button>
                                            </MenuItem>
                                        )}

                                        {session?.pages?.includes(
                                            "edit product"
                                        ) && (
                                            <MenuItem className="sidebarButton">
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    component={NavLink}
                                                    to="/editproduct"
                                                    sx={{ mt: 0 }}
                                                >
                                                    {dict("Edit product")}
                                                </Button>
                                            </MenuItem>
                                        )}

                                        {session?.pages?.includes(
                                            "products"
                                        ) && (
                                            <MenuItem className="sidebarButton">
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    component={NavLink}
                                                    to="/product"
                                                    sx={{ mt: 0 }}
                                                >
                                                    {dict("Search product")}
                                                </Button>
                                            </MenuItem>
                                        )}

                                        {session?.pages?.includes(
                                            "schools"
                                        ) && (
                                            <MenuItem className="sidebarButton">
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    component={NavLink}
                                                    to="/school"
                                                    sx={{ mt: 0 }}
                                                >
                                                    {dict("School settings")}
                                                </Button>
                                            </MenuItem>
                                        )}

                                        {session?.pages?.includes(
                                            "contact"
                                        ) && (
                                            <MenuItem className="sidebarButton">
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    component={NavLink}
                                                    to="/contact"
                                                    sx={{ mt: 0 }}
                                                >
                                                    {dict("Contact")}
                                                </Button>
                                            </MenuItem>
                                        )}

                                        {session?.pages?.includes(
                                            "settings"
                                        ) && (
                                            <MenuItem className="sidebarButton">
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    component={NavLink}
                                                    to="/settings"
                                                    sx={{ mt: 0 }}
                                                >
                                                    {dict("General settings")}
                                                </Button>
                                            </MenuItem>
                                        )}

                                        {session?.pages?.includes("logout") && (
                                            <MenuItem className="sidebarButton">
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleLogout}
                                                    sx={{ mt: 2 }}
                                                >
                                                    {dict("Logout")}
                                                </Button>
                                            </MenuItem>
                                        )}
                                    </MenuList>
                                    {/* box to align the button in center */}

                                    {/* <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        pt: 1,
                                    }}
                                >
                                    <Typography align="center" variant="subtitle1" color={"#378fe7"}>
                                        {session?.selectedProducts?.length || 0} items in the cart
                                    </Typography>
                                    <Tooltip sx={{ pl: 1, width: 20, color: "#378fe7" }} title="Cart is carrying product selection from one page to another.">
                                        <InfoOutlinedIcon />
                                    </Tooltip>
                                </Box>
    
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        onClick={() => setSession({ ...session, selectedProducts: [] })}
                                        sx={{ mt: 0, backgroundColor: "white", color: "rgb(23, 109, 200)", width: 200 }}
                                    >
                                        Empty cart
                                    </Button>
                                </Box> */}
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={10}
                                    xl={10}
                                >
                                    <Box
                                        sx={{
                                            bgcolor: "#bbdefb",
                                            width: "100%",
                                        }}
                                    >
                                        <Outlet />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                )}
            </>
        );
    }
};

export default Layout;
