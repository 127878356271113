import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Product from "./pages/Product";
import Contact from "./pages/Contact";
import Settings from "./pages/Settings";
import AddProduct from "./pages/AddProduct";
import EditProduct from "./pages/EditProduct";
import NoPage from "./pages/NoPage";
import App from "./App";
import { AuthProvider } from "./context/AuthProvider";
import { SessionProvider } from "./context/SessionProvider";

import { createTheme, ThemeProvider } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));

const myTheme = createTheme({
    components: {
        //@ts-ignore - this isn't in the TS because DataGird is not exported from `@mui/material`
        MuiDataGrid: {
            styleOverrides: {
                row: {
                    "&.Mui-selected": {
                        backgroundColor: "#378fe7",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "#378fe7",
                        },
                    },
                },
            },
        },
    },
});

root.render(
    <AuthProvider>
        <SessionProvider>
            <ThemeProvider theme={myTheme}>
                <App />
            </ThemeProvider>
        </SessionProvider>
    </AuthProvider>
);
